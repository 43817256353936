import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { FastField, Field } from 'formik';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { lazy, memo, Suspense, useMemo } from 'react';
import { useDisabledState } from '../../../customer/disabled-context/context';
import { fieldTypes } from './field-types';
import styles from './form.styles';
import withSpacing from './with-spacing';

const FormField = memo(({ fieldProps, type, props: elementProps, className, fast, ...props }) => {
  const disabledContext = useDisabledState();
  const { id, name, ...rest } = props;
  const FieldComponent = useMemo(() => lazy(fieldTypes[type]), [type]);
  const FieldProvider = useMemo(() => (fast ? FastField : Field), [fast]);

  return (
    <Suspense fallback={null}>
      <FieldProvider id={id || name} name={name}>
        {({ form, field: { value } }) => (
          <FieldComponent
            field={{
              id: id || name,
              name,
              value,
              disabled: !!disabledContext || rest?.disabled,
              // TODO: Remove "no-margin-bottom" class once we migrate to use new forms in the whole application
              className: classNames(className, 'no-margin-bottom'),
              ...elementProps,
              ...rest
            }}
            ctx={{
              classes: {},
              ...form
            }}
            fieldProps={fieldProps}
          />
        )}
      </FieldProvider>
    </Suspense>
  );
});

FormField.propTypes = {
  fieldProps: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  mode: PropTypes.string,
  props: PropTypes.object,
  className: PropTypes.string,
  fast: PropTypes.bool
};

FormField.defaultProps = {
  fieldProps: {},
  label: undefined,
  mode: undefined,
  props: {},
  className: '',
  id: '',
  fast: true
};

export default flowRight(withStyles(styles), withSpacing)(FormField);
