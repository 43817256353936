import React from 'react';
import PropTypes from 'prop-types';
import MUITooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import infoIcon from '../../assets/svg/info.svg';
import infoIconLight from '../../assets/svg/info-light.svg';
import styles from './tooltip.styles';

export function Tooltip({ classes, text, className, placement, light }) {
  return (
    <MUITooltip classes={{ tooltip: classes.tooltip }} title={text} placement={placement} interactive arrow>
      <img className={classNames(className, classes.info)} src={light ? infoIconLight : infoIcon} alt={text} />
    </MUITooltip>
  );
}

Tooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
  placement: PropTypes.string,
  light: PropTypes.bool
};

Tooltip.defaultProps = {
  className: '',
  placement: 'right-start',
  light: false
};

export default withStyles(styles)(Tooltip);
