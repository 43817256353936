import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { stripeCustomerUrl } from '@ourbranch/be-constants';
import { getValue } from '@ourbranch/lookups';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import isBefore from 'date-fns/isBefore';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Loading } from 'core';
import { AuthContext } from 'core/components/auth';
import { Card } from 'core/components/card';
import { withToast } from 'core/components/toast';
import ValueField from 'core/components/value-field';
import { awsDateToDateFormatter, awsDateToJs, currencyFormatter } from 'core/helpers/formatters';
import { useStore } from 'core/store';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CreditCardForm } from '../credit-card-form';
import { HoldPaymentForm } from '../hold-payment-form';
import { ManualChargeForm } from '../manual-charge-form';
import { PastPayments } from '../past-payments';
import styles from '../payment.styles';
import PaymentMethodValue from '../value';

import { useFormikContext } from 'formik';

const maskingDotsText = '\u25CF\u25CF\u25CF\u25CF';

export function getBillingPaymentMethod(method, brand, last4, className) {
  if (!method && !brand && !last4) {
    return 'Unknown';
  }
  if (method === 'W') {
    return getValue('homeownersPaymentMethod', method);
  }
  if (method === 'Check') {
    return method;
  }
  if (!brand && last4) {
    return (
      <>
        <PaymentMethodValue className={className}>
          ACH/ETF <span>{maskingDotsText}</span>
        </PaymentMethodValue>
        {last4}
      </>
    );
  }
  return (
    <>
      {brand && <PaymentMethodValue className={className}>{brand}</PaymentMethodValue>}
      <PaymentMethodValue className={className}>
        {maskingDotsText}
        {last4}
      </PaymentMethodValue>
    </>
  );
}

function getNextPaymentDue(amount, date, hasBillingHold, billingHoldUntil) {
  if (hasBillingHold) {
    return amount === 0
      ? `${currencyFormatter(amount)}`
      : `${currencyFormatter(amount)} on ${awsDateToDateFormatter(billingHoldUntil)}`;
  }
  if (!date || !amount) {
    if (amount && amount < 0) {
      // policy already paid in full and needs refund
      return `${currencyFormatter(amount, { removeNegativeSign: true })}`;
    }
    return 'On Policy Renewal';
  }

  return `${currencyFormatter(amount)} on ${awsDateToDateFormatter(date)}`;
}

function datesAreOnSameDay(first, second) {
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
}

function getNextPaymentDueLabel({ pastDue, hasBillingHold, nextPaymentAmount, nextPaymentDate }) {
  if (hasBillingHold) {
    return 'Payment Held Until';
  }

  if (pastDue) {
    return 'Past Due';
  }

  if (nextPaymentAmount < 0 && !nextPaymentDate) {
    return 'Refund owed';
  }

  return `Next Payment ${nextPaymentAmount ? 'Due' : ''}`;
}

const BillingDetails = observer(function BillingDetails({ classes, toast, billingHoldUntil, billingAddInstallments }) {
  const { account } = useStore();
  const { isService, canAddRemoveInstallments } = useContext(AuthContext);
  const canHoldPayment = isService;
  const [cardDetails, setCardDetails] = useState(undefined);
  const [openCreditCardForm, setOpenCreditCardForm] = useState(false);
  const [sendingCard, setSendingCard] = useState(false);
  const [openHoldPaymentForm, setOpenHoldPaymentForm] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const {
    policies: {
      policy: { billingDetails, policy, writeOffTransactions, changeCreditCard, allTransactions, setChanged }
    }
  } = account;

  const { setValues, values, setFieldValue } = useFormikContext();

  const onAddRemoveInstallmentsClick = (value) => {
    const tempValue = billingAddInstallments + value;
    setValues({ ...values, billingAddInstallments: tempValue });
    setChanged(true);
  };

  const hasBillingHold = useMemo(() => {
    return (
      policy.billingHoldUntil !== null && isBefore(new Date(policy.effectiveDate), new Date(policy.billingHoldUntil))
    );
  }, [policy.billingHoldUntil, policy.effectiveDate]);

  useEffect(() => {
    if (!cardDetails && billingDetails) {
      const { brand, last4 } = billingDetails?.activePaymentMethod || {};
      setCardDetails({
        brand,
        last4
      });
    }
  }, [cardDetails, billingDetails, setCardDetails]);

  const toggleModifyCreditCardForm = useCallback(() => {
    setOpenCreditCardForm(!openCreditCardForm);
  }, [setOpenCreditCardForm, openCreditCardForm]);

  const toggleHoldPaymentForm = useCallback(() => {
    setOpenHoldPaymentForm(!openHoldPaymentForm);
  }, [setOpenHoldPaymentForm, openHoldPaymentForm]);

  const onSubmitCreditCardForm = async () => {
    try {
      setSendingCard(true);
      const cardElement = elements.getElement(CardElement);
      const res = await stripe.createToken(cardElement, { name: 'change_payment_method' });
      if (res.token) {
        const { id } = policy;
        const card = await changeCreditCard({ accountId: account.id, policyId: id, stripeToken: res.token.id });
        if (card.data) {
          const { brand, last4 } = card.data.creditCard;
          setOpenCreditCardForm(false);
          setSendingCard(false);
          setCardDetails({
            brand,
            last4
          });
          toast.notify({
            type: 'success',
            message: `The customer's new card is a ${brand} ending with ${last4}`
          });
        }
      }
    } catch (error) {
      toast.notify({
        type: 'error',
        message: 'There was an error updating your card'
      });
    }
  };

  const { transactions, nextPaymentDate, nextPaymentAmount, remainingPayments } = billingDetails || {};

  const paid = useMemo(() => {
    if (transactions) {
      return transactions.filter((t) => ['paid', 'partially refunded', 'pending'].includes(t.paymentStatus));
    }
  }, [transactions]);

  const paidAmount = useMemo(() => {
    if (paid) {
      const writeOffTotal = writeOffTransactions.reduce((total, writeOff) => total + writeOff.amount, 0);

      return paid.reduce((prev, t) => t.paymentAmount - t.paymentRefunded + prev, 0) + writeOffTotal;
    }
    return 0;
  }, [paid, writeOffTransactions]);

  const nextDate = useMemo(() => {
    if (nextPaymentDate) {
      return awsDateToJs(nextPaymentDate);
    }
  }, [nextPaymentDate]);

  const remainingPaymentsText = useMemo(() => {
    const qty = policy.policyType === 'A' ? 6 : 12;
    return `${remainingPayments} out of ${qty}`;
  }, [policy.policyType, remainingPayments]);

  // if stripe customer, add link to around text for ability to quickly jump to stripe page
  const amountPaidPendingFormatter = useCallback(
    (value) =>
      policy.stripeCustomerId?.startsWith('cus') ? (
        <a
          className={classes.stripeCustomerLink}
          href={`${stripeCustomerUrl}${policy.stripeCustomerId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {value}
        </a>
      ) : (
        value
      ),
    [policy.stripeCustomerId, classes]
  );

  const pastDue =
    nextPaymentAmount !== 0 && nextPaymentDate && nextDate < new Date() && !datesAreOnSameDay(nextDate, new Date());

  if (!billingDetails || !policy) {
    return <Loading type="secondary" />;
  }

  return (
    <Card type="secondary">
      <Grid
        container
        alignItems="flex-start"
        justify="space-between"
        className={classNames(classes.firstRow, { [classes.pastDueRow]: pastDue })}
      >
        <ValueField
          label="Amount Paid/Pending"
          value={currencyFormatter(paidAmount)}
          mode="dark"
          formatter={amountPaidPendingFormatter}
          classes={{ container: classes.paymentValue }}
        />
        <ValueField
          label={getNextPaymentDueLabel({ pastDue, hasBillingHold, nextPaymentAmount, nextPaymentDate })}
          value={
            <div>
              <div>
                {getNextPaymentDue(nextPaymentAmount, nextPaymentDate, hasBillingHold, policy.billingHoldUntil)}
              </div>
              {canHoldPayment && !openHoldPaymentForm && (
                <Button
                  onClick={() => {
                    setOpenHoldPaymentForm(true);
                  }}
                  variant="text"
                  color="secondary"
                >
                  {hasBillingHold ? 'Edit Hold' : 'Hold Payment'}
                </Button>
              )}
            </div>
          }
          mode="dark"
          classes={{ container: classes.paymentValue }}
        />
        {policy.paymentType === 'I' && (
          <ValueField
            label="Remaining Payments"
            value={remainingPaymentsText}
            mode="dark"
            classes={{ container: classes.paymentValue }}
          />
        )}
        {canAddRemoveInstallments && (
          <Grid container item alignItems="center" className={classes.addInstallment}>
            <ValueField
              name="billingAddInstallments"
              type="numeric"
              label="Installments to add / remove"
              mode="dark"
              value={-billingAddInstallments}
              xs={3}
              // the submitted value for billingAddInstallments needs to be negative for ADDING and positive for REMOVING installments
              // that makes it easy to get confused, so this field formats the value with the sign flipped, so that it's easy to read
              // ie. if billingAddInstallments is -2 (so adding 2 installments) this displays 2
            />
            <div className={classes.right}>
              <Button
                onClick={() => onAddRemoveInstallmentsClick(-1)}
                variant="contained"
                color="secondary"
                className={classes.addInstallmentBtn}
                xs={3}
                disabled={
                  policy.policyType === 'A' ? values.billingAddInstallments <= -5 : values.billingAddInstallments <= -11
                }
              >
                Add Installments
              </Button>
              <Button
                onClick={() => onAddRemoveInstallmentsClick(1)}
                variant="text"
                color="secondary"
                className={classes.addInstallmentBtn}
                disabled={
                  policy.policyType === 'A' ? values.billingAddInstallments >= 5 : values.billingAddInstallments >= 11
                }
                xs={3}
              >
                Remove Installments
              </Button>
            </div>
          </Grid>
        )}
        {cardDetails && (
          <ValueField
            label="Payment Method"
            value={
              <div>
                {policy.paymentMethod === 'C'
                  ? getBillingPaymentMethod('C', cardDetails.brand, cardDetails.last4, classes.creditCardLast4)
                  : getValue('homeownersPaymentMethod', policy.paymentMethod)}
                {isService && !openCreditCardForm && policy.paymentMethod === 'C' && (
                  <Button onClick={toggleModifyCreditCardForm} variant="text" color="secondary">
                    Modify Card
                  </Button>
                )}
              </div>
            }
            mode="dark"
            classes={{ container: classes.paymentValue }}
          />
        )}
      </Grid>

      {isService && openCreditCardForm && (
        <Grid container>
          <CreditCardForm loading={sendingCard} onSubmit={onSubmitCreditCardForm} close={toggleModifyCreditCardForm} />
        </Grid>
      )}
      {canHoldPayment && openHoldPaymentForm && (
        <Grid container>
          <HoldPaymentForm
            close={toggleHoldPaymentForm}
            billingHoldUntil={billingHoldUntil}
            hasBillingHold={hasBillingHold}
            setFieldValue={setFieldValue}
          />
        </Grid>
      )}
      {isService && <ManualChargeForm />}
      <PastPayments transactions={allTransactions} getBillingPaymentMethod={getBillingPaymentMethod} />
    </Card>
  );
});

BillingDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  toast: PropTypes.object.isRequired,
  billingHoldUntil: PropTypes.string
};

BillingDetails.defaultProps = {
  billingHoldUntil: null
};

export default flowRight(withStyles(styles), withToast)(BillingDetails);
