import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { paymentType } from '@ourbranch/lookups';
import { useParams } from 'react-router-dom';

import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { FormField } from 'core/components/form';
import { getRenewalChargeDate } from 'offer/helpers/get-renewal-charge-date';
import { getPreSaleChecklistConditions } from '../../helper';
import useStyles from './pre-sale-checklist.styles';

const buyingHomePolicyText =
  'I have informed the customer that an inspector may come to their home within 2-3 weeks of the effective date as part of our normal due diligence';
const inColoradoText =
  "I have informed the customer that we are occasionally unable to get all the necessary underwriting reports pre-sale, that it doesn't happen often and if it impacts their policy, we'll let them know right away";
const confirmedApplicantsText = "I have listed the member's spouse as the co-applicant";
const enteredPriorInsuranceText =
  "I have confirmed the name of the insured's prior carrier(s) and entered their previous policy numbers for Stress Free Switch if possible";
const confirmDiscountsText =
  'Agent confirmed discounts applied and what member needs to do for each discount to retain discount';
const confirmCoveragesText =
  'Agent has discussed all accepted and declined coverages with the Named Insured and has explained the risks of not carrying certain coverages';
const microDepositsACHText =
  'Agent has confirmed with member to look for micro-deposits in bank account within the next 2-3 days and to submit them via email to support or call in to verify bank for billing';

const updatedPaymentRemindersText = (
  effectiveDate,
  homeMonthly,
  autoMonthly,
  homeBillingDayOfMonth,
  autoBillingDayOfMonth,
  classes
) => {
  const reminders = [];
  const reminderLabel = (type, billingDayOfMonth) => {
    return (
      <FormField
        key={`${type === 'H' ? 'home' : 'auto'}PaymentRemindersUpdated`}
        className={classes?.content}
        name={`${type === 'H' ? 'home' : 'auto'}PaymentRemindersUpdated`}
        type="checkbox"
        label={
          <>
            I have informed the member of the payment schedule for their{' '}
            <strong>{type === 'H' ? 'home' : 'auto'}</strong> policy, including down payment and billing day of month.
            The first payment for their renewal policy will be on{' '}
            {getRenewalChargeDate(effectiveDate, type, billingDayOfMonth)}
          </>
        }
        mode="light"
        topAlignCheckbox
        inlineDisplay
      />
    );
  };
  if (homeMonthly) {
    reminders.push(reminderLabel('H', homeBillingDayOfMonth));
  }
  if (autoMonthly) {
    reminders.push(reminderLabel('A', autoBillingDayOfMonth));
  }
  return reminders;
};

const remindedPIFDateText = (homeEffectiveDate, autoEffectiveDate, homePIF, autoPIF, classes) => {
  const reminders = [];
  const reminderLabel = (type) => {
    const isHome = type === 'H';
    return (
      <FormField
        key={`${type}PaymentRemindersUpdated`}
        className={classes?.content}
        name={`${type === 'H' ? 'home' : 'auto'}PaymentRemindersUpdated`}
        type="checkbox"
        label={
          <>
            I have informed the member that the full payment for their <strong>{type === 'H' ? 'home' : 'auto'}</strong>{' '}
            policy will be charged on {isHome ? homeEffectiveDate : autoEffectiveDate} and that their renewal payment
            will be charged on {getRenewalChargeDate(isHome ? homeEffectiveDate : autoEffectiveDate, type)}
          </>
        }
        mode="light"
        topAlignCheckbox
        inlineDisplay
      />
    );
  };

  if (homePIF) {
    reminders.push(reminderLabel('H'));
  }
  if (autoPIF) {
    reminders.push(reminderLabel('A'));
  }
  return reminders;
};

const PreSaleChecklist = ({ offer }) => {
  const classes = useStyles();
  const { quote } = offer;
  const { option: selectedOptionPolicyType } = useParams();
  const policyType = quote.selectedOption || selectedOptionPolicyType; // check the store, if not in store, grab from url
  const { values } = useFormikContext();
  const {
    buyingHomePolicyInformed,
    allApplicantsConfirmed,
    inColoradoInformed,
    priorInsuranceEntered,
    payingInFull,
    payingMonthly,
    homePaymentRemindersUpdated,
    autoPaymentRemindersUpdated,
    microDepositsACH,
    noWindHailInformed
  } = getPreSaleChecklistConditions(quote, policyType, values);
  const {
    homeownersPaymentType,
    autoPaymentType,
    homeEffectiveDate,
    autoEffectiveDate,
    homeBillingDayOfMonth,
    autoBillingDayOfMonth
  } = values;

  return (
    <Section title="Pre - Sale Checklist">
      <Card className={classes.cardContainer} type="primaryEditable">
        <Grid container item xs={12} spacing={4} className={classes.checklistContainer}>
          <FormField
            className={classes.content}
            name="branchCommunicationConsent"
            type="checkbox"
            label={
              <>
                I have informed the customer that they will receive communications (including text messages) from Branch
                according to the{' '}
                <a
                  className={classes.link}
                  href="https://www.ourbranch.com/s/blog/communication-consent/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Branch Communication Consent
                </a>
              </>
            }
            mode="light"
            topAlignCheckbox
          />
          {buyingHomePolicyInformed && (
            <FormField
              className={classes.content}
              name="buyingHomePolicyInformed"
              type="checkbox"
              label={buyingHomePolicyText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {allApplicantsConfirmed && (
            <FormField
              className={classes.content}
              name="allApplicantsConfirmed"
              type="checkbox"
              label={confirmedApplicantsText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {inColoradoInformed && (
            <FormField
              className={classes.content}
              name="inColoradoInformed"
              type="checkbox"
              label={inColoradoText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {priorInsuranceEntered && (
            <FormField
              className={classes.content}
              name="priorInsuranceEntered"
              type="checkbox"
              label={enteredPriorInsuranceText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {payingInFull && (homePaymentRemindersUpdated || autoPaymentRemindersUpdated) && (
            <>
              {remindedPIFDateText(
                homeEffectiveDate,
                autoEffectiveDate,
                homeownersPaymentType === paymentType.OneTime && policyType?.includes('H'),
                autoPaymentType === paymentType.OneTime && policyType?.includes('A'),
                classes
              ).map((e) => e)}
            </>
          )}
          {payingMonthly && (homePaymentRemindersUpdated || autoPaymentRemindersUpdated) && (
            <>
              {updatedPaymentRemindersText(
                homeEffectiveDate || autoEffectiveDate,
                homeownersPaymentType === paymentType.Monthly && policyType?.includes('H'),
                autoPaymentType === paymentType.Monthly && policyType?.includes('A'),
                homeBillingDayOfMonth,
                autoBillingDayOfMonth,
                classes
              ).map((e) => e)}
            </>
          )}
          <FormField
            className={classes.content}
            name="confirmDiscounts"
            type="checkbox"
            label={confirmDiscountsText}
            mode="light"
            topAlignCheckbox
          />
          <FormField
            className={classes.content}
            name="confirmCoverages"
            type="checkbox"
            label={confirmCoveragesText}
            mode="light"
            topAlignCheckbox
          />
          {microDepositsACH && (
            <FormField
              className={classes.content}
              name="microDepositsACH"
              type="checkbox"
              label={microDepositsACHText}
              mode="light"
              topAlignCheckbox
            />
          )}
          {noWindHailInformed && (
            <FormField
              className={classes.content}
              name="noWindHailInformed"
              type="checkbox"
              label={
                <>
                  I have informed the member that their home coverage does not cover wind & hail damage in their
                  location and that this coverage must be purchased separately through{' '}
                  <a className={classes.link} href="https://aiua.org/" target="_blank" rel="noopener noreferrer">
                    AIUA
                  </a>
                </>
              }
              mode="light"
              topAlignCheckbox
            />
          )}
        </Grid>
      </Card>
    </Section>
  );
};

PreSaleChecklist.propTypes = {
  offer: PropTypes.object.isRequired
};

export default PreSaleChecklist;
