import ApolloClient from 'apollo-client';
import { RetryLink } from '@apollo/client/link/retry';
import { ApolloLink } from 'apollo-link';
import awsConfig from '../aws-exports';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { Auth as AwsAuth } from 'aws-amplify';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

// We do this so that RetryLink will treat AppSync execution timeouts as errors
// and retry them. By default only network errors are considered errors for retrying,
// and errors within the GraphQL response are not considered "errors while making the request"
// and are not retried. This needs to be between the HTTP link and the retry link.
//
// Shape of data.errors:
// [
//   {
//     "data": null,
//     "errorInfo": null,
//     "errorType": "ExecutionTimeout",
//     "locations": [{ "column": 3, "line": 2, "sourceName": null }],
//     "message": "Execution timed out.",
//     "path": ["recalculateCluster"]
//   }
// ]
const promoteAppSyncExecutionTimeoutsToErrorsLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    if (data && data.errors && data.errors.some((error) => error.errorType === 'ExecutionTimeout')) {
      throw new Error('AppSyncExecutionTimeout');
    }
    return data;
  });
});

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: 3000,
    jitter: true
  },
  attempts: {
    max: 5
  }
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    retryLink,
    promoteAppSyncExecutionTimeoutsToErrorsLink,
    createAuthLink({
      url: awsConfig.graphqlEndpoint,
      region: awsConfig.region,
      auth: {
        type: awsConfig.authenticationType,
        jwtToken: async () => (await AwsAuth.currentSession()).getIdToken().getJwtToken()
      }
    }),
    createSubscriptionHandshakeLink(
      awsConfig.graphqlEndpoint,
      createHttpLink({ uri: awsConfig.graphqlEndpoint, fetch: (...args) => window.fetch(...args) })
    )
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    },
    watchQuery: {
      fetchPolicy: 'no-cache'
    }
  }
});
