const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

const isFuturePayment = ({ effectiveDate, paymentMethod }) => {
  // Any Policy sold after midnight east coast will bill immediately
  const parsedEffectiveDate = dayjs.tz(effectiveDate, 'America/New_York').toDate();
  return parsedEffectiveDate > new Date() && ['C', 'E'].includes(paymentMethod);
};

module.exports = {
  isFuturePayment
};
