/* eslint-disable */
const overrideRadio = (theme) => {
  theme.overrides.MuiSwitch = {
    root: {
      marginLeft: -14
    },
    colorPrimary: {
      color: theme.colorPalette.beige_10,
      '&$checked': {
        color: theme.colorPalette.orange_10
      }
    },
    colorSecondary: {
      height: 'auto',
      color: theme.colorPalette.green_40
    },
    disabled: {
      color: `${theme.colorPalette.orange_10_op_70}`
    }
  };
};

export default overrideRadio;
