import React, { memo } from 'react';
import People from 'common/components/people';
import AddDriver from 'common/components/people/add-form';
import Driver from 'common/components/people/driver';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import Suggestions from 'common/components/people/suggestions/suggestions';
import useStyles from './people-details.styles';

const PeopleDetails = memo(({ disabled, enableApplicantSwap, hasAuto }) => {
  const classes = useStyles();

  const {
    values: { drivers }
  } = useFormikContext();

  return (
    <div className={classes.container}>
      <People
        disabled={disabled}
        id={'drivers'}
        person={Driver}
        addForm={(props) => (
          <AddDriver {...props} addContent={<Suggestions isCoapplicant={!hasAuto} isDriver drivers={drivers} />} />
        )}
        addLabel={hasAuto ? 'Add Drivers' : 'Add Co-applicants'}
        title={hasAuto ? 'Drivers' : 'Applicants'}
        singular="person"
        plural="people"
        enableApplicantSwap={enableApplicantSwap}
        hasAuto={hasAuto}
      />
    </div>
  );
});

export default PeopleDetails;

PeopleDetails.propTypes = {
  disabled: PropTypes.bool,
  enableApplicantSwap: PropTypes.bool,
  hasAuto: PropTypes.bool
};

PeopleDetails.defaultProps = {
  disabled: false,
  enableApplicantSwap: false,
  hasAuto: false
};
