import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Header from '../header/header';
import styles from './layout.styles';

const Layout = ({ children, classes }) => {
  return (
    <>
      <Header />
      <div className={classes.layout}>
        <div className={classes.container}>{children}</div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Layout);
