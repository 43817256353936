import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useFormikContext, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { FormField } from 'core/components/form';
import { Tooltip } from 'core/components/tooltip';
import { awsDateFormatter } from 'core/helpers/formatters';
import { isValidVin } from 'core/helpers/vin-validator';
import Suggestions from 'common/components/people/suggestions/suggestions';

import useStyles from 'common/components/people/add-form.styles';

const initialValues = {
  carVIN: '',
  initialOpen: true
};

const findGarageLocation = (cars) => {
  if (!Array.isArray(cars) || !cars.length) {
    return undefined;
  }
  const [car] = cars;
  if (car) {
    return car.garageLocation;
  }
};

const handleSubmit =
  (onAdd, cars) =>
  ({ carVIN, initialOpen }) => {
    onAdd({
      VIN: carVIN.toUpperCase(),
      initialOpen,
      purchaseDate: awsDateFormatter(new Date()),
      garageLocation: findGarageLocation(cars)
    });
  };

const validationSchema = (cars) =>
  Yup.object().shape({
    carVIN: Yup.string()
      .required('VIN is required')
      .ensure()
      .test('is-valid-vin', 'Please enter a valid car VIN', (val) => isValidVin(val))
      .test('is-new-vin', 'This VIN is already included in your quote', (val) => !cars.find((car) => car.VIN === val))
  });
function AddCar({ onAdd, id, disabled }) {
  const { values } = useFormikContext();
  const { [id]: cars, drivers, isBix } = values || {};

  const canAddCar = isBix ? drivers.length >= (cars.length + 1) / 10 : drivers.length >= (cars.length + 1) / 2.5;
  // if it's BIX the car to driver ratio is 10:1, if GSNIC policy car to driver ratio is 2.5:1,

  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema(cars)}
      onSubmit={handleSubmit(onAdd, cars)}
    >
      <Form className={classes.container}>
        <Suggestions cars={cars} />
        <Grid key="container" container justify="space-between" alignItems="center" spacing={2}>
          <FormField id="carVIN" name="carVIN" type="string" label={`Car's VIN Number`} mode="light" xs={6} />
          <div>
            {!canAddCar && (
              <Tooltip
                text={`The Car-to-Driver ratio for ${
                  isBix ? 'BIX policies is 10 : 1.' : 'GSNIC policies is 2.5 : 1.'
                } To add a car, please add more drivers to the policy`}
                placement="top"
                className={classes.carToolTip}
              />
            )}
            <Button
              type="submit"
              key="addButton"
              mode="big"
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={!canAddCar}
            >
              Add Car
            </Button>
          </div>
        </Grid>
      </Form>
    </Formik>
  );
}

AddCar.propTypes = {
  onAdd: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired
};

AddCar.defaultProps = {
  disabled: false
};

export default AddCar;
