const styles = (theme) => ({
  'light-textFieldRoot': {
    backgroundColor: theme.colors.text_secondary_background
  },
  'light-textFieldInput': {
    color: theme.colors.text_secondary,
    zIndex: 2
  },
  'light-textFieldDisabled': {
    color: theme.colorPalette.green_10
  },
  'light-labelRoot': {
    color: theme.colors.text_disabled,
    zIndex: 1
  },
  'light-labelShrink': {
    color: theme.colors.text_secondary,
    zIndex: 1
  },
  'light-labelFocused': {
    color: `${theme.colors.text_secondary} !important`
  },
  'dark-labelRoot': {
    minWidth: 195
  },
  'dark-textFieldDisabled': {
    color: theme.colorPalette.white_10
  },
  'red-labelShrink': {
    color: theme.colorPalette.white
  },
  'red-textFieldRoot': {
    backgroundColor: theme.colors.white
  },
  'red-textFieldInput': {
    color: theme.colors.text_secondary,
    zIndex: 2
  }
});

export default styles;
