export default (theme) => ({
  info: {
    height: 24,
    width: 24,
    paddingLeft: 2
  },
  tooltip: {
    margin: theme.spacing(0, 1),
    backgroundColor: theme.colorPalette.green_10,
    fontSize: 14,
    cursor: 'pointer'
  }
});
