import React from 'react';
import PropTypes from 'prop-types';
import { CustomerSidebar, CustomerHeader, Policy, PolicySidebar } from 'customer/components';
import Customer from 'customer/customer';
import LayoutWithSidebar from 'core/components/layout-with-sidebar';
import { useCustomerProvider } from './useCustomerProvider';

export const PolicyRoute = ({ accountId, policyId, history }) => {
  useCustomerProvider({ accountId, policyId });
  return (
    <LayoutWithSidebar
      header={CustomerHeader}
      content={Policy}
      side={PolicySidebar}
      history={history}
      accountId={accountId}
      policyId={policyId}
    />
  );
};

PolicyRoute.propTypes = {
  accountId: PropTypes.string.isRequired,
  policyId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

export const CustomerRoute = ({ accountId, policyId, history }) => {
  useCustomerProvider({ accountId });
  return (
    <LayoutWithSidebar
      header={CustomerHeader}
      content={Customer}
      side={CustomerSidebar}
      history={history}
      accountId={accountId}
    />
  );
};

CustomerRoute.propTypes = {
  accountId: PropTypes.string.isRequired,
  policyId: PropTypes.string,
  history: PropTypes.object.isRequired
};

CustomerRoute.defaultProps = {
  policyId: null
};
