import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { Formik, Form } from 'formik';
import { useParams, Link } from 'react-router-dom';

import { FormField } from 'core/components/form';
import { ActionButton } from 'core/components/action-button';
import { Label } from 'core/components/label';
import ValueField from 'core/components/value-field';
import { ElemType, MapClassesToElem } from 'core/helpers/styles-helpers';
import { LottieLoader } from 'core/components/loading';
import closeIcon from './close.svg';
import { validationSchema } from './preview.validation';
import up from 'core/assets/svg/up.svg';
import down from 'core/assets/svg/down.svg';
import { awsDateToDateFormatter, capitalizeWords } from 'core/helpers/formatters';
import { AuthContext } from 'core/components/auth';
import styles from './preview.styles';

function getPriceDetails({ newTotalPrice }, total) {
  if (newTotalPrice > total) {
    return {
      arrow: up,
      label: 'increase'
    };
  }
  if (newTotalPrice < total) {
    return {
      arrow: down,
      label: 'decrease'
    };
  }
  return {
    arrow: null,
    label: 'not change'
  };
}

function Preview({
  classes,
  open,
  preview,
  loading,
  onClose,
  onConfirm,
  errors,
  total,
  action,
  policyType,
  cancelReason,
  determineboxTwoText
}) {
  const { canViewClarionDoorData } = useContext(AuthContext);
  const { arrow, label } = preview ? getPriceDetails(preview, total) : {};
  const canGenerateClarionDoorDiff =
    canViewClarionDoorData &&
    preview?.clarionDoorIds?.requestIdAfterPreview &&
    preview?.clarionDoorIds?.requestIdBeforePreview;
  const { id: accountId, policyId } = useParams();

  const boxTwoText =
    cancelReason && preview?.itemsChanged.includes('cancelReason') ? determineboxTwoText(cancelReason) : undefined;

  const [displaySubmitWarning, setDisplaySubmitWarning] = useState(false);

  // examining boxTwoText because it will rule out CNBX for us, in which the customer
  // does not get sent a notice so there should be no warning
  useEffect(() => {
    if (boxTwoText) {
      setDisplaySubmitWarning(true);
    } else {
      setDisplaySubmitWarning(false);
    }
  }, [boxTwoText]);

  return (
    <Dialog open={open} classes={MapClassesToElem(ElemType.Modal, classes)}>
      <DialogContent className={classNames([classes.mContent, { [classes.centered]: loading || errors }])}>
        <ActionButton type="edit" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
        {loading && (
          <div className={classes.loadingContainer}>
            <LottieLoader />
            <Label style={{ justifyContent: 'center' }}>Calculating changes...</Label>
          </div>
        )}
        {preview && !loading && (
          <>
            <Label type="titleSecondary" className={classes.title}>
              {action === 'reinstate' && 'Reinstate Policy Preview'}
              {action === 'cancel' && `Cancel ${policyType === 'A' ? 'Auto' : 'Home'} Policy Preview`}
              {!action && 'Policy Changes Preview'}
            </Label>
            {['cancel', 'reinstate'].includes(action) && (
              <div className={classes.fields}>
                <Label type="infoInnerTitle" className={classes.priceInfo}>
                  The policy will be {action === 'cancel' ? 'cancelled' : 'reinstated'} from
                  <strong style={{ marginLeft: 6 }}>{awsDateToDateFormatter(preview.endDate)}</strong>
                </Label>
                <ValueField value={preview.billingDescription} />
              </div>
            )}

            {!action && (
              <div className={classes.fields}>
                <ValueField label="Changes on Coverage" value={preview.coverageChange} />
                <ValueField label="The following items were changed" value={capitalizeWords(preview.itemsChanged)} />
                <ValueField label="Billing Description" value={preview.billingDescription} />
                {canGenerateClarionDoorDiff ? (
                  <Link
                    rel="noreferrer noopener"
                    target="_blank"
                    to={`/customer/${accountId}/policy/${policyId}/${preview.id}`}
                  >
                    <Label type="action">
                      Price difference not what you are expecting? Click here to open a new tab with ClarionDoor data.
                    </Label>
                  </Link>
                ) : (
                  canViewClarionDoorData && (
                    <Label type="status">Not able to get Clariondoor data to generate link.</Label>
                  )
                )}
                {arrow && (
                  <div className={classes.arrow}>
                    <img alt="Add segment" src={arrow} className={classes.arrowIcon} />
                  </div>
                )}
                {arrow && (
                  <Label type="infoInnerTitle" className={classes.priceInfo}>
                    Policy price will {label} from ${total.toFixed(2)} to
                    <span className={classes.boldPrice}>${(preview.newTotalPrice || 0).toFixed(2)}</span>
                  </Label>
                )}
              </div>
            )}
            <Formik
              onSubmit={(values) => {
                onConfirm(preview.id, values.notes);
              }}
              validationSchema={validationSchema}
              initialValues={{
                ...preview,
                notes: boxTwoText || ''
              }}
            >
              <Form>
                <Grid container justify="space-around" alignItems="flex-start" spacing={2}>
                  <FormField
                    id="notes"
                    name="notes"
                    type="string"
                    label="Add an internal note"
                    mode="light"
                    xs={9}
                    disabled={false}
                  />
                  <Button
                    id="submit"
                    name="submit"
                    type="submit"
                    variant="contained"
                    label="Add an internal note"
                    mode="light"
                    xs={3}
                    color="primary"
                    className={classes.submit}
                  >
                    Create a new version
                  </Button>
                </Grid>
              </Form>
            </Formik>
            {displaySubmitWarning && (
              // type="statusWarning" uppercases the displayed text automatically
              <Label type="statusWarning" className={classes.submitWarning}>
                warning: this internal note will be included on the cancellation form sent to the customer
              </Label>
            )}
          </>
        )}
        {errors &&
          errors.map((error) => (
            <Label type="infoValue" key={error} className={classes.errors}>
              {error}
            </Label>
          ))}
      </DialogContent>
    </Dialog>
  );
}

Preview.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  total: PropTypes.number,
  action: PropTypes.oneOf(['cancel', 'reinstate']),
  preview: PropTypes.shape({
    coverageChange: PropTypes.string,
    itemsChanged: PropTypes.string,
    billingDescription: PropTypes.string,
    newPremium: PropTypes.number,
    newTotalPrice: PropTypes.number,
    id: PropTypes.string,
    endDate: PropTypes.string
  }),
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  policyType: PropTypes.string.isRequired,
  cancelReason: PropTypes.string,
  determineboxTwoText: PropTypes.func
};

Preview.defaultProps = {
  preview: null,
  errors: null,
  total: 0,
  action: undefined,
  cancelReason: undefined,
  determineboxTwoText: undefined
};

export default withStyles(styles)(memo(Preview));
