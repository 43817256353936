/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { withDisabledState } from 'customer/disabled-context';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { HomeCoverage, Umbrella } from './coverages';
import Detail from './detail';
import { SchedulePP } from './schedule-pp';

const HomePolicy = memo(({ disabled }) => {
  return (
    <>
      <Detail disabled={disabled} />
      <SchedulePP disabled={disabled} />
      <HomeCoverage disabled={disabled} />
      <Umbrella disabled={disabled} />
    </>
  );
});

HomePolicy.propTypes = {
  disabled: PropTypes.bool
};

HomePolicy.defaultProps = {
  disabled: false
};

export default flowRight(withDisabledState)(HomePolicy);
