import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import { Auth as AwsAuth, Hub } from 'aws-amplify';
import { StaffSpeedDial } from 'core/components/speed-dial';
import ZendeskChat from 'core/components/zendesk-chat';
import { createStore, StoreProvider } from 'core/store';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import awsConfig from '../../aws-exports';
import { identify, track } from '../helpers/analytics';
import { apolloClient } from '../apollo-client';
import Auth from './auth';
import ErrorBoundary from './error-boundary';
import Layout from './layout/layout';
import Routes from './routes';
import ThemeProvider from './theme';
import { colors, fonts } from './theme/variables';
import { Toast, ToastProvider } from './toast';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://92e31532c8f0419eb6c9d3ce2f620928@sentry.io/1501891',
    environment: window.location.hostname
  });
}

const rootStyles = {
  '@global': {
    '*': {
      fontFamily: fonts.LibreFranklin
    },
    body: {
      backgroundColor: colors.page__background_primary
    },
    ':focus': {
      outline: 'none'
    },
    '::-moz-focus-inner': {
      border: 0
    },
    a: {
      textDecoration: 'none',
      display: 'contents' // https://drafts.csswg.org/css-display/#valdef-display-contents
    }
  }
};

const store = createStore(apolloClient);

const App = () => {
  useEffect(() => {
    if (awsConfig.trackingEnabled) {
      Hub.listen('auth', (capsule) => {
        // eslint-disable-next-line default-case
        switch (capsule.payload.event) {
          case 'signIn': {
            AwsAuth.currentAuthenticatedUser().then((user) => {
              window.analytics.alias(`staff-${user.username}`);
              identify({ username: user.username, email: user.attributes.email });
              track('Staff Sign In', { username: user.username, email: user.attributes.email });
            });
            break;
          }
          case 'signIn_failure': {
            track('Unsuccesful Staff Sign In', {
              reason: `${capsule.payload.message} ${capsule.payload.data.message}`
            });
            break;
          }
        }
      });
    }
  }, []);

  return (
    <ThemeProvider>
      <StoreProvider store={store}>
        <ToastProvider>
          <Auth client={apolloClient}>
            <ApolloProvider client={apolloClient}>
              <CssBaseline />
              <ErrorBoundary>
                <Router basename="/">
                  <Layout>
                    <Routes />
                  </Layout>
                  <StaffSpeedDial />
                  <ZendeskChat />
                </Router>
              </ErrorBoundary>
              <Toast />
            </ApolloProvider>
          </Auth>
        </ToastProvider>
      </StoreProvider>
    </ThemeProvider>
  );
};

export default withStyles(rootStyles)(App);
