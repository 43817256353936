import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { stripeBankStatus } from '@ourbranch/lookups';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';

import { Loading } from 'core';
import { withToast } from 'core/components/toast';
import { Card } from 'core/components/card';
import { useStore } from 'core/store/store.mobx';
import Microdeposits from './microdeposits';
import useStyles from './microdeposits.styles';

const MicrodepositWrapper = observer(({ toast, stripeCustomerId, policyId, accountId }) => {
  const classes = useStyles();
  const { account } = useStore();
  const { policies: store } = account;
  const [loading, setLoading] = useState(false);
  const alreadyVerified = store.getStripeBankInfo(policyId)?.achStatus === stripeBankStatus.Verified;

  reaction(
    () => ({
      achPolicies: store.achPolicies
    }),
    async ({ achPolicies }, reaction) => {
      if (
        account.id === accountId && // has the store completely refreshed?
        !!achPolicies.length &&
        !store.getStripeBankInfo(policyId)?.achStatus && // has this reaction already run for current policy?
        !loading
      ) {
        setLoading(true);
        try {
          await store.generateStripeBankInfo(accountId, policyId, stripeCustomerId);
        } catch (error) {
          toast.notify({
            type: 'error',
            message: error.message
          });
        }
        setLoading(false);
        reaction.dispose();
      }
    },
    { fireImmediately: true }
  );

  if (alreadyVerified) {
    return <></>;
  }

  if (loading && !alreadyVerified) {
    return (
      <Card className={classes.loadingCard}>
        <Loading noBackground />
      </Card>
    );
  }

  return <Microdeposits stripeCustomerId={stripeCustomerId} policyId={policyId} />;
});

MicrodepositWrapper.propTypes = {
  toast: PropTypes.object.isRequired,
  stripeCustomerId: PropTypes.string,
  policyId: PropTypes.string,
  accountId: PropTypes.string
};

export default withToast(MicrodepositWrapper);
