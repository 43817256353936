import React, { memo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import NavigationPrompt from 'react-router-navigation-prompt';

import { Label } from 'core';
import LeavePageDialog from 'core/components/leave-page-dialog';
import Field from 'core/components/form/form.v2';
import { AuthContext } from 'core/components/auth';
import styles from './footer.styles';

function Footer({ title, classes, onClick, form, leftForm, buttonLabel }) {
  useEffect(() => {
    window.onbeforeunload = () => true;
  }, []);
  const session = useContext(AuthContext);
  return (
    <div className={classes.container}>
      <NavigationPrompt renderIfNotActive={false} when>
        {({ isActive, onCancel, onConfirm }) => {
          return <LeavePageDialog open={isActive} onClose={onCancel} cb={onConfirm} />;
        }}
      </NavigationPrompt>
      <Grid container justify="space-between" className={classes.footerInner}>
        <div className={classNames(classes.footerItem, { [classes.withLeftForm]: !!leftForm })}>
          <Label className={classNames(classes.label, { [classes.leftFormLabel]: !!leftForm })} type="subtitle">
            {title}
          </Label>
          {leftForm}
        </div>
        <div
          className={classNames(classes.footerItem, { [classes.right]: !!form, [classes.rightWithLeft]: !!leftForm })}
        >
          {form}
        </div>
        <Grid className={classes.footerItem}>
          {session.isService && (
            <Field
              type="checkbox"
              id="skipImmediateBillOrRefund"
              name="skipImmediateBillOrRefund"
              mode="light"
              label="Don't require immediate payment or refund"
              className={classNames({ [classes.checkbox]: !!leftForm })}
              disabled={false} // cancel policy sets all form fields to disabled, so need to manually exclude this field
            />
          )}
          <Button
            variant="contained"
            color="primary"
            data-cy="preview-policy-changes"
            onClick={onClick}
            className={classes.btn}
          >
            <Label className={classes.btnLabel}>{buttonLabel || 'Preview policy modifications'}</Label>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

Footer.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  form: PropTypes.node,
  leftForm: PropTypes.node,
  buttonLabel: PropTypes.string
};

Footer.defaultProps = {
  form: undefined,
  leftForm: undefined,
  title: 'Create new version',
  buttonLabel: 'Preview policy modifications'
};

export default withStyles(styles)(memo(Footer));
