// note: using the prefix "um" because "in" is a reserved word
export const umal = () => import(/* webpackChunkName: "alabama-umbi" */ './al-umbi.js');
export const umaz = () => import(/* webpackChunkName: "arizona-umbi" */ './az-umbi.js');
export const umco = () => import(/* webpackChunkName: "colorado-umbi" */ './co-umbi.js');
export const umil = () => import(/* webpackChunkName: "illinois-umbi" */ './il-umbi.js');
export const umin = () => import(/* webpackChunkName: "indiana-umbi" */ './in-umbi.js');
export const umks = () => import(/* webpackChunkName: "kansas-umbi" */ './ks-umbi.js');
export const ummd = () => import(/* webpackChunkName: "maryland-umbi" */ './md-umbi.js');
export const ummi = () => import(/* webpackChunkName: "michigan-umbi" */ './mi-umbi.js');
export const ummo = () => import(/* webpackChunkName: "missouri-umbi" */ './mo-umbi.js');
export const umne = () => import(/* webpackChunkName: "nebraska-umbi" */ './ne-umbi.js');
export const umoh = () => import(/* webpackChunkName: "ohio-umbi" */ './oh-umbi.js');
export const umok = () => import(/* webpackChunkName: "oklahoma-umbi" */ './ok-umbi.js');
export const umpa = () => import(/* webpackChunkName: "pennsylvania-umbi" */ './pa-umbi.js');
export const umtx = () => import(/* webpackChunkName: "texas-umbi" */ './tx-umbi.js');
export const umut = () => import(/* webpackChunkName: "utah-umbi" */ './ut-umbi.js');
export const umva = () => import(/* webpackChunkName: "virginia-umbi" */ './va-umbi.js');
export const umwi = () => import(/* webpackChunkName: "wisconsin-umbi" */ './wi-umbi.js');
