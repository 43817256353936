import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import classnames from 'classnames';

import { Card } from 'core/components/card';
import NotificationIconWhite from './assets/alert-white.svg';
import NotificationIconOrange from './assets/alert-orange.svg';
import useStyles from './notification-card.styles';

const icon = {
  primary: NotificationIconWhite,
  secondary: NotificationIconOrange,
  tertiary: NotificationIconWhite,
  quaternary: NotificationIconWhite
};

const NotificationCard = ({ children, type }) => {
  const classes = useStyles();

  return (
    <Card className={classnames(classes.container, classes[type])}>
      <Grid className={classes.notificationContentContainer}>
        <img src={icon[type]} className={classes.icon} alt="Notification Icon" />
        {children}
      </Grid>
    </Card>
  );
};

NotificationCard.defaultProps = {
  type: 'primary'
};

NotificationCard.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary'])
};

export default NotificationCard;
