// pass billing day of month to calculate monthly, omit for pay in full
export const getRenewalChargeDate = (effectiveDate, policyType, monthlyBillingDay = 0) => {
  const calculateDayAndMonth = () => {
    const effectiveMonth = new Date(effectiveDate).getMonth();
    const effectiveDay = new Date(effectiveDate).getDate();
    let billingMonth = effectiveMonth + (policyType === 'H' ? 11 : 5);
    let billingDay = monthlyBillingDay;

    if (billingDay < new Date().getDate()) {
      billingMonth += 1;
    }

    if (billingDay === 0) {
      billingDay = new Date(new Date(effectiveDate).setDate(effectiveDay - 15)).getDate();
      if (billingDay > 28) {
        billingDay = 1;
      }
    }
    return { billingMonth, billingDay };
  };

  const { billingMonth, billingDay } = calculateDayAndMonth();

  const date = new Date(new Date(new Date(effectiveDate).setMonth(billingMonth)).setDate(billingDay))
    .toISOString()
    .split('T')[0]
    .split('-');
  return `${date[1]}/${date[2]}/${date[0]}`;
};
