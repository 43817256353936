import React, { memo, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import flowRight from 'lodash-es/flowRight';
import { Tabs, Tab, withStyles } from '@material-ui/core';

import { AuthContext } from 'core/components/auth';
import Documents from './components/documents';
import Policies from './components/policies';
import Tickets from './components/tickets';
import Claims from './components/claims';
import { CustomerNotifications } from './components/customer-notifications';
import { useStore } from 'core/store';
import styles from './customer.styles';

const Customer = observer(({ match, history, classes }) => {
  const [activeTab, setActiveTab] = useState(0);
  const { canEdit } = useContext(AuthContext);

  const { account } = useStore();
  const { notifications } = account;

  const handleTabChange = useCallback(
    (event, tab) => {
      setActiveTab(tab);
    },
    [setActiveTab]
  );

  return (
    <>
      {notifications?.length && <CustomerNotifications />}
      {canEdit && (
        <Tabs value={activeTab} variant={'fullWidth'} onChange={handleTabChange} className={classes.tabs}>
          <Tab label="Details" className={classes.tab} />
          <Tab label="Tickets" className={classes.tab} />
          <Tab label="Claims" className={classes.tab} />
        </Tabs>
      )}
      {activeTab === 0 && (
        // DETAILS
        <>
          <Policies
            id={match.params.id}
            onClick={(policy) => history.push(`/customer/${match.params.id}/policy/${policy.id}`)}
          />
          <Documents showPolicyIdColumn />
        </>
      )}
      {activeTab === 1 && <Tickets accountId={match.params.id} />}
      {activeTab === 2 && <Claims />}
    </>
  );
});

Customer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  classes: PropTypes.object.isRequired
};

export default flowRight(withRouter, withStyles(styles), memo)(Customer);
