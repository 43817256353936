export const claimStatusMap = {
  O: 'OPEN',
  R: 'REOPENED',
  C: 'CLOSED'
};

export const labelStyleMap = {
  OPEN: 'statusOpen',
  CLOSED: 'statusClosed',
  REOPENED: 'statusWarning'
};
