import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';

import { Discount } from '../discount';

const Inventory = ({ isActive, ...discount }) => {
  const { setFieldValue, values } = useFormikContext();

  const isApplied = values.global ? values.global.discountInventoryScore : isActive;

  const onAddClick = useCallback(() => {
    setFieldValue('global.discountInventoryScore', true);
  }, [setFieldValue]);

  const onRemoveClick = useCallback(() => {
    setFieldValue('global.discountInventoryScore', false);
  }, [setFieldValue]);

  return <Discount {...discount} isActive={isApplied} onAdd={onAddClick} onRemove={onRemoveClick} />;
};

export default Inventory;
