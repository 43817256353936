import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { types } from 'mobx-state-tree';
import { Provider as MobxProvider, inject, observer, MobXProviderContext } from 'mobx-react';
import makeInspectable from 'mobx-devtools-mst';

import OfferStore, { CheckoutStatus, FormAction } from './offer-store';
import { QuoteStore } from './quote-store';
import { AccountStore } from './account-store';
import { SearchStore } from './search-store';

const initialState = {
  offer: {
    errors: [],
    loading: false,
    offer: null,
    openDialog: false,
    mortgageOptions: null,
    status: CheckoutStatus.Initial,
    purchasedAccountId: null,
    formAction: FormAction.Update,
    includeConnectedHome: false,
    notifications: null,
    vinDiscrepancies: null,
    successfulNewVins: null,
    carAndDriverSuggestions: null,
    checkoutFormData: null,
    savingCheckoutFormData: false,
    showConectedHomeModal: false
  },
  account: {
    id: '',
    fname: '',
    lname: '',
    phoneNumber: '',
    additionalPhoneNumbers: [],
    email: '',
    created: '',
    mailingAddress: null,
    tickets: null,
    accountLoading: false,
    ticketsLoading: false,
    purchasedAccountId: '',
    inviter: {},
    policies: {
      loading: false,
      list: [],
      loadingDocuments: false,
      documents: undefined,
      policy: {
        segments: [],
        changed: false,
        segment: null,
        segmentId: null,
        policy: null,
        loading: true,
        holdCards: [],
        holdCardLoading: undefined,
        billingDetails: null,
        policyEquityStatus: {},
        inspection: undefined
      }
    },
    pledges: {},
    community: {}
  },
  quote: {
    loading: false,
    errorCodes: [],
    offer: null,
    prefillData: null
  },
  search: {
    loading: false,
    customersResults: {},
    offersResults: {},
    policiesResults: {},
    searchString: '',
    showCustomerMatchesModal: false,
    expandedSearch: false,
    showOfferMatchesModal: false
  },
  matchSearch: {
    loading: false,
    customersResults: {},
    offersResults: {},
    searchString: '',
    showCustomerMatchesModal: false,
    showOfferMatchesModal: false,
    resultsPerPage: 5
  },
  userPreferences: {
    algoliaKey: undefined
  }
};

const UserPreferences = types
  .model({
    algoliaKey: types.maybe(types.string)
  })
  .actions((self) => ({
    setUserPreferences(userPreferences) {
      const { algoliaKey } = userPreferences;

      self.algoliaKey = algoliaKey;
    }
  }));

export const StaffStore = types
  .model({
    offer: OfferStore,
    account: AccountStore,
    quote: QuoteStore,
    search: SearchStore,
    matchSearch: SearchStore,
    userPreferences: UserPreferences
  })
  .actions((self) => ({
    resetStore() {
      self.offer = initialState.offer;
      self.quote = initialState.quote;
      self.account = initialState.account;
    }
  }));

export const Provider = ({ store, children }) => <MobxProvider store={store}>{children}</MobxProvider>;

Provider.propTypes = {
  store: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export function createStore(client) {
  const staffStore = StaffStore.create(initialState, { client });
  makeInspectable(staffStore);
  if (process.env.NODE_ENV !== 'production') {
    window.store = staffStore;
  }
  return staffStore;
}

export const useStore = () => {
  const stores = useContext(MobXProviderContext);
  const store = stores?.store;
  return store;
};

export function withStore(target) {
  return inject('store')(observer(target));
}
