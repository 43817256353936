"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stateSpecificAutoUnderwriting = exports.noPPCExclusionStates = exports.driverViolationsDisqualifyingCourseDiscountStates = exports.minPremiumCheckStates = exports.assignDriversToCarsStates = exports.excessCarsByDriverCarAssignmentStates = exports.noUMPDIfCollisionOnCarStates = exports.CCWStates = exports.windstormMitigationDiscountStates = exports.enhancedRoofWindstormStates = exports.defaultCommunityDriveStates = exports.noMedPayStates = exports.noUMPDStates = exports.UMPDOnlyWithUMBIStates = exports.policyLevelUMPDStates = exports.separate3NAFsCheckStates = exports.combinedUMUIM = exports.noExcludedDriversStates = exports.noUDRsAllowedStates = exports.mandatoryPIP = exports.mandatoryUM = exports.mandatoryPolicyUMPD = exports.percentageWindHailDeductible = exports.homeownersRights = exports.limitedBranchWindhailCoverage = exports.mandatoryWindHailDeductible = exports.hurricaneDeductibleStates = exports.courseDiscountsAges = exports.seniorDiscountByAgeOnlyStates = exports.courseDiscountTypeByStates = exports.alabamaLogicInstallmentFeeStates = exports.myCommunityDiscountStateCaps = exports.vouchDiscountStateCaps = exports.vouchDiscountStates = exports.homeCoveragesToSave = exports.autoCoveragesToSave = exports.disableExcludeDriverStates = exports.uimPremiumCoverageArray = exports.umPremiumCoverageArray = exports.umUimCoverageArray = exports.validZipCodeRanges = exports.excludedDriverSignsStates = exports.gbckFormStates = exports.adwrFormStates = exports.noTrailerCoverageStates = exports.noConnectedHomeProviderSpecialOfferByState = exports.restrictedPurchaseStates = exports.branchLiveStates = exports.insurancePaper = exports.bixStates = void 0;
exports.mortgageHoldersAffinities = exports.HomeSecurityPartnerCustomerType = exports.vehicleEquipmentStatusCode = exports.minimumDrivingAge = exports.rentersPolicyAvailable = exports.mandatoryWindHailDeductibleByCounty = exports.mandatoryWindHailDeductibleByZip = exports.mandatoryWindHailDeductibleValueMinimumStates = exports.coastalCounties = exports.ineligibleCoastalCities = exports.mineSubsidenceRequired = exports.mineSubsidenceOptional = exports.mineSubsidenceDefaulted = exports.offerExcessUM = exports.coverageFOffered = exports.fixableRejectionCodes = exports.rejectCodes = exports.errorCodes = exports.communityDriveAvailableStates = exports.umpdDeductibleByState = exports.policyLevelAndCopyToCarUMPDStates = exports.pipStates = exports.discountPaperlessStates = exports.discountInventoryScoreStates = exports.advancedConnectedHomeStates = exports.leaseLoanStates = exports.pipDefaultStates = exports.coverageBPDefaultStates = exports.windHailDeductibleDefaultStates = exports.minLimitRentalStates = exports.claimLimitRentalStates = exports.driverCarAssignmentStates = exports.fcraDriverRiskStates = exports.mvrDriversLicenseNotRequiredStates = exports.mvrPostBindStates = exports.mvrAlsoPreBindStates = exports.mvrStates = exports.roofSurfacesExtendedCompositionMaxAge = exports.sewerBackupStates = exports.waterBackupNameByState = exports.autoViolationNoRateCodes = exports.rceMinimum = exports.rceLimitOnline = exports.yearBuiltMinimum = exports.neverLoseUmbrellaByState = exports.neverLoseCommunityDriveParticipationByState = exports.ratingLookbackByState = exports.disqualifyingChargesByState = exports.ineligibleDistanceToOceanCodesStates = exports.ineligibleForUWHomeClaimGroupStates = void 0;
exports.preBindMVRStates = exports.annualMilesDrivenLimits = exports.stormShuttersStates = exports.specificDriverAgeLogicStates = exports.passiveRestraintStates = exports.cancelCodeInformationalArray = exports.cancelNoticeParagraphText = exports.stripeBankStatus = exports.paymentType = exports.paymentMethod = exports.planType = exports.policyType = exports.priorLapseDays = exports.financialResponsibilityTiersImpactingPrice = exports.fpcClassesImpactingPrice = exports.homeClaimCodeToDescription = exports.violationCodeToDescription = exports.connectedHomeProvidersAllowingVerification = exports.homeClaimPoints = exports.monarchPoints = exports.ratingSeriousness = exports.HighestEducation = exports.connectedHomePromoDetails = exports.newCustomersDefaultDevices = exports.mortgageClauses = void 0;
const courseDiscountTypes = {
    AVOIDANCE: 'AVOIDANCE',
    DEFENSIVE: 'DEFENSIVE',
    PREVENTION: 'PREVENTION',
    SENIOR: 'SENIOR'
};
exports.bixStates = {
    AZ: true,
    IN: true,
    MD: true,
    MI: true,
    NE: true,
    OH: true,
    OK: true,
    PA: true,
    TX: true,
    UT: true,
    WI: true
};
exports.insurancePaper = {
    GSNIC: 'GSNIC',
    BIX: 'BIX'
};
exports.branchLiveStates = [
    'AL',
    'AZ',
    'CO',
    'IL',
    'IN',
    'MD',
    'MI',
    'MO',
    'NE',
    'OH',
    'OK',
    'PA',
    'TX',
    'UT',
    'WI'
];
exports.restrictedPurchaseStates = ['MI'];
exports.noConnectedHomeProviderSpecialOfferByState = { MD: ['SimpliSafe'] };
exports.noTrailerCoverageStates = ['CO', 'VA', 'NY', 'NJ'];
exports.adwrFormStates = {
    AZ: true,
    CO: false,
    IN: true,
    MD: true,
    MI: false,
    NE: true,
    OH: true,
    OK: true,
    PA: false,
    TX: true,
    UT: true,
    WI: true
};
exports.gbckFormStates = {
    AL: true,
    AZ: true,
    CO: true,
    IN: true,
    MD: true,
    MI: true,
    NE: true,
    OH: true,
    OK: true,
    PA: false,
    TX: true,
    UT: true,
    WI: true
};
exports.excludedDriverSignsStates = {
    UT: true
};
/**
 * Valid Zip Codes can be represented as either a single value, or "tuples" containing the min & max values of a range.
 * Values are inclusive.
 */
exports.validZipCodeRanges = {
    AL: [[35000, 39999]],
    AZ: [[85001, 86556]],
    CO: [[80001, 82000]],
    IL: [[60001, 62999]],
    IN: [[46001, 47997]],
    KS: [[66000, 69999]],
    MD: [[20331, 21999]],
    MI: [[48001, 49999]],
    MO: [[63001, 65899]],
    NE: [[68001, 69999]],
    OH: [[43001, 45999]],
    OK: [[73001, 74999]],
    PA: [[15001, 19999]],
    TX: [[73301, 88589]],
    UT: [[84000, 84999]],
    VA: [[20000, 24999]],
    WI: [[53001, 55000]]
};
exports.umUimCoverageArray = {
    AL: ['UM'],
    AZ: ['UM_UIM'],
    CO: ['UM_UIM'],
    IL: ['UMUIM', 'UM_UIM'],
    IN: ['UM_UIM'],
    KS: ['UM'],
    MD: ['UM', 'EUIM'],
    MO: ['UM', 'UIM'],
    MI: ['UMUIM'],
    NE: ['UM', 'UIM'],
    OH: ['UM_UIM'],
    OK: ['UM'],
    PA: ['UM', 'UIM'],
    TX: ['UMUIMBI', 'UMUIMPD'],
    UT: ['UM', 'UIM'],
    VA: ['UM'],
    WI: ['UM', 'UIM']
};
exports.umPremiumCoverageArray = {
    AL: ['UM_UIM'],
    AZ: ['UM_UIM'],
    CO: ['UM_UIM'],
    IL: ['UMUIM', 'UM_UIM'],
    IN: ['UM_UIM'],
    KS: ['UM'],
    MD: ['UM'],
    MO: ['UM'],
    MI: ['UMUIM'],
    NE: ['UM'],
    OH: ['UM_UIM'],
    OK: ['UM'],
    PA: ['UM'],
    TX: ['UMUIMBI', 'UMUIMPD'],
    UT: ['UM'],
    VA: ['UM_UIM'],
    WI: ['UM']
};
exports.uimPremiumCoverageArray = {
    AL: [],
    AZ: [],
    CO: [],
    IL: [],
    IN: [],
    KS: [],
    MD: ['EUIM'],
    MO: ['UIM'],
    MI: [],
    NE: ['UIM'],
    OH: [],
    OK: [],
    PA: ['UIM'],
    TX: [],
    UT: ['UIM'],
    VA: [],
    WI: ['UIM']
};
exports.disableExcludeDriverStates = { MI: true, WI: true };
exports.autoCoveragesToSave = [
    'BI',
    'PD',
    'UM',
    'UIM',
    'UM_UIM',
    'EUIM',
    'EUIMPD',
    'UMPD',
    'UMUIM',
    'UMUIMBI',
    'UMUIMPD',
    'COMP',
    'COLL',
    'MED',
    'PIP',
    'GPIP',
    'TRNSP',
    'ROAD',
    'ACPE',
    'ACQ_EXP',
    'OPS_EXP',
    'CONTENTS',
    'COMP_TRLR',
    'COLL_TRLR',
    'LOAN',
    'LPD',
    'PPI',
    'ME',
    'WL',
    'ACR',
    'INCM_LOSS',
    'COMBO_FPB',
    'FNRL_BEN',
    'MED_EXP',
    'ACC_DTH',
    'PIP_scenarios',
    'GPIP_scenarios',
    'affinity_discount_savings',
    'total_statutory_otherLiability',
    'total_statutory_PIP',
    'total_statutory_physDam'
];
exports.homeCoveragesToSave = [
    'cov_ms_premium',
    'affinity_discount_savings',
    'connected_home_discount_savings',
    'wind',
    'hurricane'
];
exports.vouchDiscountStates = {
    AL: true,
    AZ: true,
    CO: true,
    MD: true,
    MO: true,
    IL: true,
    IN: true,
    KS: true,
    MI: true,
    NE: true,
    OH: true,
    OK: true,
    PA: true,
    TX: true,
    UT: true,
    VA: true,
    WI: true
};
exports.vouchDiscountStateCaps = {
    AL: 5,
    AZ: 5,
    CO: 5,
    MD: 5,
    MO: 5,
    IL: 5,
    IN: 5,
    KS: 5,
    MI: 5,
    OH: 5,
    OK: 5,
    TX: 5,
    UT: 5,
    VA: 5,
    WI: 5
};
exports.myCommunityDiscountStateCaps = {
    AZ: 25,
    IL: 0,
    IN: 0,
    MO: 50,
    OH: 0,
    TX: 0
};
exports.alabamaLogicInstallmentFeeStates = {
    AL: true
};
exports.courseDiscountTypeByStates = {
    AL: courseDiscountTypes.SENIOR,
    AZ: false,
    CO: courseDiscountTypes.SENIOR,
    IL: courseDiscountTypes.SENIOR,
    IN: false,
    KS: courseDiscountTypes.AVOIDANCE,
    MI: false,
    MO: false,
    NE: false,
    OH: courseDiscountTypes.SENIOR,
    OK: courseDiscountTypes.DEFENSIVE,
    PA: courseDiscountTypes.DEFENSIVE,
    TX: false,
    UT: courseDiscountTypes.SENIOR,
    WI: false
};
exports.seniorDiscountByAgeOnlyStates = {
    MI: 65
};
exports.courseDiscountsAges = { AL: 55, CO: 55, IL: 60, KS: 1, OH: 60, OK: 1, PA: 55, UT: 55, VA: 60 };
exports.hurricaneDeductibleStates = {
    AZ: false,
    CO: false,
    IL: false,
    IN: false,
    KS: false,
    MO: false,
    OH: false,
    TX: false
};
exports.mandatoryWindHailDeductible = { MO: true };
exports.limitedBranchWindhailCoverage = { AL: true };
exports.homeownersRights = {
    AL: 'https://www.aldoi.gov/PDF/Consumers/HomeownersBillofRightsAct.pdf'
};
/** States that, when they have a minimum % deductible, only allow % deductibles */
exports.percentageWindHailDeductible = { MD: true };
exports.mandatoryPolicyUMPD = { MD: true };
exports.mandatoryUM = { IL: true, MD: true, MO: true };
exports.mandatoryPIP = { KS: true, MD: true };
exports.noUDRsAllowedStates = ['IN', 'MD'];
exports.noExcludedDriversStates = ['KS', 'WI'];
exports.combinedUMUIM = {
    AL: true,
    CO: true,
    IL: true,
    IN: true,
    KS: true,
    MD: true,
    MI: true,
    NE: true,
    OH: true,
    OK: true,
    PA: false,
    TX: true,
    UT: false,
    VA: true,
    WI: false
};
exports.separate3NAFsCheckStates = { MD: true };
exports.policyLevelUMPDStates = { IN: true, MD: true, TX: true, VA: true };
exports.UMPDOnlyWithUMBIStates = { UT: true };
exports.noUMPDStates = { AL: true, MI: true };
exports.noMedPayStates = { MI: true, MD: true, UT: true };
exports.defaultCommunityDriveStates = { AZ: true, CO: true };
exports.enhancedRoofWindstormStates = { SC: false };
exports.windstormMitigationDiscountStates = { AL: true };
/** CCW violation type */
exports.CCWStates = { AL: true };
exports.noUMPDIfCollisionOnCarStates = { VA: true };
exports.excessCarsByDriverCarAssignmentStates = { VA: true };
exports.assignDriversToCarsStates = { VA: true };
exports.minPremiumCheckStates = { VA: 10 };
exports.driverViolationsDisqualifyingCourseDiscountStates = {
    AL: ['AAF', 'CRD', 'DR', 'FEL', 'FLE', 'HOM', 'LDL', 'LTS', 'MAJ', 'RKD', 'SUS', 'DWI']
};
exports.noPPCExclusionStates = { MI: true };
exports.stateSpecificAutoUnderwriting = { MI: true };
exports.ineligibleForUWHomeClaimGroupStates = {
    MD: ['F', 'G']
};
exports.ineligibleDistanceToOceanCodesStates = {
    MD: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6'],
    VA: ['01', '02', '03', '04', '05', '06', '1', '2', '3', '4', '5', '6']
};
exports.disqualifyingChargesByState = {
    MD: ['CRD', 'DR', 'DWI', 'FDL', 'FEL', 'FLE', 'FSV', 'HOM', 'LDL', 'LTS', 'RKD', 'SLV', 'SUS'],
    MI: ['CRD', 'DR', 'DWI', 'FEL', 'FLE', 'FSV', 'HOM', 'LDL', 'LTS', 'RKD', 'SLV', 'SUS']
};
exports.ratingLookbackByState = {
    KS: 35,
    MD: 35,
    PA: 35
};
exports.neverLoseCommunityDriveParticipationByState = {
    PA: true
};
exports.neverLoseUmbrellaByState = {
    PA: true
};
exports.yearBuiltMinimum = {
    MI: 1600
};
exports.rceLimitOnline = {
    AL: 950000,
    AZ: 950000,
    CO: 950000,
    IL: 950000,
    IN: 950000,
    KS: 950000,
    MD: 950000,
    MI: 950000,
    MO: 950000,
    NE: 950000,
    OH: 950000,
    PA: 950000,
    TX: 850000,
    UT: 950000,
    VA: 950000,
    WI: 950000
};
exports.rceMinimum = {
    MI: 50000
};
exports.autoViolationNoRateCodes = {
    MO: ['PUN', 'NAF', 'CMP', 'CMU'],
    TX: ['PUN', 'CMP', 'CMU']
};
exports.waterBackupNameByState = {
    MD: 'Sewer Backup'
};
exports.sewerBackupStates = {
    MD: true
};
exports.roofSurfacesExtendedCompositionMaxAge = {
    AL: 15,
    AZ: 15,
    CO: 15,
    IL: 15,
    IN: 15,
    KS: 15,
    MD: 15,
    MI: 15,
    MO: 10,
    NE: 15,
    OH: 15,
    OK: 15,
    PA: 15,
    TX: 15,
    UT: 15,
    VA: 15,
    WI: 15
};
exports.mvrStates = ['MO', 'MN', 'OH'];
exports.mvrAlsoPreBindStates = ['MI'];
exports.mvrPostBindStates = [
    'AL',
    'AR',
    'CA',
    'CO',
    'DC',
    'HI',
    'KS',
    'LA',
    'MA',
    'MI',
    'MS',
    'MT',
    'NE',
    'NY',
    'VT'
];
exports.mvrDriversLicenseNotRequiredStates = [
    'CA',
    'FL',
    'IN',
    'ME',
    'MI',
    'MN',
    'MO',
    'NE',
    'NH',
    'NY',
    'OH',
    'OK',
    'VA',
    'WI',
    'WY'
];
exports.fcraDriverRiskStates = [
    'AK',
    'AZ',
    'CT',
    'DE',
    'FL',
    'GA',
    'IA',
    'ID',
    'IL',
    'IN',
    'KY',
    'ME',
    'MD',
    'NC',
    'ND',
    'NH',
    'NM',
    'NJ',
    'NV',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'WA',
    'WI',
    'WV',
    'WY'
];
exports.driverCarAssignmentStates = {
    VA: true
};
exports.claimLimitRentalStates = {
    VA: true
};
exports.minLimitRentalStates = {
    AL: 30,
    AZ: 30,
    CO: 30,
    IL: 40,
    IN: 30,
    KS: 40,
    MD: 30,
    MI: 30,
    MO: 30,
    NE: 40,
    OH: 30,
    OK: 30,
    PA: 30,
    TX: 30,
    UT: 30,
    VA: 600,
    WI: 40
};
exports.windHailDeductibleDefaultStates = {
    OK: 0.01
};
exports.coverageBPDefaultStates = {
    KS: 0
};
exports.pipDefaultStates = {
    KS: '4500'
};
exports.leaseLoanStates = {
    AL: { isBix: true, isNotBix: true },
    AZ: { isBix: true, isNotBix: false },
    CO: { isBix: true, isNotBix: true },
    IL: { isBix: true, isNotBix: false },
    IN: { isBix: true, isNotBix: true },
    MD: { isBix: true, isNotBix: true },
    MI: { isBix: true, isNotBix: true },
    MO: { isBix: true, isNotBix: false },
    NE: { isBix: true, isNotBix: true },
    OH: { isBix: true, isNotBix: false },
    OK: { isBix: true, isNotBix: true },
    PA: { isBix: true, isNotBix: true },
    TX: { isBix: true, isNotBix: false },
    UT: { isBix: true, isNotBix: true },
    WI: { isBix: true, isNotBix: true }
};
exports.advancedConnectedHomeStates = {
    AL: { isBix: true, isNotBix: true },
    AZ: { isBix: true, isNotBix: false },
    CO: { isBix: true, isNotBix: true },
    IL: { isBix: true, isNotBix: false },
    IN: { isBix: true, isNotBix: false },
    KS: { isBix: true, isNotBix: true },
    MD: { isBix: true, isNotBix: true },
    MI: { isBix: true, isNotBix: true },
    MO: { isBix: true, isNotBix: false },
    NE: { isBix: true, isNotBix: true },
    OH: { isBix: true, isNotBix: false },
    OK: { isBix: true, isNotBix: false },
    PA: { isBix: true, isNotBix: true },
    TX: { isBix: true, isNotBix: false },
    UT: { isBix: true, isNotBix: true },
    VA: { isBix: true, isNotBix: true },
    WI: { isBix: true, isNotBix: true }
};
exports.discountInventoryScoreStates = {
    AL: { isBix: true, isNotBix: true },
    AZ: { isBix: true, isNotBix: false },
    CO: { isBix: true, isNotBix: true },
    IL: { isBix: true, isNotBix: false },
    IN: { isBix: true, isNotBix: false },
    KS: { isBix: true, isNotBix: true },
    MD: { isBix: true, isNotBix: true },
    MI: { isBix: true, isNotBix: true },
    MO: { isBix: true, isNotBix: false },
    NE: { isBix: true, isNotBix: true },
    OH: { isBix: true, isNotBix: false },
    OK: { isBix: true, isNotBix: false },
    PA: { isBix: true, isNotBix: true },
    TX: { isBix: true, isNotBix: false },
    UT: { isBix: true, isNotBix: true },
    VA: { isBix: true, isNotBix: true },
    WI: { isBix: true, isNotBix: true }
};
exports.discountPaperlessStates = {
    AL: { isBix: true, isNotBix: true },
    AZ: { isBix: true, isNotBix: false },
    CO: { isBix: true, isNotBix: true },
    IL: { isBix: true, isNotBix: false },
    IN: { isBix: true, isNotBix: false },
    KS: { isBix: true, isNotBix: true },
    MD: { isBix: true, isNotBix: true },
    MI: { isBix: true, isNotBix: true },
    MO: { isBix: true, isNotBix: false },
    NE: { isBix: true, isNotBix: true },
    OH: { isBix: true, isNotBix: false },
    OK: { isBix: true, isNotBix: false },
    PA: { isBix: true, isNotBix: true },
    TX: { isBix: true, isNotBix: false },
    UT: { isBix: true, isNotBix: true },
    VA: { isBix: true, isNotBix: true },
    WI: { isBix: true, isNotBix: true }
};
exports.pipStates = {
    KS: true,
    MD: true,
    TX: true,
    UT: true
};
exports.policyLevelAndCopyToCarUMPDStates = ['IN', 'MD', 'VA'];
exports.umpdDeductibleByState = {
    MD: 250,
    UT: 250
};
exports.communityDriveAvailableStates = {
    AL: {
        showForBix: true,
        showForNonBix: true
    },
    AZ: {
        showForBix: true,
        showForNonBix: false
    },
    CO: {
        showForBix: true,
        showForNonBix: true
    },
    IL: {
        showForBix: true,
        showForNonBix: false
    },
    IN: {
        showForBix: true,
        showForNonBix: false
    },
    KS: {
        showForBix: true,
        showForNonBix: true
    },
    MD: {
        showForBix: true,
        showForNonBix: true
    },
    MI: {
        showForBix: true,
        showForNonBix: true
    },
    MO: {
        showForBix: true,
        showForNonBix: false
    },
    NE: {
        showForBix: true,
        showForNonBix: true
    },
    OH: {
        showForBix: true,
        showForNonBix: false
    },
    OK: {
        showForBix: true,
        showForNonBix: false
    },
    PA: {
        showForBix: true,
        showForNonBix: true
    },
    TX: {
        showForBix: true,
        showForNonBix: false
    },
    UT: {
        showForBix: true,
        showForNonBix: true
    },
    VA: {
        showForBix: true,
        showForNonBix: true
    },
    WI: {
        showForBix: true,
        showForNonBix: true
    }
};
exports.errorCodes = {
    CHAT: 4000,
    EMAIL: 4001,
    POWER: 4002,
    NOTLIVE: 4003,
    NOT_ENOUGH_INFO: 4004,
    INVALIDINPUT: 4005,
    NEEDPRIORADDRESS: 5001,
    NEEDDATEOFBIRTH: 5002,
    NEEDCV: 5003,
    NEED360: 5004,
    NEEDCAR: 5005,
    INVALIDCAR: 5006,
    INVALIDQUOTE: 5007,
    INVALIDSTATE: 5008,
    WONTWRITECAR: 5009,
    INVALIDVIN: 5010,
    MORATORIUM: 5011,
    NEEDDRIVERSLICENSE: 5012,
    NOPOBOXADDRESS: 5013,
    MIN_PREMIUM: 5014,
    PRIMARYUDRNODRIVERSLICENSE: 5015,
    NEEDPERSONINFO: 5016,
    RESTRICTEDSTATE: 5017,
    UNKNOWN: 0
};
exports.rejectCodes = {
    // all codes from 10000 - 49999 are FCRA
    VERISK_GENERIC: 10000,
    VERISK_PROPERTY_CLAIMS_TOTAL: 10009,
    VERISK_PROPERTY_CLAIMS_SAME_PERIL: 10010,
    VERISK_PROPERTY_CLAIMS_OLDER_HOME: 10011,
    VERISK_PROPERTY_CLAIMS_BITE: 10012,
    VERISK_AUTO_CLAIMS_SUS: 10013,
    VERISK_AUTO_CLAIMS_DIS: 10014,
    VERISK_AUTO_CLAIMS_POLICY: 10015,
    VERISK_AUTO_CLAIMS_DRIVER: 10016,
    TU_DRIVERRISK_GENERIC: 20000,
    TU_DRIVERRISK_SUS: 20001,
    TU_DRIVERRISK_DIS: 20002,
    TU_DRIVERRISK_POLICY: 20003,
    TU_DRIVERRISK_DRIVER: 20004,
    // Non-FCRA rejections:
    NON_FCRA_GENERIC: 50000,
    NO_CARS: 50001,
    SQFT_HIGH: 50002,
    OCCUPANTS_HIGH: 50003,
    CAR_RATIO: 50004,
    YEARBUILT_OLD: 50005,
    VERISK_NO360: 50006,
    NO_PRIOR_AUTO_INSURANCE: 50007,
    VERISK_360_EIFS: 50008,
    VERISK_360_STILTS: 50009,
    VERISK_360_WOOD: 50010,
    VERISK_360_RCE_LOW: 50011,
    VERISK_360_RCE_HIGH: 50012,
    VERISK_PPC_10: 50013,
    VERISK_NO_PPC: 50014,
    TOO_MANY_DRIVERS: 50015,
    TOO_MANY_CARS: 50016,
    TEST_CASE: 50017,
    INELIGIBLE_COASTAL_LOCATION: 50018,
    VERISK_NO_FIRELINE: 50019,
    VERISK_FIRELINE_TOO_HIGH: 50020,
    VERISK_FIRELINE_PLUS_FPC_TOO_HIGH: 50021,
    NO_DTC: 50022,
    INELIGIBLE_DTC: 50023
};
exports.fixableRejectionCodes = [50001, 50004, 50006, 50014, 50015, 500016, 50022];
exports.coverageFOffered = {
    AL: true,
    AZ: false,
    CO: true,
    IL: true,
    IN: true,
    KS: true,
    MD: true,
    MI: true,
    MO: true,
    NE: true,
    OH: true,
    OK: true,
    PA: true,
    TX: true,
    UT: true,
    VA: true,
    WI: true
};
exports.offerExcessUM = {
    AL: true,
    AZ: true,
    CO: false,
    IL: false,
    IN: true,
    KS: false,
    MD: true,
    MI: false,
    MO: false,
    NE: true,
    OH: true,
    OK: true,
    PA: false,
    TX: false,
    UT: false,
    VA: true,
    WI: true
};
exports.mineSubsidenceDefaulted = {
    IL: {
        BOND: true,
        BUREAU: true,
        CHRISTIAN: true,
        CLINTON: true,
        DOUGLAS: true,
        FRANKLIN: true,
        FULTON: true,
        GALLATIN: true,
        GRUNDY: true,
        ISLAND: true,
        JACKSON: true,
        JEFFERSON: true,
        KNOX: true,
        LASALLE: true,
        LOGAN: true,
        MACOUPIN: true,
        MADISON: true,
        MARION: true,
        MARSHALL: true,
        MCDONOUGH: true,
        MENARD: true,
        MERCER: true,
        MONTGOMERY: true,
        PEORIA: true,
        PERRY: true,
        PUTNAM: true,
        RANDOLPH: true,
        ROCK: true,
        'ROCK ISLAND': true,
        'SAINT CLAIR': true,
        SALINE: true,
        SANGAMON: true,
        'ST CLAIR': true,
        'ST. CLAIR': true,
        TAZEWELL: true,
        VERMILLION: true,
        WASHINGTON: true,
        WILLIAMSON: true
    }
};
exports.mineSubsidenceOptional = {
    IL: {
        all: true
    },
    IN: {
        CLAY: true,
        CRAWFORD: true,
        DAVIES: true,
        DAVIESS: true,
        DUBOIS: true,
        FOUNTAIN: true,
        GIBSON: true,
        GREENE: true,
        KNOX: true,
        LAWRENCE: true,
        MARTIN: true,
        MONROE: true,
        MONTGOMERY: true,
        ORANGE: true,
        OWEN: true,
        PARKE: true,
        PERRY: true,
        PIKE: true,
        POSEY: true,
        PUTNAM: true,
        SPENCER: true,
        SULLIVAN: true,
        VANDERBURG: true,
        VANDERBURGH: true,
        VERMILION: true,
        VERMILLION: true,
        VIGO: true,
        WARREN: true,
        WARRICK: true
    },
    OH: {
        DELAWARE: true,
        ERIE: true,
        GEAUGA: true,
        LAKE: true,
        LICKING: true,
        MEDINA: true,
        OTTAWA: true,
        PORTAGE: true,
        PREBLE: true,
        SUMMIT: true,
        WAYNE: true
    }
};
exports.mineSubsidenceRequired = {
    IN: {
        // stub placeholder to make this clear
        NONE: true
    },
    OH: {
        ATHENS: true,
        BELMONT: true,
        CARROLL: true,
        COLUMBIANA: true,
        COSHOCTON: true,
        GALLIA: true,
        GUERNSEY: true,
        HARRISON: true,
        HOCKING: true,
        HOLMES: true,
        JACKSON: true,
        JEFFERSON: true,
        LAWRENCE: true,
        MAHONING: true,
        MEIGS: true,
        MONROE: true,
        MORGAN: true,
        MUSKINGUM: true,
        NOBLE: true,
        PERRY: true,
        SCIOTO: true,
        STARK: true,
        TRUMBULL: true,
        TUSCARAWAS: true,
        VINTON: true,
        WASHINGTON: true
    }
};
exports.ineligibleCoastalCities = {
    TX: {
        ARANSAS: { all: true },
        BRAZORIA: { all: true },
        CALHOUN: { all: true },
        CAMERON: { all: true },
        CHAMBERS: { all: true },
        GALVESTON: { all: true },
        HARRIS: {
            'LA PORTE': true,
            LAPORTE: true,
            "MORGAN'S POINT": true,
            'MORGANS POINT': true,
            MORGANSPOINT: true,
            PASADENA: true,
            SEABROOK: true,
            SHOREACRES: true
        },
        JEFFERSON: { all: true },
        KENEDY: { all: true },
        KLEBERG: { all: true },
        MATAGORDA: { all: true },
        NUECES: { all: true },
        REFUGIO: { all: true },
        'SAN PATRICIO': { all: true },
        WILLACY: { all: true }
    }
};
exports.coastalCounties = {
    TX: {
        HARDIN: true,
        JEFFERSON: true,
        ORANGE: true,
        AUSTIN: true,
        BRAZORIA: true,
        CHAMBERS: true,
        COLORADO: true,
        'FORT BEND': true,
        GALVESTON: true,
        HARRIS: true,
        LIBERTY: true,
        MATAGORDA: true,
        MONTGOMERY: true,
        WALKER: true,
        WALLER: true,
        WHARTON: true,
        CALHOUN: true,
        DEWITT: true,
        GOLIAD: true,
        GONZALES: true,
        JACKSON: true,
        LAVACA: true,
        VICTORIA: true,
        ARANSAS: true,
        BEE: true,
        BROOKS: true,
        DUVAL: true,
        'JIM WELLS': true,
        KENEDY: true,
        KLEBURG: true,
        KLEBERG: true,
        'LIVE OAK': true,
        NUECES: true,
        REFUGIO: true,
        'SAN PATRICIO': true,
        CAMERON: true,
        HIDALGO: true,
        WILLACY: true
    }
};
exports.mandatoryWindHailDeductibleValueMinimumStates = {
    MD: 2500
};
exports.mandatoryWindHailDeductibleByZip = {};
exports.mandatoryWindHailDeductibleByCounty = {
    MD: {
        ALLEGANY: 2500,
        GARRETT: 2500,
        WASHINGTON: 2500,
        FREDERICK: 2500,
        CARROLL: 2500,
        HOWARD: 2500,
        MONTGOMERY: 2500,
        CHARLES: 0.01,
        'PRINCE GEORGES': 0.01,
        "PRINCE GEORGE'S": 0.01,
        'ANNE ARUNDEL': 0.01,
        BALTIMORE: 0.01,
        'BALTIMORE COUNTY': 0.01,
        'BALTIMORE CITY': 0.01,
        HARFORD: 0.01,
        CECIL: 0.01,
        KENT: 0.01,
        'QUEEN ANNES': 0.01,
        "QUEEN ANNE'S": 0.01,
        CAROLINE: 0.01,
        CALVERT: 0.02,
        'ST MARYS': 0.02,
        'ST. MARYS': 0.02,
        'SAINT MARYS': 0.02,
        "ST. MARY'S": 0.02,
        "ST MARY'S": 0.02,
        "SAINT MARY'S": 0.02,
        TALBOT: 0.02,
        DORCHESTER: 0.02,
        WICOMICO: 0.02,
        SOMERSET: 0.02,
        WORCESTER: 0.02
    }
};
exports.rentersPolicyAvailable = {
    AL: true,
    AZ: false,
    CO: false,
    IL: true,
    IN: true,
    KS: true,
    MD: false,
    MI: false,
    MO: true,
    NE: true,
    OH: true,
    OK: true,
    PA: false,
    TX: false,
    UT: true,
    VA: true,
    WI: true
};
exports.minimumDrivingAge = {
    AL: 15,
    AK: 14,
    AZ: 15,
    AR: 14,
    CA: 15,
    CO: 15,
    CT: 16,
    DE: 16,
    DC: 16,
    FL: 15,
    GA: 15,
    HI: 14,
    ID: 14,
    IL: 15,
    IN: 15,
    IA: 14,
    KS: 14,
    KY: 16,
    LA: 15,
    ME: 15,
    MD: 15,
    MA: 16,
    MI: 14,
    MN: 15,
    MS: 15,
    MO: 15,
    MT: 14,
    NE: 15,
    NV: 15,
    NH: 15,
    NJ: 16,
    NM: 16,
    NY: 16,
    NC: 15,
    ND: 14,
    OH: 15,
    OK: 15,
    OR: 15,
    PA: 16,
    RI: 16,
    SC: 15,
    SD: 14,
    TN: 15,
    TX: 15,
    UT: 15,
    VT: 15,
    VA: 15,
    WA: 15,
    WV: 15,
    WI: 15,
    WY: 15
};
exports.vehicleEquipmentStatusCode = {
    OPTIONAL: 'O',
    STANDARD: 'S',
    NO: 'N'
};
exports.HomeSecurityPartnerCustomerType = {
    CURRENT: 'C',
    NEW_BRANCH: 'B'
};
exports.mortgageHoldersAffinities = [
    {
        name: 'QUICKEN LOANS INC ISAOA',
        affinity: 'QL1'
    }
];
exports.mortgageClauses = {
    RM1: {
        correctName: 'T2 FINANCIAL LLC DBA/ REVOLUTION MORTGAGE ISAOA/ATIMA',
        address: '480 Olde Worthington Road',
        address2: 'Ste 300',
        city: 'Westerville',
        state: 'OH',
        zip: '43082'
    }
};
exports.newCustomersDefaultDevices = {
    ADT: {
        moistureDevices: 2,
        theftPreventionDevices: 2,
        motionDetectingDevices: 1,
        smokeDetectingDevices: 2,
        autoWaterShutoffDevices: false
    },
    SimpliSafe: {
        moistureDevices: 1,
        theftPreventionDevices: 1,
        motionDetectingDevices: 1,
        smokeDetectingDevices: 1,
        autoWaterShutoffDevices: false
    }
};
exports.connectedHomePromoDetails = {
    SimpliSafe: {
        promoItems: [
            '7-piece security system guards against break-ins and fires--plus includes 24/7 video monitoring. A $403 value, yours FREE.',
            'Monitored by professionals 24/7 for only $14.99/month - with NO long-term contract. Your first month is FREE.',
            'Ranked the best overall home security system of 2021 by U.S. News and World Report'
        ],
        privacyPolicyUrl: 'https://simplisafe.com/privacy'
    },
    ADT: {
        promoItems: [
            'Get ADTs best offer PLUS receive 2 flood detectors FREE (Requires 36 month monitoring contract. Early termination and installation fees apply.)',
            'ADT is the # 1 smart home security provider in the U.S. (Source: Strategy Analytics, 2020)',
            '$0 Down*** 0% Interest 100% Real Protection. Now you can get the security you need with the smart home devices you want with $0 down and flexible, easy, low monthly payments on professionally installed equipment. (***Requires 0% APR installment agreement with equal monthly payments based on term/equipment selected. Well-qualified credit. Requires monthly monitoring contract for finance term selected. Early term fees apply.)'
        ],
        privacyPolicyUrl: 'https://www.adt.com/about-adt/legal/privacy-policy'
    },
    Kangaroo: {
        privacyPolicyUrl: 'https://info.heykangaroo.com/legal#legal-privacy-policy'
    }
};
exports.HighestEducation = {
    H: 'High School or less',
    S: 'Some College',
    A: 'Associates Degree',
    B: 'Bachelors Degree',
    M: 'Masters Degree',
    D: 'Doctoral Degree'
};
exports.ratingSeriousness = {
    AAF: 4,
    AFM: 0,
    ANC: 0,
    ANO: 0,
    ASW: 0,
    BOT: 1,
    CCW: 0,
    CML: 2,
    CMP: 0,
    CMU: 0,
    CRD: 4,
    DEV: 2,
    DEQ: 2,
    DR: 8,
    DWI: 8,
    FAR: 2,
    FDL: 2,
    FEL: 8,
    FFR: 8,
    FLE: 8,
    FRA: 2,
    FTC: 2,
    FTY: 2,
    HOM: 8,
    IBK: 2,
    IP: 2,
    IT: 2,
    LDL: 4,
    LIC: 2,
    LTS: 4,
    MAJ: 4,
    MMV: 2,
    NAF: 0,
    NFX: 0,
    PUA: 0,
    PUN: 0,
    REF: 1,
    RKD: 8,
    SAF: 2,
    SCH: 2,
    SLV: 4,
    SPD: 2,
    SUS: 10,
    TMP: 0,
    UDR: 4,
    WSR: 2
};
exports.monarchPoints = {
    AAF: 3,
    CMP: 2,
    NAF: 1
};
exports.homeClaimPoints = {
    D: 4,
    G: 3,
    E: 2,
    F: 1
};
exports.connectedHomeProvidersAllowingVerification = ['ADT'];
exports.violationCodeToDescription = {
    AAF: 'Accident found on MVR only at renewal - Not Chargeable',
    ANC: 'Waived Claim - Closed',
    ANO: 'Waived Claim - Open',
    ASW: 'Accident Surcharge Waived',
    BOT: 'Comprehensive Claim',
    CCW: 'Comprehensive Claim - Weather',
    CMU: 'Comprehensive Claim Less Than or Equal to $1,000',
    CRD: 'Careless or Improper Driving',
    DEQ: 'Defective Equipment',
    DEV: 'Traffic Device /Sign',
    DR: 'Driving Under The Influence of Alcohol/Drugs',
    FAR: 'False Reporting or Perjury',
    FDL: `Foreign Driver's License`,
    FEL: 'Auto Theft or Felony Involving a Motor Vehicle',
    FLE: 'Fleeing or Eluding the Police',
    FRA: 'Failure to Report an Accident',
    FTC: 'Following Too Close',
    FTY: 'Improper or Illegal Passing',
    IT: `Operating Without Owner's Consent`,
    LIC: 'License or Credentials Violation',
    LTS: 'Minor Moving Violation',
    NAF: 'Not At Fault Accident',
    NFX: 'Waived Not At Fault Accident',
    PUA: 'Permissive Use At Fault Accident',
    PUN: 'Permissive Use Not At Fault Accident',
    REF: 'Passing of a School Bus',
    SLV: 'Serious License Violation',
    SPD: 'Speeding',
    TMP: 'Dispute – At Fault Accident',
    UDR: 'Unverifiable Record',
    WSR: 'Wrong Way on a One Way Street'
};
exports.homeClaimCodeToDescription = {
    D: 'Fire',
    E: 'Theft, Liability, Vandlism',
    F: 'All Other Claims (not Fire, Water, Theft, Liability or Vandalism)',
    G: 'Water'
};
exports.fpcClassesImpactingPrice = ['7', '8', '8B', '9'];
exports.financialResponsibilityTiersImpactingPrice = ['L-1', 'M-1', 'N-1', 'O-1', 'P-1', 'Q-1', 'R-1'];
exports.priorLapseDays = { TX: 32 };
exports.policyType = { Home: 'H', Auto: 'A', ARBundle: 'AR', HABundle: 'HA' };
exports.planType = { Standard: 'S', Economy: 'E', Premium: 'P', Custom: 'C' };
exports.paymentMethod = { CreditCard: 'C', ACH: 'E', Escrow: 'W' };
exports.paymentType = { Monthly: 'I', OneTime: 'F', Escrow: 'E' };
exports.stripeBankStatus = {
    New: 'new',
    Validated: 'validated',
    Verified: 'verified',
    VerificationFailed: 'verification_failed',
    Errored: 'errored'
};
exports.cancelNoticeParagraphText = [
    {
        cancelNoticeName: 'CNRS',
        paragraphText: "We'll be happy to reinstate your policy if we receive the information requested above prior to cancellation. Just give us a call or, if you prefer, fax or mail the requested information along with a copy of this page to Branch. But don't delay. We won't be able to reinstate your policy if you contact us after the deadline."
    },
    {
        cancelNoticeName: 'CNPY',
        paragraphText: "Unfortunately, we didn't receive your payment and, as a result, your policy will be cancelled at 12:01 a.m. on [endDate]. Please know that this means you will no longer have insurance coverage. If you've already sent your payment, thank you. Your next regular payment will be due on [minimumAmountDueDate]. You can also pay online or over the phone using a credit card or authorizing a withdrawal from your bank account. We'll credit your payment right away so your insurance coverage will continue. We sincerely appreciate your attention to this matter and thank you for your business."
    }
];
exports.cancelCodeInformationalArray = [
    {
        cancelCode: 'CNDC',
        cancelReasonPlainText: 'Customer Dissatisfied with Claims',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Dissatisfied with Claims'
    },
    {
        cancelCode: 'CNPR',
        cancelReasonPlainText: 'Customer Dissatisfied with Price',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Dissatisfied with Price'
    },
    {
        cancelCode: 'CNDR',
        cancelReasonPlainText: 'Customer Dissatisfied with Roadside',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Dissatisfied with Roadside'
    },
    {
        cancelCode: 'CNDB',
        cancelReasonPlainText: 'Customer Dissatisfied with Service',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Dissatisfied with Service'
    },
    {
        cancelCode: 'CNDA',
        cancelReasonPlainText: 'Customer Dissatisfied with Agency',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Dissatisfied with Agency'
    },
    {
        cancelCode: 'CNMS',
        cancelReasonPlainText: 'Customer Misrepresentation',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Misrepresentation'
    },
    {
        cancelCode: 'CNPY',
        cancelReasonPlainText: 'Customer Non-Payment',
        cancelNoticeName: 'CNPY',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Non-Payment'
    },
    {
        cancelCode: 'CNID',
        cancelReasonPlainText: 'Documents Not Signed',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Documents Not Signed'
    },
    {
        cancelCode: 'CNCN',
        cancelReasonPlainText: 'Ineligible Condition of Home',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Ineligible Condition of Home'
    },
    {
        cancelCode: 'CNMV',
        cancelReasonPlainText: 'Moving, Branch Eligible',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Moving'
    },
    {
        cancelCode: 'CNMI',
        cancelReasonPlainText: 'Moving, Branch Ineligible',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Moving, Branch Ineligible'
    },
    {
        cancelCode: 'CNNO',
        cancelReasonPlainText: 'Other Customer Cancellation',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Insured Request - Other'
    },
    {
        cancelCode: 'CNUW',
        cancelReasonPlainText: 'Other Underwriting',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Underwriting Reasons'
    },
    {
        cancelCode: 'CNSO',
        cancelReasonPlainText: "Sold the Insured Property/Didn't Purchase",
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Sold the Insured Property'
    },
    {
        cancelCode: 'CNDP',
        cancelReasonPlainText: 'Duplicate Policy',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Duplicate Policy'
    },
    {
        cancelCode: 'CNPP',
        cancelReasonPlainText: 'No Proof of Prior',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: No Proof of Prior Insurance'
    },
    {
        cancelCode: 'CNAP',
        cancelReasonPlainText: 'Accidental Purchase',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Accidental Purchase'
    },
    {
        cancelCode: 'CNBX',
        cancelReasonPlainText: 'Customer Convert to Bix',
        // no cancellation docs generated for CNBX
        cancelNoticeName: '',
        boxOneText: '',
        boxTwoText: ''
    },
    {
        cancelCode: 'CNNR',
        cancelReasonPlainText: 'Non-renewal',
        cancelNoticeName: 'NRRS',
        boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Non-renewal: [fill in]'
    },
    {
        cancelCode: 'CNDE',
        cancelReasonPlainText: 'Customer Deceased',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Deceased'
    },
    {
        cancelCode: 'NA',
        cancelReasonPlainText: 'Unknown',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: [Fill In]'
    },
    {
        cancelCode: 'CNAG',
        cancelReasonPlainText: 'Agency Book Roll',
        cancelNoticeName: 'NRRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Previous Agency Policy'
    },
    {
        cancelCode: 'CNBN',
        cancelReasonPlainText: 'BIX Forced Non-Renewal',
        cancelNoticeName: 'NRRS',
        boxOneText: 'Your policy will be non-renewed as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason For Nonrenewal: General Security National Insurance Company is no longer offering policies in your state. '
    },
    {
        cancelCode: 'CNDY',
        cancelReasonPlainText: 'Bix Opt out',
        cancelNoticeName: 'CNRS',
        boxOneText: 'Your policy will be cancelled as of 12:01 A.M on [end date] ',
        boxTwoText: 'Reason(s) for Cancellation: Opt Out of BIX Policy '
    }
];
exports.passiveRestraintStates = ['PA'];
exports.specificDriverAgeLogicStates = ['AL'];
exports.stormShuttersStates = ['AL', 'MD', 'MO', 'OK', 'TX'];
exports.annualMilesDrivenLimits = {
    NE: {
        max: 30000,
        step: 1000
    }
};
exports.preBindMVRStates = ['CO'];
