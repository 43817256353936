import React, { Suspense, useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import { Dialog, Grid } from '@material-ui/core';

import DialogContent from '@material-ui/core/DialogContent';

import { SearchBar } from 'core/components/search-bar';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { ActionButton } from 'core/components/action-button';
import { useStore } from 'core/store';
import x from 'core/assets/svg/x.svg';
import OffersResults from 'core/components/search-results/offers-results';
import useStyles from './search-dialog.styles';

const OfferSearchDialog = observer(() => {
  const classes = useStyles();
  const {
    matchSearch: {
      showOfferMatchesModal,
      setShowOfferMatches,
      offersResults,
      clearInput,
      searchOffersAction,
      currentResultsPage,
      setCurrentResultsPage,
      loading,
      searchString,
      setSearchString
    },
    offer
  } = useStore();

  const { values } = useFormikContext();

  useEffect(() => {
    searchOffersAction();
  }, [searchString, searchOffersAction]);

  const onSearchStringChange = useCallback(
    (event) => {
      setSearchString(event.target.value);
    },
    [setSearchString]
  );

  const onChangePage = useCallback(
    (page) => {
      setCurrentResultsPage(page);
      searchOffersAction(searchString);
    },
    [searchString, setCurrentResultsPage, searchOffersAction]
  );
  const switchOffer = useCallback(() => {
    window.location.replace(`/offer/${values.selectedOffer}`);
  }, [values]);

  return (
    <Dialog maxWidth="lg" fullWidth open={showOfferMatchesModal} onClose={() => setShowOfferMatches(false)}>
      <DialogContent className={classes.searchDialog}>
        <Suspense fallback={null}>
          <Grid container>
            <Grid item xs={12} className={classes.searchHeader}>
              <Label type="greenBig">{offersResults.nbHits} Existing offers</Label>
              <Label type="infoValue" className={classes.searchSubtitle}>
                Select a match to link to offer or search more
              </Label>
            </Grid>
            <Grid item xs={7} className={classes.searchBar}>
              <SearchBar
                onCloseIconClick={clearInput}
                onInputChange={onSearchStringChange}
                value={searchString}
                label="Search"
              />
            </Grid>
          </Grid>
          <OffersResults
            loading={loading}
            hits={offersResults.hits}
            totalHits={offersResults.nbHits}
            page={currentResultsPage}
            onChangePage={onChangePage}
            showHeader={false}
            isDialogSearch
          />
          <Grid container alignItems="center" justify="flex-end" className={classes.searchFooter}>
            <Grid item className={classes.buttonContainer}>
              <Button
                className={classes.linkButton}
                type="submit"
                disabled={loading || offer.loading || !values.selectedOffer}
                loading={loading || offer.loading}
                variant="contained"
                color="primary"
                onClick={switchOffer}
              >
                <Label>Switch offer</Label>
              </Button>
            </Grid>
          </Grid>
        </Suspense>
        <ActionButton
          size="big"
          type="edit"
          icon={x}
          className={classes.closeButton}
          onClick={() => {
            setShowOfferMatches(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
});

export default OfferSearchDialog;
