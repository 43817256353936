import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { NotificationCard } from 'core/components/notification-card';
import useStyles from './short-policy-notification.styles';

const ShortPolicyNotification = ({ text, onClick, buttonText }) => {
  const classes = useStyles();

  return (
    <NotificationCard>
      <Grid container alignItems="center" justify="space-between">
        <Label className={classes.styledText}>{text}</Label>
        <Button className={classes.button} onClick={onClick}>
          {buttonText}
        </Button>
      </Grid>
    </NotificationCard>
  );
};

ShortPolicyNotification.propTypes = {
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ShortPolicyNotification;
