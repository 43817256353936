import gql from 'graphql-tag';
import * as fragments from 'customer/components/policy/policy.query.fragments';
import { OFFER_FRAGMENT } from 'core/fragments/offer';

export const GET_ACCOUNT = gql`
  query ($id: ID!) {
    account: getAccount(id: $id) {
      id
      fname
      lname
      created
      mailingAddress {
        address
        address2
        city
        state
        zip
      }
      phoneNumber
      email
      inviter {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_FULL_ACCOUNT = gql`
  query ($id: ID!) {
    account: getAccount(id: $id) {
      id
      fname
      lname
      created
      mailingAddress {
        address
        address2
        city
        state
        zip
      }
      phoneNumber
      email
      additionalPhoneNumbers {
        phoneNumber
        note
        canText
      }
      inviter {
        id
        firstName
        lastName
      }
      policies {
        id
        policyType
        state
        effectiveDate
        endDate
        premium
        surplusContribution
        fromStaff
        stripeCustomerId
        paymentMethod
        salesRep
        offer {
          options {
            id
            name
            annual
            monthly
            type
            homeBill
            homeTotal
            autoBill
            autoTotal
            homePremium
            autoPremium
          }
          quote {
            fname
            lname
            email
            phone
            correctedAddress {
              address
              address2
              city
              state
              zip
            }
            global {
              affinity
            }
            leadSource
            rep
            offerings {
              autoRejectCode
              monolineAutoRejectCode
              homeownersRejectCode
            }
          }
        }
        versionHistory {
          updatedDateTime
        }
        segments {
          global {
            affinity
          }
        }
        policyDetails {
          autoCoverage {
            policyLimitBIPD
            policyLimitUMBI
            policyLimitUIMBI
            policyLimitPIP
            policyLimitUMPD
            policyLimitMedicalPayments
            policyLimitLPD
            petInjury
            policyLimitPIPME
            policyLimitPIPWL
            policyLimitPIPACR
            policyLimitPPI
            policyLimitIncomeLoss
            policyLimitAccidentalDeath
            policyLimitFuneralBenefits
            policyLimitExtraMedBenefits
            policyLimitTortOption
            policyLimitComboFBP
          }
          homeCoverage {
            coverageA
            minimumDeductibleValue
            minimumWindHailDeductibleValue
            interestedInSeparateWindHail
            externalPolicyId
          }
          includeUmbrella
          drivers {
            id
            excludeDriver
            waivedPIPWL
            pipEligible
            pipOptOut
            pipHasQHC
          }
        }
        fees {
          amount
        }
      }
    }
    documents: getDocs(accountId: $id) {
      regular {
        path
        lastModified
      }
      internal {
        path
        lastModified
      }
    }
    unsignedApplications: getUnsignedApplications(accountId: $id) {
      policyId
      applicationUrl
    }
    unsignedBixConversions: getUnsignedBixConversions(accountId: $id) {
      policyId
    }
    pledges: getAccountPledges(accountId: $id) {
      pledges {
        name
        pledgedTimeStamp
      }
    }
    myCommunity: getAccountCommunity(accountId: $id) {
      community {
        name
        communityTimeStamp
      }
    }
  }
`;

export const GET_ACCOUNT_POLICY = gql`
  query ($id: ID!, $accountId: ID!) {
    account: getAccount(id: $accountId) {
      id
      fname
      lname
      created
      mailingAddress {
        address
        address2
        city
        state
        zip
      }
      phoneNumber
      email
      inviter {
        id
        firstName
        lastName
      }
      policies {
        id
        offerId
        policyType
        paymentType
        isBix
        salesRep
        paymentMethod
        premiumBreakdown
        policyDetails {
          autoCoverage {
            policyLimitBIPD
            policyLimitUMBI
            policyLimitUIMBI
            policyLimitPIP
            policyLimitUMPD
            policyLimitMedicalPayments
            policyLimitLPD
            petInjury
            policyLimitPIPME
            policyLimitPIPWL
            policyLimitPIPACR
            policyLimitPPI
            policyLimitIncomeLoss
            policyLimitAccidentalDeath
            policyLimitFuneralBenefits
            policyLimitExtraMedBenefits
            policyLimitTortOption
            policyLimitComboFBP
          }
          homeCoverage {
            interestedInSeparateWindHail
            externalPolicyId
          }
          drivers {
            ...People
          }
          nonDrivers {
            ...People
          }
          people {
            ...People
          }
          cars {
            addDate
            VIN
            year
            make
            model
            bodyType
            primaryUse
            purchaseDate
            garageLocation {
              ...Address
            }
            deductibleCollision
            deductibleComprehensive
            limitUMPD
            limitRental
            limitACPE
            roadsideAssistance
            rideSharing
            confirmed
            aebStatus
            aebConfirmed
            antiTheftStatus
            antiTheftConfirmed
            singleAirbagStatus
            singleAirbagConfirmed
            doubleAirbagStatus
            doubleAirbagConfirmed
            luxury
            coverageLoan
            symbolMake
            symbolModel
            symbolStyle
            symbolAux
            annualMilesDriven
          }
          trailers {
            VIN
            type
            year
            value
            contents
            purchaseDate
            garageLocation {
              ...Address
            }
            deductibleCollision
            deductibleComprehensive
            confirmed
            coverageLoan
            addDate
          }
          includeUmbrella
          includeRenters
          attachedHomeowners
          attachedRenters
          attachedAuto
        }
        billingDayOfMonth
        billingHoldUntil
        rateControlDate
        effectiveDate
        endDate
        autoRenew
        sendPaperDocuments
        renewalCreditPull
        renewalPaymentType
        state
        term
        version
        premium
        surplusContribution
        renewalPaymentType
        rewriteReason
        cancelReason
        stripeCustomerId
        surplusContribution
        defaultCreditCard {
          id
          brand
          last4
          bankName
        }
        defaultBankAccount {
          id
          routingNumber
          accountHolder
          accountType
        }
        defaultEscrowAccount {
          mortgageHolderName
          loanNumber
          mortgageHolderAddress {
            address
            address2
            country
            state
            city
            zip
          }
        }
        accountDetails {
          accountHolder
          accountType
          routingNumber
          accountNumber
        }
        bankAccounts {
          accountHolder
          accountType
          routingNumber
          accountNumber
        }
        creditCards {
          id
          brand
          last4
        }
        additionalParties {
          id
          name
          relationship
          lossPayee
          address {
            address
            address2
            unit
            country
            city
            state
            zip
          }
          loanNumber
          VIN
        }
        fees {
          type
          description
          amount
          appliedDate
        }
        fromStaff
        offer {
          ...OfferFragment
        }
        versionHistory {
          version
          username
          updatedDateTime
          coverageChange
          itemsChanged
          effectiveDate
          internalNotes
          changeInPremium
        }
        segments {
          segmentId
          clarionDoorId
          startDate
          dailyRate
          segmentDays
          segmentPremium
          autoCoverage {
            policyLimitBIPD
            policyLimitUMBI
            policyLimitUIMBI
            policyLimitPIP
            policyLimitUMPD
            policyLimitMedicalPayments
            policyLimitLPD
            petInjury
            policyLimitPIPME
            policyLimitPIPWL
            policyLimitPIPACR
            policyLimitPPI
            policyLimitIncomeLoss
            policyLimitAccidentalDeath
            policyLimitFuneralBenefits
            policyLimitExtraMedBenefits
            policyLimitTortOption
            policyLimitComboFBP
          }
          scheduledPersonalProperty {
            deductible
            items {
              category
              description
              value
              breakageOption
              exTheftOption
            }
          }
          premium
          includeUmbrella
          includeRenters
          attachedHomeowners
          attachedRenters
          attachedAuto
          umbrellaCoverage {
            highRisk
            numVehiclesTotal
            numLicensedDriversUnder25
            numMotorcyclesScooters
            numATVs
            numPersonalWatercraft
            numWatercraft
            watercraftHullLengths
            numRVs
            numRentalProperties
            uninsuredMotoristLimit
            rentalPropertyAddresses {
              ...Address
            }
            numOtherProperties
            otherPropertyAddresses {
              ...Address
            }
            presenceOfBusiness
            uninsuredMotoristLimit
            liabilityCoverageLimit
            limitBIOccurrence
          }
          homeCoverage {
            coverageA
            coverageIBSC
            coverageBCPctOfA
            coverageBPctOfA
            coverageCPctOfA
            coverageDPctOfA
            coverageX
            coverageY
            coverageWaterBackup
            coverageBuildingMaterialsTheft
            coverageCourseOfConstruction
            coverageExtendedPremises
            coverageFairRental
            coveragePetBite
            coverageRSExtended
            coverageYardAndGarden
            coverageSinkhole
            coverageOilStorageTank
            coverageBP
            coverageCA
            coverageDC
            coverageDR
            coverageF
            coverageG
            coverageJ
            coverageMI
            coverageMS
            coverageSP
            coverageST
            homeownerProtection
            deductibleAllOther
            deductibleHurricane
            deductibleWindHail
            windHailExclusion
            minimumDeductibleValue
            minimumWindHailDeductibleValue
            interestedInSeparateWindHail
            externalPolicyId
          }
          global {
            affinity
            rateControlDate
            currentlyHomeInsured
            manualInventorySubmission
            discountInventoryScore
            discountDriverScore
            discountPaperless
            homeownersPaymentType
            homeownersPaymentMethod
            autoPaymentType
            autoPaymentMethod
            currentlyAutoInsured
            homeEffectiveDate
            autoEffectiveDate
            homeSecurityPartnerCustomerType
          }
          rentersCoverage {
            rentersLocation {
              ...Address
            }
            coverageCD
            coverageX
            coverageY
            deductible
          }
          cars {
            addDate
            VIN
            year
            make
            model
            bodyType
            primaryUse
            purchaseDate
            garageLocation {
              ...Address
            }
            deductibleCollision
            deductibleComprehensive
            limitUMPD
            limitRental
            limitACPE
            roadsideAssistance
            rideSharing
            confirmed
            aebStatus
            aebConfirmed
            antiTheftStatus
            antiTheftConfirmed
            blindSpotStatus
            blindSpotConfirmed
            singleAirbagStatus
            singleAirbagConfirmed
            doubleAirbagStatus
            doubleAirbagConfirmed
            coverageLoan
            symbolMake
            symbolModel
            symbolStyle
            symbolAux
            annualMilesDriven
          }
          drivers {
            ...People
          }
          nonDrivers {
            ...People
          }
          people {
            ...People
          }
          trailers {
            VIN
            type
            year
            value
            contents
            purchaseDate
            garageLocation {
              ...Address
            }
            deductibleCollision
            deductibleComprehensive
            confirmed
            coverageLoan
            addDate
          }
          auto {
            hasSetPip
            pipResidentsWithQHC
            pipExcludedResidents
            pipAllResidents
            pipEveryoneOnSamePlan
            pipHealthInsuranceType
            pipAdditionalResidents
          }
          home {
            basementSqFt
            basementType
            constructionType
            exteriorWallType
            garageCarCapacity
            garageType
            homeLocation {
              ...Address
            }
            homeQuality
            mortgageDetails {
              loanNumber
              mortgageHolderAddress {
                address
                address2
                country
                city
                state
                zip
              }
              mortgageHolderName
              primary
            }
            numFamiliesInDwelling
            numFireplaces
            numFullBathrooms
            numHalfBathrooms
            numOccupants
            numStories
            numWoodBurningStoves
            ownershipStatus
            purchaseDate
            replacementCostEstimate {
              total
              itemized {
                calculatedValue
                type
                desc
              }
            }
            roofOriginal
            roofShape
            roofType
            roofYear
            sqFt
            swimmingPool
            typeOfHome
            yearBuilt
            latitude
            longitude
            county
            fpc
            wildfireHazardScore
            dtoName
            dtbowName
            dtbowLabel
            windstormMitigationLevel
            stormShutters
          }
          connectedHome {
            monitored
            moistureDevices
            theftPreventionDevices
            motionDetectingDevices
            smokeDetectingDevices
            autoWaterShutoffDevices
            providerName
            agreeToTerms
          }
          multiPolicyDiscount
          underwritingChanges
          homeViolationDates
          homeSingleViolationDates
        }
      }
    }
    writeOffTransactions: getWriteOffTransactions(policyId: $id) {
      date
      amount
      paymentMethod
      paymentStatus
      description
    }
    billingDetails: getBillingDetails(accountId: $accountId, policyId: $id) {
      nextPaymentDate
      nextPaymentAmount
      remainingPayments
      activePaymentMethod {
        id
        brand
        last4
        bankName
      }
      allPaymentMethods {
        id
        brand
        last4
        bankName
      }
      transactions {
        id
        paymentAmount
        paymentAmountFormatted
        paymentRefunded
        paymentRefundedFormatted
        paymentDate
        paymentMethod
        paymentMethodBrand
        paymentMethodLast4
        paymentStatus
        internalDescription
      }
    }
    holdcards: getHoldCards(policyId: $id) {
      holdCardId
      createdBy
      createdDate
      reason
      deleted
      policyId
    }
    documents: getDocs(accountId: $accountId) {
      regular {
        path
        lastModified
      }
      internal {
        path
        lastModified
      }
    }
    inspection: getInspectionStatus(policyId: $id) {
      status
      inspectionId
      caseNumber
      dateOrdered
      dateCompleted
      completedType
    }
    unsignedBixConversions: getUnsignedBixConversions(accountId: $accountId) {
      policyId
    }
  }
  ${fragments.Address}
  ${fragments.People}
  ${OFFER_FRAGMENT}
`;

export const GET_TICKETS = gql`
  query ($params: GetTicketsParams!) {
    tickets: getTickets(params: $params) {
      zendeskTickets {
        id
        date
        status
        subject
        description
        url
      }
      sendGridEmails {
        id
        date
        status
        subject
        description
        url
        clicksCount
        opensCount
      }
      five9Data {
        status
        subject
        date
      }
    }
  }
`;

export const GET_TICKET_DETAIL = gql`
  query ($ticketId: ID!) {
    ticketDetail: getZendeskTicketDetails(ticketId: $ticketId) {
      id
      date
      subject
      description
      url
      comments {
        id
        date
        author
        body
      }
    }
  }
`;

export const GET_ACCOUNT_CLAIMS = gql`
  query ($accountId: ID!) {
    claims: getAccountClaims(accountId: $accountId) {
      policyId
      claimNumber
      status
      TPA
      coverage
      cause
      lossDate
      claimType
      adjusterName
      adjusterEmail
      adjusterPhone
    }
  }
`;

export const SEND_EVENT_AS_MEMBER = gql`
  mutation sendEventAsMember($eventDetails: EventDetailsInput!) {
    sendEventAsMember(eventDetails: $eventDetails)
  }
`;
