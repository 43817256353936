import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import flowRight from 'lodash-es/flowRight';
import { withStyles } from '@material-ui/core/styles';
import { lookupsJson, getOptions, getValue } from '@ourbranch/lookups';

import Field from 'core/components/form/form.v2';
import { Form, useFormikContext } from 'formik';
import withDatePicker from 'core/components/with-date-picker';
import { tooltipHoverTexts } from 'core/helpers/constants';

import { LabelTooltip } from 'core/components/label-tooltip';
import { Label } from '../../../../core/components/label';
import CollapsibleCard from '../../../../core/components/collapsible-card';
import RemoveButton from '../../../../core/components/remove-button';
import styles from './trailer.styles';

const getTrailerHeader = (item) => `${item.year || ''} ${getValue('trailerType', item.type) || 'New Trailer'}`;

const Trailer = ({
  fieldName,
  fieldId: id,
  classes,
  item = {},
  index,
  removeFromList,
  onRemove,
  disabled,
  offer,
  policy
}) => {
  const { isBixOfferOrState } = offer;
  const { isBix: policyIsBix } = policy;
  const isBix = policyIsBix || isBixOfferOrState;

  const { setTouched, errors, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (Object.keys(errors).length) {
      setTouched(errors);
    }
  }, [errors, setTouched]);

  const state = item?.garageLocation?.state || null;

  const { deductibleCollision, deductibleComprehensive } = item;

  const leaseLoanAvailable = useMemo(() => {
    // lease loan in all BIX and all GSNIC that isn't our original five
    return (
      deductibleCollision !== 'NONE' &&
      deductibleComprehensive !== 'NONE' &&
      (isBix || !['AZ', 'OH', 'IL', 'MO', 'TX'].includes(state))
    );
  }, [deductibleCollision, deductibleComprehensive, isBix, state]);

  useEffect(() => {
    if (!leaseLoanAvailable) {
      setFieldValue(`${fieldName}.coverageLoan`, false);
    }
  }, [leaseLoanAvailable, fieldName, setFieldValue]);

  return (
    <CollapsibleCard
      content={
        <div className={classes.header}>
          <Label type="infoSubtitle">{getTrailerHeader(item)}</Label>
        </div>
      }
      contentRight={<RemoveButton disabled={disabled} mode="big" onClick={() => onRemove(index, removeFromList)} />}
      initialOpen={item.initialOpen}
    >
      <Form>
        <Grid spacing={4} container justify="space-between" className={classes.cardBody}>
          <Field id={`${fieldName}.VIN`} name={`${fieldName}.VIN`} type="string" label="VIN" mode="light" xs={4} />
          <Field
            id={`${fieldName}.type`}
            name={`${fieldName}.type`}
            type="select"
            label="Type"
            mode="light"
            xs={4}
            options={lookupsJson.trailerType}
          />
          <Field
            id={`${fieldName}.purchaseDate`}
            name={`${fieldName}.purchaseDate`}
            type="date"
            label="Purchase Date"
            mode="light"
            xs={4}
          />
          <Field
            id={`${fieldName}.garageLocation.address`}
            name={`${fieldName}.garageLocation.address`}
            type="string"
            label="Garage Location"
            mode="light"
            xs={8}
          />
          <Field
            id={`${fieldName}.garageLocation.address2`}
            name={`${fieldName}.garageLocation.address2`}
            type="string"
            label="Address 2"
            mode="light"
            xs={4}
          />
          <Field
            id={`${fieldName}.garageLocation.city`}
            name={`${fieldName}.garageLocation.city`}
            type="string"
            label="City"
            mode="light"
            xs={4}
          />
          <Field
            id={`${fieldName}.garageLocation.state`}
            name={`${fieldName}.garageLocation.state`}
            type="autocomplete"
            label="State"
            mode="light"
            xs={4}
            options={lookupsJson.usStates}
            stateSelectForm
          />
          <Field
            id={`${fieldName}.garageLocation.zip`}
            name={`${fieldName}.garageLocation.zip`}
            type="string"
            label="Zip Code"
            mode="light"
            xs={4}
          />
          <Field
            id={`${fieldName}.year`}
            name={`${fieldName}.year`}
            type="string"
            label="Trailer year"
            mode="light"
            xs={4}
          />
          <Field
            id={`${fieldName}.value`}
            name={`${fieldName}.value`}
            type="string"
            label="Trailer value"
            mode="light"
            xs={4}
            InputProps={{
              startAdornment: (
                <InputAdornment className={classes.inputAdornment} clposition="start">
                  $
                </InputAdornment>
              )
            }}
          />
          <Field
            id={`${fieldName}.contents`}
            name={`${fieldName}.contents`}
            type="string"
            label="Contents value"
            mode="light"
            xs={4}
            InputProps={{
              startAdornment: (
                <InputAdornment className={classes.inputAdornment} clposition="start">
                  $
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid spacing={4} container justify="space-between" className={classes.containerDark} key={state}>
          <Grid item xs={6}>
            <LabelTooltip
              label="Collision Deductible"
              tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.deductibleCollision }}
            >
              <Field
                id={`${fieldName}.deductibleCollision`}
                name={`${fieldName}.deductibleCollision`}
                type="select"
                mode="dark"
                // how do we change this over to lookupsJson?
                options={getOptions('deductibleCollision', state)}
              />
            </LabelTooltip>
          </Grid>

          <Grid item xs={6}>
            <LabelTooltip
              label="Comprehensive Deductible"
              tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.deductibleComprehensive }}
            >
              <Field
                id={`${fieldName}.deductibleComprehensive`}
                name={`${fieldName}.deductibleComprehensive`}
                type="select"
                mode="dark"
                // how do we change this over to lookupsJson?
                options={getOptions('deductibleComprehensive', state)}
              />
            </LabelTooltip>
          </Grid>
        </Grid>
      </Form>
    </CollapsibleCard>
  );
};

Trailer.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  fieldId: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  item: PropTypes.shape({
    VIN: PropTypes.string,
    addDate: PropTypes.string,
    confirmed: PropTypes.bool,
    deductibleCollision: PropTypes.string,
    deductibleComprehensive: PropTypes.string,
    coverageLoan: PropTypes.bool,
    garageLocation: PropTypes.object,
    purchaseDate: PropTypes.string,
    year: PropTypes.number
  }).isRequired,
  index: PropTypes.number.isRequired,
  removeFromList: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  offer: PropTypes.object,
  policy: PropTypes.object
};

Trailer.defaultProps = {
  disabled: false,
  offer: {},
  policy: {}
};

export default flowRight(withStyles(styles), withDatePicker)(memo(Trailer));
