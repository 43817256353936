import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { CardElement } from '@stripe/react-stripe-js';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import { FormField } from 'core/components/form';
import { useStripeToken } from 'offer/components/checkout/hooks/use-stripe-token';
import { Label } from 'core/components/label';

import useStyles, { styles } from './credit-card-field.styles';

const CreditCardField = observer(function CreditCardField() {
  const formik = useFormikContext();
  const classes = useStyles();
  const theme = useTheme();
  const { getStripeCCToken } = useStripeToken();
  const {
    offer: { setSavingCheckoutData, saveCheckoutFormData }
  } = useStore();
  const inlineStyles = styles(theme);
  const { setFieldValue, errors, touched, setTouched, values, setFieldError } = formik;
  const className =
    touched.completeCardData && errors.completeCardData ? 'StripeElement StripeElement-custom-error' : 'StripeElement';

  const onChange = useCallback(
    async (res) => {
      setTouched({ ...touched, completeCardData: true });
      setFieldValue('cardBrand', res.brand);
      setFieldValue('completeCardData', res.complete);

      if (res.complete) {
        setSavingCheckoutData(true);
        const { error, token } = await getStripeCCToken(values);
        if (token) {
          saveCheckoutFormData({ id: values.address, creditCardToken: token, error });
        }
        if (error) {
          setFieldError('completeCardData', error.message);
        }
        setSavingCheckoutData(false);
      }
    },
    [
      setFieldValue,
      setTouched,
      touched,
      setSavingCheckoutData,
      getStripeCCToken,
      saveCheckoutFormData,
      values,
      setFieldError
    ]
  );

  return (
    <div className={classes.root}>
      {values?.recoveredPaymentData?.creditCard ? (
        <Grid container>
          <FormField
            type="value"
            name="recoveredPaymentData.creditCard.card.brand"
            label="Saved Credit Card Brand"
            xs={4}
          />
          <FormField
            type="value"
            name="recoveredPaymentData.creditCard.card.last4"
            label="Saved Credit Card Last 4"
            xs={4}
          />
        </Grid>
      ) : (
        <>
          <Label type="infoLabel">Credit Card Number</Label>
          <CardElement
            onChange={onChange}
            hidePostalCode
            options={{ style: inlineStyles.cardElementStyle, hidePostalCode: true }}
            className={className}
          />
        </>
      )}

      <span className="StripeElement-error-message">{touched.completeCardData && errors.completeCardData}</span>
    </div>
  );
});

export default CreditCardField;
