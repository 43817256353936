import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    border: '2px solid white',
    backgroundColor: theme.colors.page__background_primary,
    padding: '18px 32px 24px 32px',
    marginBottom: 8
  },
  'light-textFieldRoot': {
    marginBottom: 10
  },
  button: {
    minWidth: 163
  },
  formContainer: {
    marginTop: 0,
    marginBottom: 0
  },
  addButton: {
    marginTop: 16,
    width: '100%'
  },
  address: {
    width: 'calc(70% - 8px)',
    marginRight: 8
  },
  carToolTip: {
    marginRight: '10px'
  },
  trailerTip: {
    marginRight: -10
  }
}));

export default useStyles;
