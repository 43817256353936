import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { lookupsJson, rentersPolicyAvailable } from '@ourbranch/lookups';
import { connect, useFormikContext } from 'formik';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import { FormField } from 'core/components/form';
import Field from 'core/components/form/form.v2';
import Switch from 'core/components/switch';
import { LabelTooltip } from 'core/components/label-tooltip';
import { tooltipHoverTexts } from 'core/helpers/constants';
import Section from 'core/components/section';
import AddressAutocomplete from 'core/components/address-autocomplete';

import styles from './renters.styles';

const id = 'rentersCoverage';

// What is the 'disabled' prop for? I'm leaving it as-is

const Renters = ({ classes, disabled }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { includeRenters } = values;
  const handleRentersChange = useCallback(() => {
    setFieldValue('includeRenters', !includeRenters);
    setFieldTouched('includeRenters');
  }, [includeRenters, setFieldValue, setFieldTouched]);
  const switchClasses = useMemo(
    () => ({
      label: classes.label
    }),
    [classes]
  );

  const { state } = values.rentersCoverage.rentersLocation;

  return (
    <Section title="Renters Coverage" type="SubSection">
      <div className={classes.container}>
        <Grid container justify="space-between" alignItems="flex-start" spacing={2}>
          <Grid
            container
            justify="space-between"
            alignItems="flex-start"
            className={classes.containerInner}
            spacing={2}
          >
            <LabelTooltip
              label="Renter's Insurance"
              tooltip={
                !rentersPolicyAvailable[state]
                  ? {
                      label: '(?)',
                      onHoverText: "Renter's coverage is not available in this state"
                    }
                  : { label: '', onHoverText: '' }
              }
            >
              <Switch
                className={classes.switchContainer}
                id="includeRenters"
                classes={switchClasses}
                mode="light"
                label="Renters"
                disabled={!rentersPolicyAvailable[state]}
                value={includeRenters && rentersPolicyAvailable[state]}
                onChange={handleRentersChange}
              />
            </LabelTooltip>
            {values.includeRenters && rentersPolicyAvailable[state] && (
              <>
                <AddressAutocomplete
                  mode="light"
                  name={`${id}.rentersLocation.address`}
                  id={`${id}.rentersLocation.address`}
                  label="Address*"
                  xs={12}
                />
                <Field
                  mode="light"
                  name={`${id}.rentersLocation.address2`}
                  id={`${id}.rentersLocation.address2`}
                  type="string"
                  label="Floor, unit, building, etc"
                  xs={12}
                  className={classes.addressFormfield}
                />
                <Field
                  mode="light"
                  name={`${id}.rentersLocation.city`}
                  id={`${id}.rentersLocation.city`}
                  type="string"
                  label="City*"
                  xs={4}
                  className={classes.addressFormfield}
                />
                <Field
                  mode="light"
                  name={`${id}.rentersLocation.state`}
                  id={`${id}.rentersLocation.state`}
                  type="autocomplete"
                  label="State*"
                  options={lookupsJson.usStates}
                  xs={4}
                  className={classes.addressFormfield}
                  stateSelectForm
                />
                <Field
                  mode="light"
                  name={`${id}.rentersLocation.zip`}
                  id={`${id}.rentersLocation.zip`}
                  type="string"
                  label="Zip Code*"
                  xs={4}
                  className={classes.addressFormfield}
                />
              </>
            )}
          </Grid>
          {values.includeRenters && rentersPolicyAvailable[state] && (
            <Grid
              container
              justify="space-around"
              alignItems="flex-start"
              spacing={4}
              className={classes.containerDark}
            >
              <Grid item xs={6}>
                <LabelTooltip
                  label="Personal Liability Coverage"
                  tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.personalLiabilityCoverage }}
                >
                  <FormField
                    mode="dark"
                    name={`${id}.coverageX`}
                    id={`${id}.coverageX`}
                    options={lookupsJson.rentersLiability}
                    type="select"
                  />
                </LabelTooltip>
              </Grid>

              <Grid item xs={6}>
                <LabelTooltip
                  label="PP/AL Expense Coverage"
                  tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.PPALExpenseCoverage }}
                >
                  <FormField
                    mode="dark"
                    name={`${id}.coverageCD`}
                    id={`${id}.coverageCD`}
                    options={lookupsJson.coverageCD}
                    type="select"
                  />
                </LabelTooltip>
              </Grid>

              <Grid item xs={6}>
                <LabelTooltip
                  label="Deductible"
                  tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.deductibleAllOther }}
                >
                  <FormField
                    mode="dark"
                    name={`${id}.deductible`}
                    id={`${id}.deductible`}
                    options={[
                      { id: '500', value: '500' },
                      { id: '1000', value: '1000' }
                    ]}
                    type="select"
                  />
                </LabelTooltip>
              </Grid>

              <Grid item xs={6}>
                <LabelTooltip
                  label="Guest Medical Limit"
                  tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.guestMedicalLimit }}
                >
                  <FormField
                    mode="dark"
                    name={`${id}.coverageY`}
                    id={`${id}.coverageY`}
                    options={[
                      { id: '1000', value: '1000' },
                      { id: '2500', value: '2500' },
                      { id: '5000', value: '5000' }
                    ]}
                    type="select"
                  />
                </LabelTooltip>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </Section>
  );
};

Renters.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

Renters.defaultProps = {
  disabled: false
};

export default flowRight(withStyles(styles), connect)(Renters);
