import { Auth } from 'aws-amplify';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import { Route, Switch } from 'react-router-dom';

import { useStore } from 'core/store';
import CustomersResults from 'core/components/search-results/customers-results';
import OffersResults from 'core/components/search-results/offers-results';
import PoliciesResults from 'core/components/search-results/policies-results';
import { identify } from 'core/helpers/analytics';
import { AuthContext } from 'core/components/auth';
import { useQueryParams } from 'core/hooks/useQueryParams';
import SearchForm from './components/search-form';
import SearchHeader from './components/search-header';

const Search = observer(function Search({ location, history }) {
  const {
    quote: { setPrefillData },
    search
  } = useStore();
  const {
    loading,
    setCurrentResultsPage,
    currentResultsPage,
    setSearchString,
    searchString,
    searchCustomersAction,
    searchOffersAction,
    searchPoliciesAction,
    customersResults,
    customersResults: { nbHits: customerNumHits },
    offersResults,
    offersResults: { nbHits: offerNumHits },
    policiesResults,
    policiesResults: { nbHits: policyNumHits }
  } = search;
  const { canEdit } = useContext(AuthContext);
  const params = useQueryParams();
  // const params = new URLSearchParams(location.search);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      identify({ username: user.username, email: user.attributes.email });
    });
  }, []);

  // Set the current table page page back to 0 when the route changes.
  // `history.listen` returns a function that stops the listener when called,
  // which is used as the cleanup function for this effect.
  useEffect(() => history.listen(() => setCurrentResultsPage(0)));

  const runSearch = useCallback(async () => {
    // The `setOfferSearchResults` / `setUserSearchResults` functions will be called multiple times with the same
    // result when all the queued searches from the debounced search function resolve. But because they'll all resolve
    // with the same result, React's diffing will only cause one re-render, so this won't cause a performance issue.
    searchCustomersAction();
    searchOffersAction();
    searchPoliciesAction();
  }, [searchCustomersAction, searchOffersAction, searchPoliciesAction]);

  // The `runSearch` function changes when one of the items in its dependency array changes
  // When one of those things change we also want to run the search again.
  useEffect(() => {
    runSearch();
  }, [runSearch, currentResultsPage]);

  useEffect(() => {
    if (!canEdit) {
      history.replace('/search/policies');
    }
  }, [canEdit, history]);

  // handle searching from query params, and redirect if no search results
  const inboundQuery = params?.search;

  useEffect(() => {
    if (inboundQuery) {
      setSearchString(inboundQuery);
      runSearch();
    }
  }, [inboundQuery, setSearchString, runSearch]);
  reaction(
    () => search.offersResults,
    (results) => {
      if (results.nbHits === 0 && inboundQuery) {
        setSearchString(''); // or else the results dropdown will show
        setPrefillData(params, true);
        history.push('/quote');
      }
    }
  );

  const onSearchStringChange = (newSearchString) => {
    setSearchString(newSearchString);
    setCurrentResultsPage(0);
    runSearch();
  };

  const onSearchButtonPress = () => {
    setCurrentResultsPage(0);
    runSearch();
  };

  return (
    <>
      <SearchHeader policiesNumHits={policyNumHits} customerNumHits={customerNumHits} offerNumHits={offerNumHits}>
        <SearchForm
          value={searchString}
          onChange={(e) => onSearchStringChange(e.target.value)}
          onSearchButtonPress={onSearchButtonPress}
          location={location}
        />
      </SearchHeader>
      <Switch>
        {canEdit && (
          <Route path="/search/offers">
            <OffersResults
              loading={loading}
              hits={offersResults.hits}
              totalHits={get(offersResults, 'nbHits')}
              page={currentResultsPage}
              onChangePage={setCurrentResultsPage}
              showHeader
            />
          </Route>
        )}
        <Route path="/search/customers">
          <CustomersResults
            history={history}
            loading={loading}
            hits={customersResults.hits}
            totalHits={get(customersResults, 'nbHits')}
            page={currentResultsPage}
            onChangePage={setCurrentResultsPage}
            useLink
          />
        </Route>
        <Route path="/search/policies">
          <PoliciesResults
            history={history}
            loading={loading}
            hits={policiesResults.hits}
            totalHits={get(policiesResults, 'nbHits')}
            page={currentResultsPage}
            onChangePage={setCurrentResultsPage}
            useLink
          />
        </Route>
      </Switch>
    </>
  );
});

Search.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Search;
