/* eslint-disable func-names */
import * as Yup from 'yup';
import { UMPDOnlyWithUMBIStates } from '@ourbranch/lookups';
import requiredString from 'common/helpers/yup-required-string';
import shouldBeAValidState from 'common/helpers/yup-valid-state';
import { haveAnyOfThisCarsSymbolsChanged, hasMakeModelStyleSymbols } from 'common/helpers/car-symbols-helpers';
import { canAddUmbrella } from 'core/helpers/quoter.service';
import { HEALTH_INSURANCE_TYPE } from 'core/helpers/constants';

const min = (minVal) => (val) => val >= minVal;

function deductibleValidation(value) {
  return !(this.parent.items && this.parent.items.length && !value);
}

Yup.addMethod(Yup.string, 'requiredString', requiredString);
Yup.addMethod(Yup.mixed, 'shouldBeAValidState', shouldBeAValidState);

export const homeValidation = ({ policies, isAdvancedConnectedHome, savedSegment, session }) =>
  Yup.object().shape({
    home: Yup.object().shape({
      roofYear: Yup.number().test('roofYearMin', 'Roof year must be higher than 1899', min(1900)),
      yearBuilt: Yup.number().test('yearBuiltMin', 'Year built must be higher than 1899', min(1900))
    }),
    homeCoverage: Yup.object().shape({
      coverageA: Yup.number().test(
        'coverageA',
        'Error, dwelling limit value cannot be less than estimated cost to rebuild home.',
        function (
          currentCoverageA,
          {
            options: {
              context: { home }
            }
          }
        ) {
          return home.replacementCostEstimate.total <= currentCoverageA;
        }
      ),
      coverageCourseOfConstruction: Yup.boolean(),
      coverageFairRental: Yup.boolean().when('coverageCourseOfConstruction', {
        is: true,
        then: Yup.boolean().test('coverageFairRental', 'Active construction. Set to inactive', (val) => !val),
        otherwise: Yup.boolean().required()
      }),
      coverageBCPctOfA: Yup.number().when('coverageCourseOfConstruction', {
        is: true,
        then: Yup.number().test('coverageBCPctOfA', 'Active construction. Set to 0', (val) => val === 0),
        otherwise: Yup.number().required()
      }),
      coverageIBSC: Yup.number().when('coverageCourseOfConstruction', {
        is: true,
        then: Yup.number().test('coverageIBSC', 'Active construction. Must be < 20%', (val) => val < 20),
        otherwise: Yup.number()
      }),
      coverageDC: Yup.boolean().when('coverageCourseOfConstruction', {
        is: true,
        then: Yup.boolean().test('coverageDC', 'Active construction. Set to inactive', (val) => !val),
        otherwise: Yup.boolean()
      }),
      coverageWaterBackup: Yup.number().when('coverageCourseOfConstruction', {
        is: true,
        then: Yup.number().test('coverageWaterBackup', 'Active construction. Set to 0', (val) => val < 20),
        otherwise: Yup.number()
      })
    }),
    includeUmbrella: Yup.boolean()
      .required()
      .test(
        'includeUmbrella',
        'Error, you cannot activate umbrella with an excluded driver on your Auto policy',
        (value) => {
          if (value) {
            const policy = policies ? policies.find(({ policyType }) => policyType === 'A') : undefined;
            if (policy) {
              const driver = policy.policyDetails.drivers
                ? policy.policyDetails.drivers.find(({ excludeDriver }) => excludeDriver)
                : undefined;
              return !driver;
            }
          }
          return true;
        }
      )
      .test(
        'canAddUmbrella',
        "Policy does not meet requirements to add Umbrella: Auto BI and UM/UIM BI need to be at least 250/500 and Homeowner's Liability needs to be at least $300K",
        // eslint-disable-next-line func-names
        function (val, { options: { context } }) {
          if (val) {
            const { autoCoverage } = context;
            const { policyLimitBIPD, policyLimitUMBI } = autoCoverage;
            return canAddUmbrella({
              policyLimitBIPD,
              policyLimitUMBI,
              coverageX: context?.homeCoverage?.coverageX
            });
          }

          return true;
        }
      ),
    umbrellaCoverage: Yup.object()
      .shape({
        liabilityCoverageLimit: Yup.number().test(
          'is-team-leader',
          'You must have team leader permissions to increase coverage over $2m',
          function (value, { options: { context } }) {
            const liabilityCoverageLimitChanged =
              context.includeUmbrella && savedSegment?.umbrellaCoverage?.liabilityCoverageLimit !== value;
            if (liabilityCoverageLimitChanged && value > 2000000 && !session.isTeamLeader) {
              return false;
            }
            return true;
          }
        ),
        watercraftHullLengths: Yup.array()
          .of(Yup.number().max(30, 'We do not allow boats with hull lengths over 30 feet.'))
          .nullable()
      })
      .nullable(),
    scheduledPersonalProperty: Yup.object()
      .shape({
        items: Yup.array(Yup.object()),
        deductible: Yup.mixed().test('deductible', 'SPP Deductible is required', deductibleValidation)
      })
      .nullable(),
    connectedHome: Yup.object().when('homeCoverage', {
      is: (val) => val.coverageCourseOfConstruction,
      then: Yup.object().test(
        'connectedHome',
        'Active construction. Cannot add Connected Home Discount',
        (val) => val.monitored === 'N'
      ),
      otherwise: Yup.object()
        .shape({
          monitored: Yup.string(),
          moistureDevices: Yup.number()
            .min(0, 'Please enter a positive number')
            .test('ch-on', 'Required. If none, enter 0.', function (value, context) {
              return !context.parent.monitored || context.parent?.monitored === 'N' || value >= 0;
            }),
          motionDetectingDevices: Yup.number()
            .min(0, 'Please enter a positive number')
            .test('ch-on', 'Required. If none, enter 0.', function (value, context) {
              return (
                !context.parent.monitored || isAdvancedConnectedHome || context.parent?.monitored === 'N' || value >= 0
              );
            }),
          smokeDetectingDevices: Yup.number()
            .min(0, 'Please enter a positive number')
            .test('ch-on', 'Required. If none, enter 0.', function (value, context) {
              return !context.parent.monitored || context.parent?.monitored === 'N' || value >= 0;
            }),
          theftPreventionDevices: Yup.number()
            .min(0, 'Please enter a positive number')
            .test('ch-on', 'Required. If none, enter 0.', function (value, context) {
              return !context.parent.monitored || context.parent?.monitored === 'N' || value >= 0;
            })
            .nullable(),
          autoWaterShutoffDevices: Yup.boolean().default(false),
          providerName: Yup.string().test('ch-on', 'Required', function (value, context) {
            return (
              !context.parent.monitored || context.parent?.monitored === 'N' || context.parent?.providerName?.length
            );
          })
        })
        .nullable()
    })
  });

function getPLimitValue(policyLimitBIPD = 0) {
  if (!policyLimitBIPD) {
    return 0;
  }
  if (policyLimitBIPD.includes('CSL')) {
    return Number(policyLimitBIPD.split(' ')[0]);
  }
  const splitted = policyLimitBIPD.split('/');
  if (splitted && splitted.length === 3) {
    return Number(splitted[2]);
  }
  return Number(policyLimitBIPD.split(' ')[0]);
}

function getUMPDValue(limitUMPD = 0) {
  if (!limitUMPD || limitUMPD === 'NONE' || limitUMPD.includes('/')) {
    return 0;
  }
  if (limitUMPD.includes('CSL')) {
    return Number(limitUMPD.split(' ')[0]);
  }

  // in order to compare to policyLimitBIPD, we should divide by 1000
  // policyLimitBIPD is stored like 250/500/100, which represents 250000/500000/100000
  return Number(limitUMPD) / 1000;
}

export const autoValidation = ({ policies }) =>
  Yup.lazy(({ autoCoverage }) => {
    return Yup.object().shape({
      auto: Yup.object().shape({
        pipAllResidents: Yup.number()
          .test('requiredForMI', 'Required', function (value) {
            if (this.options.context?.state === 'MI') {
              return value >= 0;
            }
            return true;
          })
          .nullable(),
        pipResidentsWithQHC: Yup.number()
          .test('requiredForMI', 'Required', function (value) {
            if (this.options.context?.state === 'MI') {
              return value >= 0;
            }
            return true;
          })
          .test(
            'compareToAllPipEligibleResidents',
            'The amount of residents with QHC cannot exceed the amount of eligible pip residents',
            function (value) {
              if (this.options.context?.state === 'MI') {
                const pipEligibleResidentsCount =
                  this.options.context.auto?.pipAllResidents - this.options.context.auto?.pipAdditionalResidents;
                if (value > pipEligibleResidentsCount) {
                  // using this.createError to have a dynamic error message
                  return this.createError({
                    message: `This cannot exceed ${pipEligibleResidentsCount} - the amount of pip eligible residents`
                  });
                }
              }
              return true;
            }
          )
          .test('compareToHealthInsurance', 'Must be at least 1 because Medicare is selected', function (value) {
            if (
              this.options.context?.state === 'MI' &&
              this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE &&
              !this.options.context.auto?.pipEveryoneOnSamePlan
            ) {
              return value >= 1;
            }
            return true;
          })
          .test(
            'compareToHealthInsurance2',
            'Must be the same as the number of residents in household because Medicare is selected and everyone is on the same plan',
            function (value) {
              if (
                this.options.context?.state === 'MI' &&
                this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE &&
                this.options.context.auto?.pipEveryoneOnSamePlan
              ) {
                return value === this.options.context.auto?.pipAllResidents;
              }
              return true;
            }
          )
          .nullable(),
        pipExcludedResidents: Yup.number()
          .test('requiredForMI', 'Required', function (value) {
            if (this.options.context?.state === 'MI') {
              return value >= 0;
            }
            return true;
          })
          .test(
            'checkIfMedicare',
            'The number of excluded residents must be 0 or the same as the number of residents in the household',
            function (value) {
              if (
                this.options.context?.state === 'MI' &&
                this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE
              ) {
                return value === 0 || value === this.options.context.auto?.pipAllResidents;
              }
              return true;
            }
          )
          .test(
            'checkIfMedicare2',
            'The number of excluded residents must be 0 because residents with QHC is less than residents in household',
            function (value) {
              if (
                this.options.context?.state === 'MI' &&
                this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE &&
                this.options.context.auto?.pipAllResidents > this.options.context.auto?.pipResidentsWithQHC
              ) {
                return value === 0;
              }
              return true;
            }
          )
          .test(
            'compareToAllPipEligibleResidents',
            'The amount of excluded residents cannot exceed the amount of eligible pip residents',
            function (value) {
              if (this.options.context?.state === 'MI') {
                const pipEligibleResidentsCount =
                  this.options.context.auto?.pipAllResidents - this.options.context.auto?.pipAdditionalResidents;
                if (value > pipEligibleResidentsCount) {
                  // using this.createError to have a dynamic error message
                  return this.createError({
                    message: `This cannot exceed ${pipEligibleResidentsCount} - the amount of pip eligible residents`
                  });
                }
              }
              return true;
            }
          )
          .nullable(),
        pipAdditionalResidents: Yup.number()
          .test(
            'compareToAllResidents',
            'The amount of excluded residents cannot exceed the amount of residents living in the household',
            function (value) {
              if (this.options.context?.state === 'MI') {
                return value <= this.options.context.auto?.pipAllResidents;
              }
              return true;
            }
          )
          .nullable(),
        pipHealthInsuranceType: Yup.string()
          .test('requiredForMI', 'Required', function (value) {
            if (this.options.context?.state === 'MI') {
              return value?.length;
            }
            return true;
          })
          .nullable()
      }),
      autoCoverage: Yup.object().shape({
        policyLimitPIPME: Yup.string()
          .test('requiredForMI', 'Required', function (value) {
            if (this.options.context?.state === 'MI') {
              return value?.length;
            }
            return true;
          })
          .nullable(),
        policyLimitPIPACR: Yup.string()
          .test('requiredForMI', 'Required', function (value) {
            if (this.options.context?.state === 'MI') {
              return value?.length;
            }
            return true;
          })
          .nullable(),
        policyLimitMedicalPayments: Yup.string()
          .test('medicalPaymentsOrComboFBP', 'Required', function (value) {
            if (this.options.context?.state === 'PA') {
              const { policyLimitComboFBP } = this.parent;
              if (policyLimitComboFBP && policyLimitComboFBP !== 'NONE' && value !== 'NONE') {
                return this.createError({
                  message: 'Can not choose both this and combined first party benefits'
                });
              }
              if (policyLimitComboFBP && policyLimitComboFBP === 'NONE' && value === 'NONE') {
                return this.createError({
                  message: 'Choose medical payments or combined first party benefits'
                });
              }
            }
            return true;
          })
          .test('medicalPaymentsOrPIPNotBothTX', 'Cannnot chose this and PIP in TX', function (value) {
            if (this.options.context?.state === 'TX') {
              const { policyLimitPIP } = this.parent;
              if (value !== 'NONE' && policyLimitPIP && policyLimitPIP !== 'NONE') {
                return false;
              }
              return true;
            }
            return true;
          })
          .nullable(),
        policyLimitComboFBP: Yup.string()
          .test('medicalPaymentsOrComboFBP', 'required', function (value) {
            if (this.options.context?.state === 'PA') {
              const { policyLimitMedicalPayments } = this.parent;
              if (policyLimitMedicalPayments && policyLimitMedicalPayments !== 'NONE' && value !== 'NONE') {
                return this.createError({
                  message: 'Can not choose both this and medical payments'
                });
              }
              if (policyLimitMedicalPayments && policyLimitMedicalPayments === 'NONE' && value === 'NONE') {
                return this.createError({
                  message: 'Choose medical payments or combined first party benefits'
                });
              }
            }
            return true;
          })
          .nullable(),
        policyLimitPIP: Yup.string()
          .test('medicalPaymentsOrPIPNotBothTX', 'Cannnot chose this and MedPay in TX', function (value) {
            if (this.options.context?.state === 'TX') {
              const { policyLimitMedicalPayments } = this.parent;
              if (value !== 'NONE' && policyLimitMedicalPayments && policyLimitMedicalPayments !== 'NONE') {
                return false;
              }
              return true;
            }
            return true;
          })
          .nullable()
      }),
      cars: Yup.array(
        Yup.object().shape({
          limitUMPD: Yup.mixed()
            .nullable()
            .test(
              'limitUMPDMustBeLowerThanPLimitValue',
              "Error, this can't be higher than property damage limit",
              (value) => {
                return getUMPDValue(value) <= getPLimitValue(autoCoverage.policyLimitBIPD);
              }
            )
            .test(
              'noLimitUMPDWithoutUMBI',
              'Error, UMPD cannot be chosen in this state without UMBI',
              (value, testContext) => {
                return !(
                  value !== 'NONE' &&
                  UMPDOnlyWithUMBIStates[testContext.parent.garageLocation.state] &&
                  (autoCoverage.policyLimitUMBI === '0/0' || autoCoverage.policyLimitUMBI === 'NONE')
                );
              }
            ),
          garageLocation: Yup.object()
            .nullable()
            .shape({
              address: Yup.string()
                .nullable()
                .test('is-po-box', 'PO Box Addresses are invalid', function (value) {
                  const pattern = new RegExp('\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b', 'i');
                  return !(pattern.test(value) || pattern.test(value));
                }),
              state: Yup.string().nullable().shouldBeAValidState('auto.garageLocation.state')
            }),
          deductibleCollision: Yup.string().requiredString('Collision deductible is required'),
          deductibleComprehensive: Yup.string().requiredString('Comprehensive deductible is required'),
          limitRental: Yup.string().requiredString('Rental car expense is required'),
          limitACPE: Yup.string().requiredString('Additional custom parts fields is required'),
          symbolMake: Yup.string()
            .test(
              'symbolMake-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) return true;
                if (parent.symbolAux && !hasMakeModelStyleSymbols(parent)) return !value || value?.length === 2;
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolAux: Yup.string()
            .test(
              'symbolAux-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) return true;
                if (hasMakeModelStyleSymbols(parent)) return !value || value?.length === 2;
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolModel: Yup.string()
            .test(
              'symbolModel-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) return true;
                if (parent.symbolAux && !hasMakeModelStyleSymbols(parent)) return !value || value?.length === 2;
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolStyle: Yup.string()
            .test(
              'symbolStyle-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) return true;
                if (parent.symbolAux && !hasMakeModelStyleSymbols(parent)) return !value || value?.length === 2;
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable()
        })
      ),
      trailers: Yup.array(
        Yup.object().shape({
          VIN: Yup.string().requiredString('VIN is required'),
          type: Yup.string().requiredString('Trailer type is required'),
          year: Yup.number().typeError('Trailer year should be a number').required('Trailer year is required'),
          value: Yup.number()
            .typeError('Trailer value should be a number between $0 - $50000')
            .positive('Trailer value should be between $0 - $50000')
            .integer('Trailer value should be between $0 - $50000')
            .min(1, 'Trailer value should be greater than $0')
            .max(50000, 'Trailer value should be less than or equal to $50000')
            .when(
              ['deductibleCollision', 'deductibleComprehensive'],
              (deductibleCollision, deductibleComprehensive, schema) => {
                const validValues = [deductibleCollision, deductibleComprehensive.split('/')[0]].filter(
                  (value) => value !== 'NONE' && value
                );
                if (!validValues.length) return schema;
                const minValue = Math.min(...validValues);
                return schema.min(500 + minValue, `Trailer value should be greater than ${500 + minValue}`);
              }
            )
            .required('Trailer value is required'),
          contents: Yup.number()
            .typeError('Content value should be a number between $0 - $5000')
            .positive('Content value should be between $0 - $5000')
            .integer('Content value should be between $0 - $5000')
            .min(0, 'Content value should be greater than $0')
            .max(5000, 'Content value should be less than or equal to $5000')
            .required('Trailer content value is required'),

          deductibleCollision: Yup.string()
            .requiredString('Collision deductible is required')
            .test(
              'minimumCollDeductibleMet',
              'Trailer deductibles must match deductibles of at least one insured vehicle on the policy',
              function (currentTrailerDeductibleCollision, { options }) {
                const { context, parent } = options;
                const { cars } = context;
                for (const car of cars) {
                  if (
                    car.deductibleCollision === currentTrailerDeductibleCollision &&
                    car.deductibleComprehensive.split('/')[0] === parent.deductibleComprehensive.split('/')[0]
                  ) {
                    return true;
                  }
                }
                return false;
              }
            ),

          deductibleComprehensive: Yup.string()
            .requiredString('Comprehensive deductible is required')
            .test(
              'minimumCompDeductibleMet',
              'Trailer deductibles must match deductibles of at least one insured vehicle on the policy',
              function (currentTrailerDeductibleComprehensive, { options }) {
                const { context, parent } = options;
                const { cars } = context;
                for (const car of cars) {
                  if (
                    car.deductibleComprehensive.split('/')[0] === currentTrailerDeductibleComprehensive.split('/')[0] &&
                    car.deductibleCollision === parent.deductibleCollision
                  ) {
                    return true;
                  }
                }
                return false;
              }
            ),

          garageLocation: Yup.object().shape({
            address: Yup.string()
              .requiredString('Address is required')
              .test('is-po-box', 'PO Box Addresses are invalid', (value) => {
                const pattern = new RegExp('\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b', 'i');
                return !(pattern.test(value) || pattern.test(value));
              }),
            city: Yup.string().requiredString('City is required'),
            state: Yup.string().requiredString('State is required').shouldBeAValidState('policy.trailers'),
            zip: Yup.string().requiredString('Zip code is required')
          })
        })
      ).nullable(),
      drivers: Yup.array(
        Yup.object().shape({
          excludeDriver: Yup.boolean()
            .nullable()
            .test('excludeDriver', 'Error, umbrella is active on Home policy', (value) => {
              if (value) {
                const policy = policies ? policies.find(({ policyType }) => policyType === 'H') : undefined;
                if (policy) {
                  return !policy.policyDetails.includeUmbrella;
                }
              }
              return true;
            }),
          driversLicenseState: Yup.string()
            .requiredString('State is required')
            .shouldBeAValidState('auto.drivers.driversLicenseState')
        })
      )
    });
  });
