import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  secondary: {
    padding: '24px 32px',
    margin: 0,
    overflow: 'visible'
  },
  discountsContainer: {
    marginTop: 24
  },
  inventoryContainer: {
    display: 'flex',
    flexFlow: 'column wrap'
  },
  manualUploadCheckbox: {
    marginLeft: 50
  },
  discountInventoryScore: {
    marginBottom: -20
  }
}));

export default useStyles;
