import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { TableCell } from '@material-ui/core';

import { Table, SelectableRow, Loading, Label } from 'core';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { useStore } from 'core/store';
import { dateFormatter } from 'core/helpers/formatters';
import useStyles from './claims.styles';
import ClaimDetail from './claim-detail';
import { claimStatusMap, labelStyleMap } from './constants';

import coffeeIcon from '../tickets/assets/coffee.svg';

const Claims = observer(function Claims() {
  const classes = useStyles();
  const { account } = useStore();

  useEffect(() => {
    if (!account.claims.length && !account.claimsLoading && account.id) {
      account.getClaims();
    }
  }, [account, account.id]);

  const claims = account.claims;

  const [claimDetailOpen, setClaimDetailOpen] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState();

  const showClaimDetail = (claim) => {
    setSelectedClaim(claim);
    setClaimDetailOpen(true);
  };

  const hideClaimDetail = () => {
    setClaimDetailOpen(false);
    setSelectedClaim({});
  };

  return (
    <>
      <Section title="Claims" rightLabel="total">
        {!account.claimsLoading ? (
          claims.length > 0 ? (
            <Card type="scrollable">
              <Table
                selfContained={false}
                header={
                  <>
                    <TableCell>Date of Loss</TableCell>
                    <TableCell>Claim Number</TableCell>
                    <TableCell>Claim Type</TableCell>
                    <TableCell>Status</TableCell>
                  </>
                }
                body={
                  <>
                    {claims.map((claim, index) => (
                      <SelectableRow
                        key={`claim-${index}`}
                        id={`claim-${index}`}
                        onClick={() => showClaimDetail(claim)}
                      >
                        <TableCell>{dateFormatter(claim.lossDate)}</TableCell>
                        <TableCell>{claim.claimNumber}</TableCell>
                        <TableCell>{claim.claimType || (/home\b/i.test(claim.policyID) ? 'HOME' : 'AUTO')}</TableCell>
                        <TableCell className={classes[claimStatusMap[claim.status]]}>
                          <Label type={`${labelStyleMap[claimStatusMap[claim.status]]}`}>
                            {claimStatusMap[claim.status]}
                          </Label>
                        </TableCell>
                      </SelectableRow>
                    ))}
                  </>
                }
              />
            </Card>
          ) : (
            <Card type="noData">
              <div className={classes.noData}>
                <img src={coffeeIcon} alt="No tickets" />
                <Label type="darkGreenMedium">Relax, there are no tickets yet.</Label>
              </div>
            </Card>
          )
        ) : (
          <Loading />
        )}
      </Section>
      {claimDetailOpen && (
        <ClaimDetail open={claimDetailOpen} claim={selectedClaim} hideClaimDetail={hideClaimDetail} />
      )}
    </>
  );
});

export default Claims;
