import { withStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import { withStore } from 'core/store';
import { Formik } from 'formik';
import { fromJS } from 'immutable';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { AuthContext } from 'core/components/auth';
import { withToast } from 'core/components/toast/context';
import { track } from 'core/helpers/analytics';
import { awsDateFormatter } from 'core/helpers/formatters';
import { conditionalEndDate, dateFromPolicy, getEndDate, getStartMinMaxDate } from '../dates';
import Footer from '../footer';
import CancellationForm from '../footer/cancellation-form';
import ReinstateForm from '../footer/reinstate-form';
import { AddHoldCard } from '../hold-card';
import PolicySettings from './policy-settings';
import styles from './policy-settings.styles';
import { validationSchema } from './policy-settings.validation';
import awsExports from '../../../../aws-exports';

const stripePromise = loadStripe(awsExports.stripeKey);

const endDateValue = (values) => {
  if (values.reinstateDate) {
    return values.reinstateDate;
  }
  if (values.cancel) {
    return values.cancellationDate;
  }
  return values.endDate;
};

const parseValues = (values) => ({
  ...values,
  autoRenew: values.renew,
  endDate: endDateValue(values)
});

const getPrimaryMortgage = ({ policy, primaryMortgageForBilling }) => {
  const emptyMortgage = {
    loanNumber: undefined,
    mortgageHolderAddress: undefined,
    mortgageHolderName: undefined,
    mortgageHolderId: undefined
  };

  if (policy?.defaultEscrowAccount?.mortgageHolderName) {
    return policy.defaultEscrowAccount;
  }
  return primaryMortgageForBilling || emptyMortgage;
};

const PolicySettingsContainer = ({
  classes,
  loadingPreview,
  onPreviewPolicy,
  accountId,
  store: {
    account: {
      policies: {
        policy: { segment, setChanged, policy, addHoldCard, billingDetails }
      }
    }
  },
  toast
}) => {
  const [state, setState] = useState(
    fromJS({
      cancelPolicy: false,
      modal: ''
    })
  );

  const handleCancellation = useCallback(
    (cancelPolicy, changed = true) => {
      setState(state.setIn(['cancelPolicy'], cancelPolicy).setIn(['changed'], changed));
    },
    [setState, state]
  );

  const handleAddHoldCard = useCallback(() => {
    setState(state.setIn(['modal'], 'addHoldCard'));
  }, [setState, state]);

  const closeModal = useCallback(() => {
    setState(state.setIn(['modal'], ''));
  }, [setState, state]);

  const handleChangePolicy = useCallback(
    (values) => {
      const parsed = parseValues(values);
      onPreviewPolicy(parsed);
    },
    [onPreviewPolicy]
  );

  const onHoldCardAddition = useCallback(
    (args) => {
      const { reason, policyId } = args;
      addHoldCard(reason, policyId)
        .then((res) => {
          track('Staff Add Hold Card', { args, res });
          toast.notify({
            type: 'success',
            message: 'The hold card was added successfully'
          });
        })
        .catch((res) => {
          Sentry.captureException(
            JSON.stringify({
              res,
              body: {
                reason,
                policyId
              }
            })
          );
        });
    },
    [addHoldCard, toast]
  );

  const originalEndDate = useMemo(() => {
    return getEndDate(policy.effectiveDate, policy.policyType, policy.state);
  }, [policy]);

  const session = useContext(AuthContext);

  const getDefaultAccounts = () => {
    // not all accounts will have a default so set it from activePaymentMethod
    let defaultBankAccount = policy.defaultBankAccount;
    let defaultCreditCard = policy.defaultCreditCard;
    const activeAccount = billingDetails?.activePaymentMethod;

    const activeBankAccount = activeAccount?.id?.startsWith('ba')
      ? billingDetails.activePaymentMethod
      : billingDetails?.allPaymentMethods?.find((account) => account.id.startsWith('ba'));
    const activeCreditCard = activeAccount?.id?.startsWith('card')
      ? billingDetails.activePaymentMethod
      : billingDetails?.allPaymentMethods?.find((account) => account.id.startsWith('card'));

    // in some cases, the active account in stripe and the default card get out of sync, use this opportunity to match them up
    if (activeBankAccount?.id !== policy.defaultBankAccount?.id) {
      defaultBankAccount = activeBankAccount || { id: undefined };
    }
    if (activeCreditCard?.id !== policy.defaultCreditCard?.id) {
      defaultCreditCard = activeCreditCard || { id: undefined };
    }
    return { defaultBankAccount, defaultCreditCard };
  };

  const { defaultBankAccount, defaultCreditCard } = getDefaultAccounts();
  const primaryMortgageForBilling = segment.home?.mortgageDetails?.find((mortgage) => mortgage?.primary);
  const primaryMortgage = getPrimaryMortgage({ policy, primaryMortgageForBilling });

  const { minDate, maxDate } = getStartMinMaxDate({
    policy,
    editCurrentSegment: true,
    currentSegment: policy.segments[0],
    canBackDate: session.canBackDate
  });

  return (
    <Formik
      initialValues={{
        ...policy,
        defaultEscrowAccount: primaryMortgage,
        renewalCreditPull: !!policy.renewalCreditPull,
        defaultBankAccount,
        defaultCreditCard
      }}
      validationSchema={() =>
        Yup.lazy((values) =>
          validationSchema({
            policyType: policy.policyType,
            values,
            minDate,
            maxDate,
            currentEffectiveDate: policy.effectiveDate
          })
        )
      }
      onSubmit={handleChangePolicy}
    >
      {({ handleSubmit, touched, values }) => {
        const { cancel, endDate, effectiveDate, state: policyState } = values;
        const cancelled = dateFromPolicy(endDate, policyState) < dateFromPolicy(originalEndDate, policyState);
        const changed = (session?.isService || session?.isSales) && Object.keys(touched).length > 0;

        if (changed || cancelled) {
          setChanged(true);
        } else {
          setChanged(false);
        }
        return (
          <Elements stripe={stripePromise}>
            <div className={changed || cancelled ? classes.changed : null}>
              <PolicySettings
                key={`settings-status-${state.get('cancelPolicy')}`}
                policy={values}
                originalEndDate={originalEndDate}
                cancelPolicy={state.get('cancelPolicy')}
                handleCancellation={handleCancellation}
                changed={changed}
                segment={segment}
                handleAddHoldCard={handleAddHoldCard}
                accountId={accountId}
                minDate={minDate}
                maxDate={maxDate}
              />
              {cancelled && session?.canReinstate ? (
                <Footer
                  title="Reinstate Policy"
                  buttonLabel={awsDateFormatter(new Date()) < policy.endDate ? 'Undo Cancellation' : 'Reinstate Policy'}
                  form={<ReinstateForm originalEndDate={originalEndDate} />}
                  loading={loadingPreview}
                  onClick={handleSubmit}
                />
              ) : (
                changed && (
                  <Footer
                    title="Save changes"
                    buttonLabel={cancelled || cancel ? 'Preview Policy Cancellation' : undefined}
                    form={
                      (cancelled || cancel) && (
                        <CancellationForm
                          endDate={conditionalEndDate({
                            endDate,
                            effectiveDate,
                            policyType: policy.policyType
                          })}
                          originalEndDate={originalEndDate}
                          effectiveDate={effectiveDate}
                        />
                      )
                    }
                    loading={loadingPreview}
                    onClick={handleSubmit}
                  />
                )
              )}
              {state.getIn(['modal']) === 'addHoldCard' && (
                <AddHoldCard
                  policy={policy}
                  onClose={closeModal}
                  onHoldCardAddition={onHoldCardAddition}
                  open={state.getIn(['modal']) === 'addHoldCard'}
                />
              )}
            </div>
          </Elements>
        );
      }}
    </Formik>
  );
};
PolicySettingsContainer.propTypes = {
  policy: PropTypes.shape({
    renewalCreditPull: PropTypes.bool,
    policyType: PropTypes.string.isRequired,
    effectiveDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    billingDayOfMonth: PropTypes.number.isRequired,
    autoRenew: PropTypes.bool,
    state: PropTypes.string.isRequired,
    term: PropTypes.number.isRequired,
    version: PropTypes.number.isRequired
  }).isRequired,
  classes: PropTypes.object.isRequired,
  loadingPreview: PropTypes.bool.isRequired,
  onPreviewPolicy: PropTypes.func.isRequired,
  toast: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired
};

export default flowRight(withToast, withStyles(styles), withStore)(PolicySettingsContainer);
