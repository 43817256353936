import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';

import awsConfig from './aws-exports';
import configureServices from './configure-services';
import './core/styles.css';
import App from './core/components/app';
import '@aws-amplify/ui/dist/style.css';

Amplify.configure(awsConfig);
configureServices();

ReactDOM.render(<App />, document.getElementById('root'));
