/* eslint-disable no-console */
import React, { memo, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  lookupsJson,
  courseDiscountTypeByStates,
  courseDiscountsAges,
  communityDriveAvailableStates,
  disableExcludeDriverStates
} from '@ourbranch/lookups';
import { Form, FastField, Field, getIn, useFormikContext } from 'formik';
import { compareAsc, subYears, parseISO, differenceInYears } from 'date-fns';
import { Grid, Button } from '@material-ui/core';
import classNames from 'classnames';
import { useObserver } from 'mobx-react';

import { Tooltip } from 'core/components/tooltip';
import { RESET_COMMUNITY_DRIVE_LOGIN } from 'core/store/offer-store/offer-queries';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import withDatePicker from 'core/components/with-date-picker';
import CollapsibleCard from 'core/components/collapsible-card';
import Checkbox from 'core/components/checkbox';
import RemoveButton from 'core/components/remove-button';
import SwapButton from 'core/components/swap-button';
import { useStore } from 'core/store/store.mobx';
import useStyles from './person.styles';
import excluded from 'core/assets/svg/ic-excluded.svg';

const fullTimeStudentAgeLimit = 23;

const drivingCourseLabel = {
  AVOIDANCE: 'Has this driver taken an Accident Avoidance Course?',
  SENIOR: 'Has this driver taken an Accident Prevention Course?',
  DEFENSIVE: 'Has this driver taken a Defensive Driving Course?',
  PREVENTION: 'Has this driver taken an Accident Prevention Course?'
};

const Driver = memo(
  ({
    index,
    fieldId: id,
    fieldName,
    item = {},
    removeFromList,
    onRemove,
    initialFold,
    onFold,
    disabled,
    swapPrimaryApplicant,
    enableApplicantSwap,
    fromPolicy
  }) => {
    const [resetDriveLogin] = useMutation(RESET_COMMUNITY_DRIVE_LOGIN);
    const classes = useStyles();
    const {
      offer: offerStore,
      account: {
        policies: { policy: policyStore }
      }
    } = useStore();
    const { setFieldValue: formikSetFieldValue, values } = useFormikContext();
    const isBix = offerStore?.offer?.quote?.isBix || policyStore?.policy?.isBix;
    const { cars, drivers } = values;

    const driverIsNotInStore = offerStore.isCoApplicantButtonDisabled(item.id, values);

    const coAppSelected = drivers.some((d) => {
      return d.isCoApplicant;
    });

    const canRemoveDriver = isBix ? drivers.length - 1 >= cars.length / 10 : drivers.length - 1 >= cars.length / 2.5;
    // if it's BIX the car to driver ratio is 10:1, if GSNIC policy car to driver ratio is 2.5:1

    const isPrimaryMarried = drivers.some((d) => {
      return d.isPrimary && d.maritalStatus === 'M';
    });

    // if co-app and married, and primary is married, then they are most likely married to each other
    const isSpouseOfPrimary = item.isCoApplicant && item.maritalStatus === 'M' && isPrimaryMarried;

    const state = fromPolicy ? policyStore?.geographicState : offerStore?.state;

    const today = new Date();
    const birthDate = new Date(item.dateOfBirth);
    const age = differenceInYears(today, birthDate);
    const drivingCourseDiscountType = courseDiscountTypeByStates[state];
    const canReceiveDrivingCourseDiscount = drivingCourseDiscountType && age >= courseDiscountsAges[state];

    const canBeFullTimeStudent =
      compareAsc(subYears(new Date(), fullTimeStudentAgeLimit), parseISO(item.dateOfBirth)) === -1 &&
      !item.isPrimary &&
      !isSpouseOfPrimary; // if 23 and under, and not primary, and not married to primary, then elligible for student discount

    const hasUDR = item?.autoViolations?.UDR >= 1;
    const showCommunityDrive =
      communityDriveAvailableStates[state] &&
      ((isBix && communityDriveAvailableStates[state].showForBix) ||
        (!isBix && communityDriveAvailableStates[state].showForNonBix));

    const resetCommunityDriveLogin = (driverId) => {
      resetDriveLogin({
        variables: {
          branchAccountId: String(policyStore?.policy?.id).substr(0, 9),
          driverId
        }
      });
    };

    useEffect(() => {
      if (!canBeFullTimeStudent || !item.fullTimeStudent) {
        formikSetFieldValue(`${fieldName}.fullTimeStudent`, false);
        formikSetFieldValue(`${fieldName}.goodStudent`, false);
        formikSetFieldValue(`${fieldName}.schoolName`, null);
        formikSetFieldValue(`${fieldName}.schoolLocation`, null);
      }
      if (!canReceiveDrivingCourseDiscount) {
        formikSetFieldValue(`${fieldName}.takenCourse`, false);
      }
    }, [fieldName, item.fullTimeStudent, canBeFullTimeStudent, canReceiveDrivingCourseDiscount, formikSetFieldValue]);

    const [driverExcluded, setDriverExcluded] = useState(false);

    useEffect(() => {
      setDriverExcluded(item.excludeDriver);
    }, [item.excludeDriver]);

    return useObserver(() => (
      <CollapsibleCard
        optionalHeaderClass={driverExcluded ? classes.driverExcluded : classes.driverIncluded}
        content={
          <>
            {driverExcluded && (
              <img
                src={excluded}
                alt="excluded"
                style={{
                  display: 'inline',
                  marginRight: 8
                }}
              />
            )}
            <Label
              type="infoSubtitle"
              style={{
                display: 'inline',
                marginRight: 8
              }}
            >{`${item.firstName} ${item.lastName}`}</Label>
            {item.isPrimary && <Label type="infoCardTitle">Applicant</Label>}
            {hasUDR && (
              <Label style={{ width: '100%' }} type="smallWarning">
                This driver has an unverifiable driving record
              </Label>
            )}
          </>
        }
        contentRight={
          item.isPrimary && enableApplicantSwap ? (
            <>
              <span
                className={classNames(classes.swapButtonHelperText, {
                  [classes.swapButtonDisabled]: offerStore.isSwapButtonDisabled
                })}
              >
                Swap Main and Co-Applicant
              </span>
              <SwapButton disabled={offerStore.isSwapButtonDisabled} mode="big" onClick={swapPrimaryApplicant} />
            </>
          ) : (
            <>
              {!item.isPrimary && (
                <Field name={`${fieldName}.isCoApplicant`}>
                  {({ form: { values, setFieldValue, setFieldTouched } }) => (
                    <Checkbox
                      id="primary"
                      mode="light"
                      label="Co Applicant?"
                      classes={{
                        container: classes.coApplicant
                      }}
                      value={!!getIn(values, `${fieldName}.isCoApplicant`)}
                      onChange={(e) => {
                        if (enableApplicantSwap) {
                          if (offerStore.drivers.get(item.id)) {
                            offerStore.drivers.get(item.id).toggleCoApplicantStatus();
                          }
                        }
                        setFieldValue(`${fieldName}.isCoApplicant`, e.target.checked);
                        setFieldTouched(id);
                      }}
                      disabled={
                        disabled || (coAppSelected && !item.isCoApplicant) || driverIsNotInStore || driverExcluded
                      }
                    />
                  )}
                </Field>
              )}
              <FastField name={`${fieldName}.excludeDriver`}>
                {({ form: { values, setFieldValue, setFieldTouched, touched, errors } }) => (
                  <Checkbox
                    id="primary"
                    mode="light"
                    label={`Exclude ${id.includes('driver') ? 'Driver' : 'Applicant'} `}
                    classes={{
                      container: classes.coApplicant,
                      helperText: classes.coApplicantHelperText
                    }}
                    className={classNames({
                      [classes.excludeDriverCheckboxDisabled]: disableExcludeDriverStates[state]
                    })}
                    error={touched[id] && !!getIn(errors, `${fieldName}.excludeDriver`)}
                    helperText={touched[id] ? getIn(errors, `${fieldName}.excludeDriver`) : undefined}
                    value={!!getIn(values, `${fieldName}.excludeDriver`)}
                    onChange={(e) => {
                      setFieldValue(`${fieldName}.excludeDriver`, e.target.checked);
                      setFieldTouched(id);
                      formikSetFieldValue('includeUmbrella', false);
                      formikSetFieldValue('umbrellaCoverage', null);
                    }}
                    disabled={
                      disabled ||
                      offerStore?.drivers?.get(item.id)?.excludeDriverDisabled ||
                      item.isCoApplicant ||
                      item.isPrimary ||
                      disableExcludeDriverStates[state]
                    }
                  />
                )}
              </FastField>
              {disableExcludeDriverStates[state] && (
                <Tooltip text={`${state} does not allow changing excluded driver status`} placement="top" />
              )}
              {item.isPrimary && !enableApplicantSwap && (
                <Tooltip
                  text={
                    'Cannot remove primary driver. To remove, please select a co-applicant, then select Swap Main and Co-Applicant'
                  }
                  placement="top"
                />
              )}
              {!item.isPrimary && !canRemoveDriver && (
                <Tooltip
                  text={`The Car-to-Driver ratio for ${
                    isBix ? 'BIX policies is 10 : 1.' : 'GSNIC policies is 2.5 : 1.'
                  } To remove a driver, please remove some vehicles from the policy`}
                  placement="top"
                />
              )}
              <RemoveButton
                disabled={disabled || item.isPrimary || !canRemoveDriver || driverExcluded}
                mode="big"
                onClick={() => onRemove(index, removeFromList)}
              />
            </>
          )
        }
        initialOpen={initialFold || item.initialOpen}
        onChange={(val) => onFold(index, val)}
      >
        <div className={classNames(classes.container, classes.driverContainer)}>
          <Form key={`driver-form-${disabled || item.excludeDriver}`} disabled={disabled || item.excludeDriver}>
            <Grid container justify="space-around" alignItems="flex-start" spacing={4}>
              <FormField
                id={`${fieldName}.firstName`}
                name={`${fieldName}.firstName`}
                type="string"
                label="First Name"
                mode="light"
                xs={3}
              />
              <FormField
                id={`${fieldName}.middleName`}
                name={`${fieldName}.middleName`}
                type="string"
                label="Middle Name"
                mode="light"
                xs={3}
              />
              <FormField
                id={`${fieldName}.lastName`}
                name={`${fieldName}.lastName`}
                type="string"
                label="Last Name"
                mode="light"
                xs={3}
              />
              <FormField
                id={`${fieldName}.dateOfBirth`}
                name={`${fieldName}.dateOfBirth`}
                type="date"
                label="Date of Birth"
                mode="light"
                xs={3}
              />
              <FormField
                id={`${fieldName}.suffix`}
                name={`${fieldName}.suffix`}
                type="string"
                label="Suffix"
                mode="light"
                xs={2}
              />
              <FormField
                id={`${fieldName}.gender`}
                name={`${fieldName}.gender`}
                type="select"
                label="Gender"
                mode="light"
                options={lookupsJson.gender}
                xs={2}
              />
              <FormField
                id={`${fieldName}.maritalStatus`}
                name={`${fieldName}.maritalStatus`}
                type="select"
                label="Marital Status"
                mode="light"
                options={
                  fromPolicy
                    ? lookupsJson.maritalStatus
                    : lookupsJson.maritalStatus.filter((option) => {
                        return option.id !== 'W';
                      })
                }
                xs={2}
              />
              <FormField
                id={`${fieldName}.highestEducation`}
                name={`${fieldName}.highestEducation`}
                type="select"
                label="Highest Education Achieved"
                mode="light"
                options={lookupsJson.highestEducation}
                xs={6}
              />
              <FormField
                id={`${fieldName}.driversLicenseState`}
                name={`${fieldName}.driversLicenseState`}
                type="autocomplete"
                label="License State"
                mode="light"
                xs={3}
                options={lookupsJson.usStates}
                stateSelectForm
              />
              <FormField
                id={`${fieldName}.driversLicenseNumber`}
                name={`${fieldName}.driversLicenseNumber`}
                type="string"
                label="License Number"
                mode="light"
                xs={3}
              />
              <FormField
                id={`${fieldName}.ageFirstLicensed`}
                name={`${fieldName}.ageFirstLicensed`}
                type="string"
                label="First Licensed at"
                mode="light"
                xs={3}
              />
              <FormField
                id={`${fieldName}.addDate`}
                name={`${fieldName}.addDate`}
                type="date"
                label="Covered Since"
                mode="light"
                xs={3}
              />
              {canBeFullTimeStudent && (
                <FormField
                  id={`${fieldName}.fullTimeStudent`}
                  name={`${fieldName}.fullTimeStudent`}
                  type="switch"
                  label="Full Time Student"
                  mode="light"
                  xs={12}
                />
              )}
              {canBeFullTimeStudent && item.fullTimeStudent && (
                <>
                  <Grid container item alignItems="flex-end" spacing={4}>
                    <FormField
                      id={`${fieldName}.schoolName`}
                      name={`${fieldName}.schoolName`}
                      type="string"
                      label="School Name"
                      mode="light"
                      xs={8}
                    />
                    <FormField
                      id={`${fieldName}.goodStudent`}
                      name={`${fieldName}.goodStudent`}
                      type="checkbox"
                      label="Good Student"
                      mode="light"
                      xs={4}
                    />
                  </Grid>
                  <FormField
                    id={`${fieldName}.schoolLocation.address`}
                    name={`${fieldName}.schoolLocation.address`}
                    type="string"
                    label="School Address"
                    mode="light"
                    xs={8}
                  />
                  <FormField
                    id={`${fieldName}.schoolLocation.address2`}
                    name={`${fieldName}.schoolLocation.address2`}
                    type="string"
                    label="Address 2"
                    mode="light"
                    xs={4}
                  />
                  <FormField
                    id={`${fieldName}.schoolLocation.city`}
                    name={`${fieldName}.schoolLocation.city`}
                    type="string"
                    label="City"
                    mode="light"
                    xs={4}
                  />
                  <FormField
                    id={`${fieldName}.schoolLocation.state`}
                    name={`${fieldName}.schoolLocation.state`}
                    type="autocomplete"
                    label="State"
                    mode="light"
                    options={lookupsJson.usStates}
                    xs={4}
                    stateSelectForm
                  />
                  <FormField
                    id={`${fieldName}.schoolLocation.zip`}
                    name={`${fieldName}.schoolLocation.zip`}
                    type="string"
                    label="Zip Code"
                    mode="light"
                    xs={4}
                  />
                </>
              )}
              {hasUDR && (
                <Grid item xs={12}>
                  <Field name={`${fieldName}.recheckDrivingRecord`}>
                    {({ form: { setFieldValue, setFieldTouched } }) => (
                      <Checkbox
                        id="recheckDrivingRecord"
                        mode="light"
                        label="Re-check for driving record"
                        value={offerStore.drivers.get(item.id)?.willRecheckDrivingRecord}
                        onChange={(e) => {
                          // eslint-disable-next-line no-unused-expressions
                          offerStore.drivers.get(item.id)?.toggleWillRecheckDrivingRecord();
                          setFieldValue(`${fieldName}.recheckDrivingRecord`, e.target.checked);
                          setFieldTouched(id);
                        }}
                      />
                    )}
                  </Field>
                </Grid>
              )}
              {canReceiveDrivingCourseDiscount && (
                <FormField
                  id={`${fieldName}.takenCourse`}
                  name={`${fieldName}.takenCourse`}
                  type="checkbox"
                  label={drivingCourseLabel[drivingCourseDiscountType]}
                  mode="light"
                  xs={12}
                />
              )}
              {showCommunityDrive && (
                <Grid container item spacing={4} alignItems={'center'}>
                  <FormField
                    id={`${fieldName}.communityDriveParticipation`}
                    name={`${fieldName}.communityDriveParticipation`}
                    type="checkbox"
                    label="Community Drive Participation Discount"
                    mode="light"
                    xs={6}
                  />
                  {item.communityDriveParticipation && fromPolicy && (
                    <Button
                      xs={4}
                      color="secondary"
                      variant="text"
                      onClick={(e) => {
                        resetCommunityDriveLogin(item.id);
                      }}
                    >
                      Reset Login
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          </Form>
        </div>
        {(item.isPrimary || item.isCoApplicant) && item.insuranceScore && (
          <Grid container alignItems="center" justify="flex-end" className={classes.cardFooter}>
            <Grid container item xs={3} direction="column" alignItems="flex-end">
              <Label type="infoLabel">Insurance Score</Label>
              <Label type="infoValue">
                {Number(item.insuranceScore?.autoTotal?.toFixed(1) || item.insuranceScore?.total?.toFixed(1))}
              </Label>
            </Grid>
            <Button
              disabled
              onClick={console.log}
              color="secondary"
              variant="contained"
              className={classes.recalculateButton}
            >
              Recalculate Score
            </Button>
          </Grid>
        )}
      </CollapsibleCard>
    ));
  }
);

Driver.propTypes = {
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  item: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    middleName: PropTypes.string,
    lastName: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
    addDate: PropTypes.string,
    ageFirstLicensed: PropTypes.number,
    driversLicenseNumber: PropTypes.string,
    driversLicenseState: PropTypes.string,
    fullTimeStudent: PropTypes.bool,
    gender: PropTypes.string,
    goodStudent: PropTypes.bool,
    highestEducation: PropTypes.string,
    isCoApplicant: PropTypes.bool,
    isPrimary: PropTypes.bool,
    maritalStatus: PropTypes.string,
    schoolLocation: PropTypes.object,
    schoolName: PropTypes.string,
    suffix: PropTypes.string
  }).isRequired,
  fieldId: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  removeFromList: PropTypes.func.isRequired,
  initialFold: PropTypes.bool.isRequired,
  onFold: PropTypes.func.isRequired,
  swapPrimaryApplicant: PropTypes.func.isRequired,
  enableApplicantSwap: PropTypes.bool.isRequired,
  fromPolicy: PropTypes.bool
};

Driver.defaultProps = {
  disabled: false,
  fromPolicy: false
};

export default withDatePicker(Driver);
