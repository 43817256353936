import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import uuidv4 from 'uuid/v4';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { Button } from '@material-ui/core';

import Form from 'core/components/form';
import useStyles from './add-form.styles';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required!'),
  lastName: Yup.string().required('Last Name is required'),
  dateOfBirth: Yup.string().required('Date of birth is required!')
});

function AddForm({ onAdd, label, disabled, addContent }) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        id: uuidv4(),
        firstName: '',
        lastName: '',
        dateOfBirth: new Date(),
        isCoApplicant: false,
        isPrimary: false
      }}
      onSubmit={onAdd}
      validationSchema={validationSchema}
    >
      {() => (
        <FormikForm className={classes.container}>
          {addContent}
          <Form
            disabled={disabled}
            data={[
              {
                component: Grid,
                props: {
                  key: 'container',
                  container: true,
                  justify: 'space-around',
                  alignItems: 'center',
                  spacing: 2
                },
                children: [
                  {
                    id: 'firstName',
                    type: 'string',
                    label: 'First Name',
                    mode: 'light',
                    width: 3
                  },
                  {
                    id: 'lastName',
                    type: 'string',
                    label: 'Last Name',
                    mode: 'light',
                    width: 3
                  },
                  {
                    id: 'dateOfBirth',
                    name: 'dateOfBirth',
                    type: 'date',
                    label: 'Date of Birth',
                    mode: 'light',
                    width: 3
                  },
                  {
                    component: Grid,
                    props: {
                      item: true,
                      xs: 3,
                      key: 'btn'
                    },
                    children: [
                      {
                        type: 'submit',
                        component: Button,
                        props: {
                          key: 'addButton',
                          mode: 'big',
                          children: label || 'Add People',
                          className: classes.button,
                          type: 'submit',
                          variant: 'contained',
                          color: 'secondary'
                        }
                      }
                    ]
                  }
                ]
              }
            ]}
          />
        </FormikForm>
      )}
    </Formik>
  );
}

AddForm.propTypes = {
  onAdd: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  addContent: PropTypes.node
};

AddForm.defaultProps = {
  disabled: false,
  addContent: null
};

export default AddForm;
