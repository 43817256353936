import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  styledText: {
    display: 'block',
    lineHeight: 1.3
  },
  button: {
    backgroundColor: theme.colorPalette.green_30,
    color: theme.colorPalette.white_10
  }
}));

export default useStyles;
