const _uniqBy = require('lodash.uniqby');
const _assignWith = require('lodash.assignwith');

const customizer = (newVal, srcVal, key) => {
  if (srcVal === undefined || srcVal === null) {
    return newVal;
  }
  if (newVal && (key === 'home' || key === 'auto' || key === 'global')) {
    return Object.assign(newVal, srcVal);
  }
};

function mergeAndRemoveDuplicates(policyDetailsIn, segment) {
  const policyDetails = policyDetailsIn;
  _assignWith(policyDetails, segment, customizer);

  // now remove duplicates from the arrays where things could have been removed:
  if (policyDetails.cars) {
    policyDetails.cars = _uniqBy(policyDetails.cars, 'VIN');
  }
  if (policyDetails.drivers) {
    policyDetails.drivers = _uniqBy(policyDetails.drivers, 'id');
  }
  if (policyDetails.people) {
    policyDetails.people = _uniqBy(policyDetails.people, 'id');
  }
  if (policyDetails.trailers) {
    policyDetails.trailers = _uniqBy(policyDetails.trailers, 'VIN');
  }
  if (
    segment.scheduledPersonalProperty &&
    segment.scheduledPersonalProperty.items &&
    !segment.scheduledPersonalProperty.items.length
  ) {
    policyDetails.scheduledPersonalProperty.items = [];
  }

  return policyDetails;
}
module.exports = {
  mergeAndRemoveDuplicates
};
