import PropTypes from 'prop-types';
import React, { memo } from 'react';
import flowRight from 'lodash-es/flowRight';

import People from 'common/components/people';
import AddDriver from 'common/components/people/add-form';
import Driver from 'common/components/people/driver';
import Discounts from 'common/components/discounts';
import PipMI from 'common/components/pip';
import { withDisabledState } from '../../../customer/disabled-context';
import Coverage from './auto-coverage';
import Cars from './cars';
import Trailers from './trailers';
import Renters from './renters';
import PriorCoverage from './prior-coverage';

function AutoPolicy({ disabled, policy, state }) {
  return (
    <>
      <People
        disabled={disabled}
        id="drivers"
        person={Driver}
        addForm={AddDriver}
        addLabel="Add Driver"
        title="Drivers"
        singular="driver"
        plural="drivers"
        fromPolicy
      />
      <Cars disabled={disabled} fromPolicy />
      <Trailers disabled={disabled} policy={policy} />
      <Coverage disabled={disabled} policy={policy} />
      <PriorCoverage />
      <Renters disabled={disabled} />
      <Discounts disabled={disabled} />
      {state === 'MI' && <PipMI fromPolicy />}
    </>
  );
}

AutoPolicy.propTypes = {
  disabled: PropTypes.bool,
  policy: PropTypes.object.isRequired
};

AutoPolicy.defaultProps = {
  disabled: false
};

export default flowRight(withDisabledState, memo)(AutoPolicy);
