import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent } from '@material-ui/core';

import { ActionButton } from '../action-button';
import closeIcon from '../../assets/svg/x.svg';
import styles from './base-dialog.styles';

const BaseDialog = ({ children, size, open, classes, onClose, ...props }) => {
  return (
    <Dialog
      maxWidth={size}
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.container
      }}
      {...props}
    >
      <DialogContent className={classes.mContent}>
        {onClose && (
          <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
        )}
        {children}
      </DialogContent>
    </Dialog>
  );
};

BaseDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['xs', 'md', 'sm', 'lg', 'xl']),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

BaseDialog.defaultProps = {
  size: 'lg',
  onClose: undefined
};

export default withStyles(styles)(BaseDialog);
