import gql from 'graphql-tag';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import { Auth as AwsAuth, Hub } from 'aws-amplify';

import { withToast } from 'core/components/toast';
import { AuthContext } from './auth.context';

const allStates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];

function getUserFromPayload(payload) {
  return {
    username: payload['cognito:username'],
    groups: payload['cognito:groups'],
    email: payload.email,
    phoneNumber: payload.phone_number,
    affinityCode: payload['custom:affinity_code'],
    licenseNumber: payload['custom:license_number']
  };
}

function getAffinityGroupsFromString(affinityGroupsString) {
  try {
    return JSON.parse(affinityGroupsString);
  } catch (e) {
    return [];
  }
}

function permissions(payload) {
  if (!payload['cognito:groups'] || !payload['cognito:groups'].length) {
    return {
      isService: false,
      isSales: false,
      canEdit: false
    };
  }
  const isService = payload['cognito:groups'].includes('Service');
  const isSales =
    payload['cognito:groups'].includes('InternalSales') || payload['cognito:groups'].includes('ExternalSales');
  const isInternalSales = payload['cognito:groups'].includes('InternalSales');
  const isExternalSales = payload['cognito:groups'].includes('ExternalSales');
  const isTeamLeader = payload['cognito:groups'].includes('TeamLeaders');
  const canReinstate = payload['cognito:groups'].includes('Reinstatement');
  const canSeeRatePriceDetails = payload['cognito:groups'].includes('RatePriceDetails');
  const canScrubIncidents = payload['cognito:groups'].includes('ScrubIncidents');
  const canModifyAffinityAndLeadSource = payload['cognito:groups'].includes('ModifyAffinityCodes');
  const canBackDate = payload['cognito:groups'].includes('CanBackDate');
  const canModifyBillingId = payload['cognito:groups'].includes('ModifyBillingID');
  const canViewClarionDoorData = payload['cognito:groups'].includes('ViewClarionDoorData');
  const canAddCarsManually = payload['cognito:groups'].includes('AddCarsManually');
  const canAddRemoveInstallments = payload['cognito:groups'].includes('CanAddRemoveInstallments');
  const canAutoRenew = payload['cognito:groups'].includes('CanAutoRenew');
  const canAddHoldCards = payload['cognito:groups'].includes('CanAddHoldCards');

  const isAgency = !!getAffinityGroupsFromString(payload['custom:affinity_groups']).length;

  return {
    canQuote: isTeamLeader || isSales || isService,
    canEdit: isTeamLeader || isSales || isService,
    canBind: isTeamLeader || isSales || isService,
    isTeamLeader,
    isSales,
    isService,
    isAgency,
    canReinstate,
    canSeeRatePriceDetails,
    isInternalSales,
    isExternalSales,
    canScrubIncidents,
    canModifyAffinityAndLeadSource,
    canBackDate: canBackDate && !isAgency,
    canModifyBillingId: canModifyBillingId && !isAgency,
    canViewClarionDoorData,
    canAddCarsManually,
    canAddRemoveInstallments,
    canAutoRenew,
    canAddHoldCards
  };
}

const defaultState = {
  user: null,
  isLoggedIn: false,
  logout: () => AwsAuth.signOut(),
  changePassword: (oldPassword, newPassword) => {
    return AwsAuth.currentAuthenticatedUser().then((user) => {
      return AwsAuth.changePassword(user, oldPassword, newPassword);
    });
  },
  loading: true,
  userPreferences: null
};

const GET_USER_PREFERENCES = gql`
  query {
    getUserPreferences {
      username
      algoliaKey
    }
  }
`;

class Auth extends React.Component {
  static contextType = MobXProviderContext; // This sets `this.context` to be the MobX store.
  static propTypes = {
    children: PropTypes.node.isRequired,
    client: PropTypes.object.isRequired,
    toast: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    Hub.listen('auth', (capsule) => {
      const { channel, payload } = capsule;
      if (channel === 'auth') {
        const { event, data } = payload;
        if (event === 'signIn') {
          this.setAuthStateFromSession(data.signInUserSession.idToken.payload, data.signInUserSession.idToken.jwtToken);
        } else if (event === 'signOut') {
          window.location.reload();
        }
        if (event === 'signIn_failure') {
          const spaceInUsernameError =
            'Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+';
          if (capsule.payload.data.message.includes(spaceInUsernameError)) {
            props.toast.notify({
              type: 'error',
              message: 'Double check if the username has a space. If so, please remove and try logging in again.'
            });
          }
        }
      }
    });
    this.state = defaultState;
  }

  async componentDidMount() {
    try {
      const session = await AwsAuth.currentSession();
      await this.setAuthStateFromSession(session.idToken.payload, session.getIdToken().getJwtToken());
    } catch (e) {
      // eslint-disable-next-line
      console.log('Error getting session from AwsAuth: ', e);
      this.setState(defaultState);
    }
  }

  async getUserPreferences(payload) {
    const { client } = this.props;

    try {
      // if user has no algolia key OR user is agent, get algolia key fom userpreferences table
      // for internal users, we use the cognito algolia key, but for agencies we need to use the key in userpreferences dynamo table
      if (
        !payload['custom:algolia_api_key'] ||
        !!getAffinityGroupsFromString(payload['custom:affinity_groups']).length
      ) {
        const {
          data: { getUserPreferences }
        } = await client.query({ query: GET_USER_PREFERENCES });

        payload['custom:algolia_api_key'] = getUserPreferences.algoliaKey;
      }
      return { algoliaKey: payload['custom:algolia_api_key'] };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Try deploying dynamoDB to have a valid algolia key');
      return { algoliaKey: undefined };
    }
  }

  async setAuthStateFromSession(payload, token) {
    const userPreferences = await this.getUserPreferences(payload);
    if (this.context.store) {
      this.context.store.userPreferences.setUserPreferences(userPreferences);
    }

    this.setState({
      user: getUserFromPayload(payload),
      isLoggedIn: true,
      ...permissions(payload),
      token,
      loading: false,
      allowedStates: payload['custom:allowed_states'] ? JSON.parse(payload['custom:allowed_states']) : allStates,
      userPreferences
    });
  }

  render() {
    const { children } = this.props;

    return <AuthContext.Provider value={this.state}>{children}</AuthContext.Provider>;
  }
}

export default withToast(Auth);
