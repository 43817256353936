import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import { AuthContext } from 'core/components/auth';
import { Tooltip } from 'core/components/tooltip';
import DatePicker from 'core/components/date-picker';
import withDatePicker from 'core/components/with-date-picker';
import Field from 'core/components/form/form.v2';
import styles from './segment-form.styles';

const SegmentForm = memo(({ onChange, startDate: from, minDate, maxDate, error, classes }) => {
  const session = useContext(AuthContext);
  const enableUnderwritingChanges = session.isService || session.isTeamLeader;
  return (
    <Grid container alignItems="flex-end" className={classes.container}>
      <Grid item>
        <DatePicker
          name="startDate"
          onChange={onChange}
          value={from}
          error={error}
          helperText={error}
          mode="light"
          disableFuture={false}
          label="Start date"
          className={classes.datePicker}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Grid>
      <Grid item>
        <Field
          type="checkbox"
          id="underwritingChanges"
          name="underwritingChanges"
          mode="light"
          label="Underwriting Changes"
          disabled={!enableUnderwritingChanges}
        />
        {!enableUnderwritingChanges && (
          <Tooltip
            text="Need Service or Team Leader group permissions to enable underwriting changes"
            placement="top"
          />
        )}
      </Grid>
    </Grid>
  );
});

SegmentForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  minDate: PropTypes.object.isRequired,
  error: PropTypes.string,
  classes: PropTypes.object.isRequired
};
SegmentForm.defaultProps = {
  error: undefined
};

export default withDatePicker(withStyles(styles)(SegmentForm));
