import React from 'react';
import PropTypes from 'prop-types';

import { AutoOffer } from 'common/components/auto';
import useStyles from './auto-details.styles';

const AutoDetails = ({ offer }) => {
  const classes = useStyles();
  // we have to pass offer here to know whether we are bix or not
  return (
    <div className={classes.container}>
      <AutoOffer offer={offer} />
    </div>
  );
};

AutoDetails.propTypes = {
  offer: PropTypes.object.isRequired
};
export default AutoDetails;
