import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Label } from 'core';
import { Tooltip } from './tooltip';
import useStyles from './label-tooltip.styles';

const LabelTooltip = memo(({ label, tooltip, children, placement, mode }) => {
  const classes = useStyles();

  return (
    // only using the 'container' className if the LabelToolTip has children i.e. is used as a container
    <div className={children && classes.container}>
      <div className={classes.header}>
        <Label type={mode === 'light' ? 'formLabelLight' : 'formLabel'} className={classes.label}>
          {label} <Tooltip text={tooltip.onHoverText} label={tooltip.label} placement={placement} />
        </Label>
      </div>
      {children}
    </div>
  );
});

LabelTooltip.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.object.isRequired,
  children: PropTypes.node,
  placement: PropTypes.string,
  mode: PropTypes.string
};

LabelTooltip.defaultProps = {
  children: null,
  label: '',
  placement: 'top',
  mode: 'dark'
};

export default LabelTooltip;
