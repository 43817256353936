const styles = () => ({
  tabs: {
    paddingBottom: 32
  },
  tab: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  }
});

export default styles;
