import { Formik } from 'formik';
import { useObserver, Observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Loading } from 'core';
import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store';
import PriceDetails from './price-details';
import PaymentDetails from './payment-details';
import awsExports from '../../../../aws-exports';
import Footer from '../footer';

const stripePromise = loadStripe(awsExports.stripeKey);

const PaymentTab = ({ loadingPreview, handleChangePolicy }) => {
  const session = useContext(AuthContext);
  const {
    account: {
      policies: { policy: store }
    }
  } = useStore();

  // TODO: Completely remove Formik?
  return useObserver(() => {
    const onSubmit = (values) => {
      handleChangePolicy({
        ...store.policy,
        fees: values.fees,
        billingAddInstallments: values.billingAddInstallments,
        billingHoldUntil: values.billingHoldUntil,
        skipImmediateBillOrRefund: values.skipImmediateBillOrRefund
      });
      store.setChanged(false);
    };

    if (store.loading) {
      return <Loading type="secondary" />;
    }
    return (
      <Elements stripe={stripePromise}>
        <Formik
          initialValues={{
            billingAddInstallments: 0,
            billingHoldUntil: store.policy ? store.policy.billingHoldUntil : undefined,
            fees: store.policy.fees
          }}
          onSubmit={onSubmit}
        >
          {({ values, submitForm }) => (
            <>
              <PriceDetails />
              <PaymentDetails
                billingAddInstallments={values.billingAddInstallments}
                billingHoldUntil={values.billingHoldUntil}
              />
              <Observer>
                {/* NOTE: when you are accessing observable properties inside a component callback,
                  you must use the Observer component or the properties will not observed.
                  Even though they appear in this component tree,
                  they are technically in a different function see https://github.com/gobranch/branch/pull/5225 */}
                {() => {
                  return (
                    session.isService &&
                    store.changed && (
                      <Footer title="Save changes" loadingPreview={loadingPreview} onClick={submitForm} />
                    )
                  );
                }}
              </Observer>
            </>
          )}
        </Formik>
      </Elements>
    );
  });
};

PaymentTab.propTypes = {
  loadingPreview: PropTypes.bool.isRequired,
  handleChangePolicy: PropTypes.func.isRequired
};

export default PaymentTab;
