export const messages = {
  // Only rejections
  10000: {
    title: 'Catch-all Verisk.',
    message:
      'This is the catch-all "Verisk FCRA" error and should not happen; please report to joe@ourbranch.com on how to replicate this error if you see it.'
  },
  10009: {
    title: 'Total Property Claims',
    message:
      'We do not allow 3 or more total property claims on a home that is less than 50 years old in the past 3 years.'
  },
  10010: {
    title: 'Same-Peril Property Claims',
    message:
      'We do not allow 2 or more claims of the same type of peril (e.g., Fire, Wind, Water) on a home in the past 3 years.'
  },
  10011: {
    title: 'Older Home Property Claims',
    message:
      'We do not allow 2 or more total property claims on a home that is greater than or equal to 50 years old in the past 3 years.'
  },
  10012: {
    title: 'Bite Claims',
    message: 'We do not allow any animal bite claims.'
  },
  10013: {
    title: 'Suspended License (MVR)',
    message: 'We do not allow any record of a suspended license in the past 59 months; this one came from a Verisk MVR.'
  },
  10014: {
    title: 'Disqualifying Charge (MVR)',
    message:
      'We do not allow any record of a "disqualifying charge" in the past 35 months: DWI, Reckless Driving, Vehicular Homicide, Failure to File Required Report, Fleeing from Police, Suspended License, Drag Racing. This one came from a Verisk MVR.'
  },
  10015: {
    title: 'Total Auto Incidents (MVR)',
    message:
      "We do not allow 3 or more total incidents across all drivers on a policy in the past 35 months. In this case, each incident was an at-fault accident, a not-at-fault accident, or a minor moving violation in which the driver is not excluded on our policy that we found on the driver's claims history or in the driver's Verisk MVR."
  },
  10016: {
    title: 'Driver Auto Incidents (MVR)',
    message:
      "We have limitations on the number of incidents for any driver on a policy in the past 35 months. In this case, each incident was an at-fault accident, a not-at-fault accident, or a minor moving violation in which the driver is not excluded on our policy that we found on the driver's claims history or in the driver's Verisk MVR."
  },
  20000: {
    title: 'Catch-all Transunion FCRA Issue',
    message:
      'This is the catch-all "Transunion FCRA" error and should not happen; please report to joe@ourbranch.com on how to replicate this error if you see it.'
  },
  20001: {
    title: 'Suspended License (DR)',
    message:
      'We do not allow any record of a suspended license in the past 59 months; this one came from Transunion DriverRisk.'
  },
  20002: {
    title: 'Disqualifying Charge (DR)',
    message:
      'We do not allow any record of a "disqualifying charge" in the past 35 months: DWI, Reckless Driving, Vehicular Homicide, Failure to File Required Report, Fleeing from Police, Suspended License, Drag Racing. This one came from Transunion DriverRisk.'
  },
  20003: {
    title: 'Total Auto Incidents (DR)',
    message:
      "We do not allow 3 or more total incidents across all drivers on a policy in the past 35 months. In this case, each incident was an at-fault accident, a not-at-fault accident, or a minor moving violation in which the driver is not excluded on our policy that we found on the driver's claims history or in the driver's Transunion DriverRisk."
  },
  20004: {
    title: 'Driver Auto Incidents (MVR)',
    message:
      "We have limitations on the number of incidents for any driver on a policy in the past 35 months. In this case, each incident was an at-fault accident, a not-at-fault accident, or a minor moving violation in which the driver is not excluded on our policy that we found on the driver's claims history or in the driver's Transunion DriverRisk."
  },
  50000: {
    title: 'Catch-all Issue',
    message:
      'This is the catch-all "Non-FCRA" error and should not happen; please report to joe@ourbranch.com on how to replicate this error if you see it.'
  },
  50001: {
    title: 'No Cars',
    message:
      "Couldn't find any cars in either Coverage Verifier or the Vehicle Registration database, and so we need to have one VIN supplied so that we can provide a price for auto."
  },
  50002: {
    title: 'Too Many Square Feet',
    message:
      'We allow up to 6,000 square feet in homes that we insure, and this home exceeds that. If you submit a smaller value, we will use it.'
  },
  50003: {
    title: 'Too Many Occupants',
    message:
      'We allow up to 12 occupants in homes that we insure, and this home exceeds that. If you submit a smaller value, we will use it.'
  },
  50004: {
    title: 'Car Ratio',
    message: 'We allow up to 2.5 cars per driver on a policy.'
  },
  50005: {
    title: 'Home Too Old',
    message: 'We do not allow homes built prior to 1900, although we can grant underwriting exceptions.'
  },
  50006: {
    title: 'Could Not Calculate Home Replacement Cost',
    message:
      'We tried to pull data on this home from three different sources (Verisk tax assessor data, Attom tax assessor data, and MLS data) and did not receive enough information to calculate a replacement cost. If you resubmit with sufficient home characteristics to calculate a replacement cost, we will do so.'
  },
  50007: {
    title: 'No Prior Auto Insurance',
    message:
      'We do not allow customers with no auto insurance for the past 31 days, unless there was no need, which needs to be granted as an underwriting exception.'
  },
  50008: {
    title: 'Exterior Insulation and Finish System',
    message:
      'We do not allow homes with Exterior Insulation and Finish System (EIFS) as their primary exterior wall type; we identify this from Verisk 360Value returning an exterior wall type of SSTU_BL.'
  },
  50009: {
    title: 'Stilts',
    message:
      'We do not allow homes built on stilts; we identify this from Verisk 360Value returning a foundation type of either E or ES.'
  },
  50010: {
    title: 'Wood Roof',
    message: 'We do not allow homes with wood roofs; if this is incorrect, you can resubmit with the proper roof type.'
  },
  50011: {
    title: 'Replacement Cost Too Low',
    message:
      'Our minimum replacement cost for coverage is $50,000. If you think it is being calculated incorrectly, specify all of the home characteristics and resubmit.'
  },
  50012: {
    title: 'Replacement Cost Too High',
    message:
      'Our maximum replacement cost for coverage is $800,000 for self-serve online, and $1,000,000 for origination within the staff system. If you think it is being calculated incorrectly, specify all of the home characteristics and resubmit.'
  },
  50013: {
    title: 'Fire Protection Class 10',
    message:
      'We do not allow homes with a fire protection class of 10. If you think this is in error, we will need proof of proximity to fire department and fire hydrant.'
  },
  50014: {
    title: 'No Fire Protection Class',
    message:
      'We did not receive a fire protection class for this home. This should not happen; if it does, please let joe@ourbranch.com know how to replicate.'
  },
  50015: {
    title: 'Too Many Drivers',
    message: 'We allow a maximum of 10 drivers per policy.'
  },
  50016: {
    title: 'Too Many Cars',
    message: 'We allow a maximum of 10 cars per policy.'
  },
  50017: {
    title: 'Test Case',
    message:
      'This is a test case rejection and should never been seen in production. If you do see this, please let joe@ourbranch.com know how to replicate.'
  },
  50018: {
    title: 'Ineligible Coastal Location',
    message:
      "This error is a fatal and non-recoverable error because the home is in a location that we aren't writing insurance for."
  },
  50019: {
    title: 'No Fireline Score Retrieved',
    message: "We didn't get a Fireline score from Verisk; requote from start to try and get one."
  },
  50020: {
    title: 'Fireline Score is Too High',
    message: 'We received a Fireline score (wildfire hazard risk) that is too high for us to write.'
  },
  50021: {
    title: 'Fireline Score with Fire Protection Class is Too High',
    message:
      'We received a fireline score (wildfire hazard risk) that is too high for us to write with the fire protection class on this property.'
  },
  50022: {
    title: 'No Distance-to-Coast value found',
    message: 'We do not have a distance-to-coast value for this property, and we need one to run underwriting rules.'
  },
  50023: {
    title: 'Ineligible Distance-to-Coast value found',
    message: 'This property is too close to tidal water for us to underwrite it.'
  },
  // These errors prevent from quoting
  4000: {
    message: 'We have received an error. Here are the error details: [error_details]'
  },
  4001: {
    message: 'We have received an error. Here are the error details: [error_details]'
  },
  4002: {
    message:
      "Unfortunately, we are experiencing an issue with one of our data vendors and cannot provide you a quote at this time. Please check back later, or provide your email address and we'll let you know when your quote is ready"
  },
  4003: {
    message: 'Unfortunately, we are not able to write insurance policies in that state yet'
  },
  5001: {
    message:
      "We couldn't find you at that address. Please provide your most recent address prior to the one you already entered."
  },
  5002: {
    message: 'We will need your date of birth to properly identify you and complete your quote'
  },
  5003: {
    message:
      'We were not able to find information on your current Auto insurance provider. Please enter your current coverage limits and continuous years that you have had auto insurance.'
  },
  5004: {
    message: 'We were not able to find some information on your home. Please enter the details here.'
  },
  5005: {
    message: 'Please enter a VIN to receive an auto quote.'
  },
  5006: {
    message: 'Unfortunately, we cannot offer you insurance for this vehicle'
  },
  5007: {
    message: 'Unfortunately, we cannot offer you a price for insurance at this time.'
  },
  5008: {
    message: 'Unfortunately, we are not able to write insurance policies in that state yet.'
  },
  5009: {
    message:
      'Unfortunately, we are not able to write insurance on that vehicle. Please enter a different VIN, or continue to the next step.'
  },
  5010: {
    message:
      "That VIN doesn't match any vehicle in our database. Please check for typos and try again, or try to add a different vehicle."
  },
  5011: {
    message: 'The address is in a zip code in which we currently have a moratorium.'
  },
  5012: {
    message: "We need a driver's license number to proceed."
  },
  5013: {
    message: 'We do not allow PO Box addresses.'
  },
  5014: {
    message: 'Returned minimum premium; likely a rater error. Cannot proceed.'
  },
  5015: {
    message: "The primary driver has a UDR and no driver's license; please put in a driver's license."
  },
  0: {
    message: 'We have received an error. Here are the error details: [error_details]'
  }
};
