import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { AuthContext } from 'core/components/auth';
import { Label } from 'core/components/label';
import styles from './search-header.styles';

function SearchHeader({ location, children, classes, policiesNumHits, customerNumHits, offerNumHits }) {
  const { canEdit } = useContext(AuthContext);

  return (
    <Grid container justify="space-between" spacing={1} className={classes.container}>
      <Grid item className={classes.title}>
        <Label
          type="title"
          style={{
            marginBottom: 25
          }}
        >
          What are you looking for?
        </Label>
        <div>
          {canEdit && (
            <Button
              color="primary"
              component={Link}
              to="/search/offers"
              className={classNames(classes.button, {
                [classes.selected]: location.pathname.includes('offers')
              })}
              data-cy="offers"
            >
              {offerNumHits > 0 ? `An Offer (${offerNumHits})` : 'An Offer'}
            </Button>
          )}
          <Button
            color="primary"
            component={Link}
            to="/search/customers"
            className={classNames(classes.button, {
              [classes.selected]: location.pathname.includes('customer')
            })}
            data-cy="customers"
          >
            {customerNumHits > 0 ? `A Customer (${customerNumHits})` : 'A Customer'}
          </Button>
          <Button
            color="primary"
            component={Link}
            to="/search/policies"
            className={classNames(classes.button, {
              [classes.selected]: location.pathname.includes('policies')
            })}
            data-cy="policies"
          >
            {policiesNumHits > 0 ? `A Policy (${policiesNumHits})` : 'A Policy'}
          </Button>
        </div>
      </Grid>
      <Grid item className={classes.content}>
        {children}
      </Grid>
    </Grid>
  );
}

SearchHeader.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node
};

SearchHeader.defaultProps = {
  children: null
};

export default flowRight(withStyles(styles), withRouter)(SearchHeader);
