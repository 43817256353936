import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { lookupsJson, connectedHomePromoDetails, noConnectedHomeProviderSpecialOfferByState } from '@ourbranch/lookups';

import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import { LabelTooltip } from 'core/components/label-tooltip';
import { Button } from 'core/components/button';
import { connectedHomeTooltipHoverTexts as TooltipHoverTexts } from 'core/helpers/constants';
import { useStore } from 'core/store';
import {
  useNewMonitoringServiceHelpers,
  useConnectedHomeSecurityProviders,
  useConnectedHomeSecurityProviderHelpers
} from './helpers/hooks';
import { OfferNotifications } from '../../../../offer/components/notifications';
import { Disclaimers } from 'core/helpers/disclaimers';
import Notification from 'core/helpers/notifications';
import useStyles from '../connected-home-settings/settings.styles';

const AdvancedConnectedHome = ({
  setHomeSecurityPartnerCustomerType,
  isAdvancedConnectedHome,
  comingFromModal = false
}) => {
  const classes = useStyles();
  const {
    offer: offerStore,
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();
  const { values } = useFormikContext();

  const geographicState = offerStore.state || policyStore.geographicState;
  const {
    isSigningUpForHomeSecurity,
    canAffinityAllowNewCustomerSignUp,
    canProviderAllowNewCustomerSignup,
    homeSecurityCustomerType
  } = useNewMonitoringServiceHelpers();
  let securityProviderOptions = useConnectedHomeSecurityProviders({ isAdvancedConnectedHome });
  const { disableSecurityProvider } = useConnectedHomeSecurityProviderHelpers({ isAdvancedConnectedHome });

  const signupOptions = [
    { value: 'existing', label: 'Currently owned' },
    { value: 'new', label: 'New sign up' }
  ];

  if (noConnectedHomeProviderSpecialOfferByState[geographicState] && homeSecurityCustomerType === 'new') {
    securityProviderOptions = securityProviderOptions.filter(
      (provider) => !noConnectedHomeProviderSpecialOfferByState[geographicState].includes(provider.value)
    );
  }

  const newSignUpNotification = offerStore?.notifications?.find(
    (notification) => notification === Notification.Offer.NewConnectedHomeSignUpCustomer
  );

  const showDeviceFields =
    !isSigningUpForHomeSecurity || (isSigningUpForHomeSecurity && values?.connectedHome?.providerName !== undefined);

  const Disclaimer = Disclaimers[values.connectedHome.providerName];

  const onApply = () => {
    offerStore.setShowConnectedHomeModal(false);
  };
  const fieldType = comingFromModal ? 'light' : 'dark';

  return (
    <>
      <Grid container justify="flex-start" alignItems="center">
        <Grid item container alignItems="center">
          <Grid item>
            <Label type={comingFromModal ? null : 'smallSubtitleLight'}>Monitoring system:</Label>
          </Grid>

          {canAffinityAllowNewCustomerSignUp && (
            <FormField
              type="radio"
              name="connectedHome.homeSecurityPartnerCustomerTypeOption"
              mode={fieldType}
              value={homeSecurityCustomerType}
              onChange={setHomeSecurityPartnerCustomerType}
              disabled={!canProviderAllowNewCustomerSignup}
              fast={false}
              options={signupOptions}
              className={classes.radioButtons}
            />
          )}
        </Grid>

        {isSigningUpForHomeSecurity && newSignUpNotification && <OfferNotifications type="tertiary" />}

        {isSigningUpForHomeSecurity && (
          <Grid container item xs={12}>
            <FormField
              type="select"
              name="connectedHome.providerName"
              mode={fieldType}
              options={securityProviderOptions}
              fast={false}
              disabled={disableSecurityProvider}
              label="Featured Security Provider"
            />
          </Grid>
        )}

        {!isSigningUpForHomeSecurity && (
          <>
            <Grid item xs={8}>
              <FormField
                type={isAdvancedConnectedHome ? 'autocomplete' : 'select'}
                name="connectedHome.providerName"
                mode={fieldType}
                options={securityProviderOptions}
                allowUserInput={isAdvancedConnectedHome}
                fast={false}
                disabled={disableSecurityProvider}
                label="Security Provider"
                xs={12}
              />
            </Grid>

            <Grid item xs={4}>
              <LabelTooltip
                label="Monitoring Type"
                mode={fieldType}
                tooltip={{ label: 'More Info', onHoverText: TooltipHoverTexts.monitoringTypes.bix }}
              >
                <FormField
                  type="value"
                  name="connectedHome.monitored"
                  mode={fieldType}
                  fast={false}
                  formatter={(value) => {
                    const found = isAdvancedConnectedHome
                      ? lookupsJson.monitoringTypesAdvancedConnectedHome.find(({ id }) => id === value)
                      : lookupsJson.monitoringTypesRegularConnectedHome.find(({ id }) => id === value);
                    return found ? found.value : '';
                  }}
                />
              </LabelTooltip>
            </Grid>
          </>
        )}

        {isSigningUpForHomeSecurity && values.connectedHome.providerName && (
          <>
            <Grid space={8}>
              {connectedHomePromoDetails[values.connectedHome.providerName]?.promoItems && (
                <>
                  <Label className={classes.spacing} type={comingFromModal ? 'label' : 'discountPromo'}>
                    Promo Details
                  </Label>
                  <ul>
                    {connectedHomePromoDetails[values.connectedHome.providerName]?.promoItems.map((details) => (
                      <li className={classes.listBullets} key={details}>
                        <Label type={comingFromModal ? 'infoValue' : 'discount'}>{details}</Label>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {values.connectedHome.providerName !== 'Kangaroo' && (
                <FormField
                  type="checkbox"
                  name="connectedHome.agreeToTerms"
                  mode={fieldType}
                  label={
                    <Disclaimer type={comingFromModal ? 'infoValue' : 'discount'} className={classes.privacyLink} />
                  }
                  xs={12}
                  fast={false}
                  className={classes.agreementCheckbox}
                />
              )}
            </Grid>
          </>
        )}

        {showDeviceFields && (
          <>
            <Grid container justify="space-between">
              <Grid item xs={4}>
                <LabelTooltip
                  label="Moisture Devices"
                  mode={fieldType}
                  tooltip={{ label: 'More Info', onHoverText: TooltipHoverTexts.moisture.bix }}
                >
                  <FormField type="numeric" name="connectedHome.moistureDevices" mode={fieldType} fast={false} />
                </LabelTooltip>
              </Grid>
              <Grid item xs={4}>
                <LabelTooltip
                  label="Smoke Detecting Devices"
                  mode={fieldType}
                  tooltip={{ label: 'More Info', onHoverText: TooltipHoverTexts.smoke.bix }}
                >
                  <FormField type="numeric" name="connectedHome.smokeDetectingDevices" mode={fieldType} fast={false} />
                </LabelTooltip>
              </Grid>

              <Grid item xs={4}>
                <LabelTooltip
                  label="Theft Prevention Devices"
                  mode={fieldType}
                  tooltip={{ label: 'More Info', onHoverText: TooltipHoverTexts.theft.bix }}
                >
                  <FormField type="numeric" name="connectedHome.theftPreventionDevices" mode={fieldType} fast={false} />
                </LabelTooltip>
              </Grid>

              {!isAdvancedConnectedHome && (
                <Grid item xs={4}>
                  <LabelTooltip
                    label="Motion Detecting Devices"
                    mode={fieldType}
                    tooltip={{ label: 'More Info', onHoverText: TooltipHoverTexts.motion.nonbix }}
                  >
                    <FormField
                      type="numeric"
                      name="connectedHome.motionDetectingDevices"
                      mode={fieldType}
                      fast={false}
                    />
                  </LabelTooltip>
                </Grid>
              )}
            </Grid>

            <FormField
              className={classes.autoWaterShutoff}
              type="checkbox"
              name="connectedHome.autoWaterShutoffDevices"
              mode={fieldType}
              label="Auto Water Shutoff Device"
              tooltipText={TooltipHoverTexts.water.bix}
              tooltipLabel="More Info"
              xs={6}
              fast={false}
            />
          </>
        )}
      </Grid>
      {comingFromModal && (
        <Button
          className={classes.applyDiscountBtn}
          mode="big"
          variant="contained"
          color="secondary"
          paddingTop="10px"
          onClick={onApply}
        >
          Apply Discount
        </Button>
      )}
    </>
  );
};

AdvancedConnectedHome.propTypes = {
  setHomeSecurityPartnerCustomerType: PropTypes.func.isRequired,
  isAdvancedConnectedHome: PropTypes.bool.isRequired
};

export default AdvancedConnectedHome;
