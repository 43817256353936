import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';

import { useStore } from 'core/store';
import BaseDialog from 'core/components/base-dialog/base-dialog';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import CollapsibleCard from 'core/components/collapsible-card';
import useStyles from './factors-impacting-price.styles';

const TABS = {
  HOME: 'HOME CHARACTERISTICS',
  PEOPLE: 'PEOPLE',
  CARS: 'CARS'
};

const getDefaultOpenTab = ({ factors, showHomeFactors, showPeopleFactors, showCarsFactors }) => {
  if (factors.homeFactors && showHomeFactors) {
    return TABS.HOME;
  }

  if (factors.peopleFactors && showPeopleFactors) {
    return TABS.PEOPLE;
  }

  if (factors.carFactors && showCarsFactors) {
    return TABS.CARS;
  }
};

const FactorsImpactingPrice = observer(
  ({ open, onClose, factors, showHomeFactors, showPeopleFactors, showCarsFactors }) => {
    const classes = useStyles();
    const [tab, setTab] = useState(getDefaultOpenTab({ factors, showHomeFactors, showPeopleFactors, showCarsFactors }));
    const { offer: store } = useStore();

    const { offerId } = useParams();
    const { protocol, host } = window.location;
    const detailsLink = `${protocol}//${host}/staff/offer/${offerId}/details`;

    const redirectToCompleteOfferDetails = () => {
      window.open(detailsLink, '_blank');
    };

    const howManyTabsShowing = [showHomeFactors, showPeopleFactors, showCarsFactors].filter((showTab) => showTab)
      .length;

    return (
      <BaseDialog open={open} onClose={onClose}>
        <Label type="greenBig" className={classes.bottomSpacing}>
          Factors Impacting Price
        </Label>
        {howManyTabsShowing > 1 && (
          <Tabs
            value={tab}
            variant="fullWidth"
            onChange={(_, newTab) => setTab(newTab)}
            className={classes.bottomSpacing}
          >
            {showHomeFactors && <Tab value={TABS.HOME} label="Home Characteristics" className={classes.tab} />}
            {showPeopleFactors && <Tab value={TABS.PEOPLE} label="People" className={classes.tab} />}
            {showCarsFactors && <Tab value={TABS.CARS} label="Cars" className={classes.tab} />}
          </Tabs>
        )}

        {/* ----- HOME FACTORS ----- */}
        {tab === TABS.HOME && factors?.homeFactors?.length > 0 && (
          <Grid className={classes.homeFactorRows}>
            {factors?.homeFactors?.map((homeFactor, index) => (
              <Grid
                item
                key={`home-factor-${index}`}
                className={classnames(classes.factorRow, { [classes.divider]: factors.homeFactors.length > 1 })}
              >
                {homeFactor}
              </Grid>
            ))}
          </Grid>
        )}

        {/* ----- PEOPLE FACTORS ----- */}
        {tab === TABS.PEOPLE &&
          factors?.peopleFactors &&
          Object.keys(factors.peopleFactors).map((id) => {
            const person = store.offer.quote.drivers.find((person) => person.id === id);

            return (
              <CollapsibleCard
                key={id}
                content={
                  <>
                    <Label
                      type="infoSubtitle"
                      className={classes.personCardLabel}
                    >{`${person.firstName} ${person.lastName}`}</Label>
                    {person.isPrimary && <Label type="infoCardTitle">Applicant</Label>}
                    {person.isCoApplicant && <Label type="infoCardTitle">Co Applicant</Label>}
                  </>
                }
              >
                <Grid className={classes.collapseCardRows}>
                  {factors.peopleFactors[id].map((personFactor, index) => (
                    <Grid
                      item
                      key={`person-factor-${index}`}
                      className={classnames(classes.collapseCardRow, {
                        [classes.divider]: factors.peopleFactors[id].length > 1
                      })}
                    >
                      {personFactor}
                    </Grid>
                  ))}
                </Grid>
              </CollapsibleCard>
            );
          })}

        {/* ----- CARS FACTORS ----- */}
        {tab === TABS.CARS &&
          factors?.carFactors &&
          Object.keys(factors.carFactors).map((VIN) => {
            const car = store.offer.quote.cars.find((c) => c.VIN === VIN);

            return (
              <CollapsibleCard
                key={VIN}
                content={
                  <Label
                    type="infoSubtitle"
                    className={classes.personCardLabel}
                  >{`${car.make} ${car.model} ${car.year}`}</Label>
                }
              >
                <Grid className={classes.collapseCardRows}>
                  {factors.carFactors[VIN].map((carFactor, index) => (
                    <Grid
                      item
                      key={`car-factor-${index}`}
                      className={classnames(classes.collapseCardRow, {
                        [classes.divider]: factors.carFactors[VIN].length > 1
                      })}
                    >
                      {carFactor}
                    </Grid>
                  ))}
                </Grid>
              </CollapsibleCard>
            );
          })}

        <Grid container justify="flex-end" spacing={2} className={classes.buttons}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={redirectToCompleteOfferDetails}>
              Complete Offer Details
            </Button>
          </Grid>
        </Grid>
      </BaseDialog>
    );
  }
);

export default FactorsImpactingPrice;
