import React from 'react';
import { Grid, Divider } from '@material-ui/core';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import ValueField from 'core/components/value-field';
import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import { claimStatusMap, labelStyleMap } from './constants';
import useStyles from './claims.styles';

const ClaimField = ({ label, value, xs }) => (
  <Grid item xs={xs || 4}>
    <ValueField label={label} value={value} />
  </Grid>
);

const ClaimDetail = observer(function ClaimDetail({ claim, open, hideClaimDetail }) {
  const classes = useStyles();
  return (
    <BaseDialog open={open} onClose={hideClaimDetail} size="md">
      <Label type="subtitle">Claim {claim.claimNumber}</Label>
      <Grid container flex="row" alignItems="baseline">
        <Grid item>
          <Label type="infoLabel">{'Status '}</Label>
        </Grid>
        <Grid item>
          <Label type={`${labelStyleMap[claimStatusMap[claim.status]]}Medium`}>{claimStatusMap[claim.status]}</Label>
        </Grid>
      </Grid>
      <div className={classes.spacing}>
        <Grid container flex="row" spacing={2}>
          <Grid container item flex="row" xs={12}>
            <ClaimField label="Date of Loss" value={claim.lossDate} />
            <ClaimField label="TPA Name" value={claim.TPA} />
          </Grid>
          <ClaimField label="Coverage" value={claim.coverage} />
          <ClaimField label="Cause of Loss" value={claim.cause} />
          <ClaimField
            label="Claim Type"
            value={claim.claimType || (/home\b/i.test(claim.policyID) ? 'HOME' : 'AUTO')}
          />
        </Grid>
      </div>
      <Divider variant="middle" />
      <div className={classes.spacing}>
        <Grid container flex="row" spacing={2}>
          <Grid item xs={12}>
            <Label type="formSubTitleLight">Adjuster</Label>
          </Grid>
          <ClaimField label="Name" value={claim.adjusterName} xs={6} />
          <ClaimField label="Phone Number" value={claim.adjusterPhone} xs={6} />
          <ClaimField label="Email" value={claim.adjusterEmail} xs={6} />
        </Grid>
      </div>
    </BaseDialog>
  );
});

ClaimDetail.propTypes = {
  claim: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  hideClaimDetail: PropTypes.func.isRequired
};

export default ClaimDetail;
