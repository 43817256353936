import { getIn, useFormikContext } from 'formik';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import AutoBreakdownPreview from 'customer/components/policy/auto-breakdown-preview/preview';
import { withDisabledState } from '../../../customer/disabled-context';
import { rentersPolicyAvailable } from '@ourbranch/lookups';
import Coverage from './auto-coverage';
import Cars from './cars';
import Trailers from './trailers';
import Renters from './renters';
import PriorCoverage from './prior-coverage';

function AutoOffer({ disabled, offer }) {
  const { values } = useFormikContext();

  return (
    <>
      <Cars disabled={disabled} fromOffer />
      <Trailers disabled={disabled} offer={offer} />
      <Coverage disabled={disabled} />
      <PriorCoverage />
      {rentersPolicyAvailable[offer.state] && offer.selectedOption !== 'AR' && <Renters disabled={disabled} />}
      <AutoBreakdownPreview
        offer={offer?.offer}
        cars={getIn(values, 'cars')}
        autoCoverage={getIn(values, 'autoCoverage')}
      />
    </>
  );
}

AutoOffer.propTypes = {
  disabled: PropTypes.bool,
  offer: PropTypes.object.isRequired
};

AutoOffer.defaultProps = {
  disabled: false
};

export default flowRight(withDisabledState, memo)(AutoOffer);
