import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { paymentMethod, paymentType } from '@ourbranch/lookups';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash-es';
import _range from 'lodash-es/range';
import { connect } from 'formik';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { AuthContext } from 'core/components/auth';
import { FormField } from 'core/components/form';
import { withStore } from 'core/store';
import { FormikCtxPropTypes } from 'core/components/form/propTypes';
import { CreditCardForm } from '../payment-tab/credit-card-form';
import styles from '../policy-settings/policy-settings.styles';

const daysOfMonth = _range(1, 29).map((day) => ({ id: day, value: day.toString() }));
const maskingDotsText = '\u25CF\u25CF\u25CF\u25CF';

const CreditCard = ({
  classes,
  formik,
  frequencyOptions,
  store: {
    account: {
      id: accountId,
      policies: { policy }
    }
  }
}) => {
  const stripe = useStripe();
  const [modify, setModify] = useState(false);
  const [sendingCard, setSendingCard] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const elements = useElements();
  const { isService, canModifyBillingId } = useContext(AuthContext);

  const { values, setFieldValue, setFieldTouched } = formik;

  const handleChangeCardDetails = async () => {
    try {
      setSendingCard(true);

      const cardElement = elements.getElement(CardElement);
      const res = await stripe.createToken(cardElement, { name: 'change_payment_method' });

      if (res.token) {
        const { id } = policy;

        const card = await policy.changeCreditCard({ accountId, policyId: id, stripeToken: res.token.id });
        if (card.data) {
          setModify(false);
          setSendingCard(false);
          // @TODO replace credit card
          policy.updateCreditCard(id, { ...card.data.creditCard });
        }
      }
    } catch (error) {}
  };

  const toggleModifyCreditCardForm = () => {
    setModify(!modify);
    setAddCard(false);
  };

  const toggleAddCreditCard = () => {
    setAddCard(!addCard);
    setModify(false);
  };

  const allPaymentMethods = policy.billingDetails.allPaymentMethods || [];

  const creditCardsOptions = allPaymentMethods
    .filter((method) => method.id.startsWith('card'))
    .map((card) => {
      return {
        id: card.id || '',
        value: `${card.brand} ${maskingDotsText}${card.last4}`
      };
    });

  const addCreditCard = async () => {
    setSendingCard(true);
    const cardElement = elements.getElement(CardElement);
    const { token } = await stripe.createToken(cardElement, { name: `${values.firstName}` });
    if (token) {
      const cardDetails = { stripeToken: token.id, ...token.card };
      policy.addCreditCard(cardDetails); // updates the options
      setFieldValue('defaultCreditCard', cardDetails);
      setSendingCard(false);
      setAddCard(false);
      setFieldTouched('defualtCreditCard', true);
    }
  };
  // switch from escrow frequency
  useEffect(() => {
    if (formik.values.paymentType === paymentType.Escrow) {
      setFieldValue('paymentType', paymentType.Monthly);
    }
  });

  return (
    <>
      <FormField
        name="paymentType"
        type="select"
        label="Payment Frequency"
        mode="dark"
        xs={4}
        options={frequencyOptions}
      />
      <FormField
        name="billingDayOfMonth"
        type="select"
        label="Billing Day"
        mode="dark"
        icon="calendar"
        options={daysOfMonth}
        xs={4}
        disabled={!isService}
      />
      <Grid container justify="flex-start" className={classes.billingDetailsContainer} spacing={4}>
        <FormField
          name="defaultCreditCard.id"
          xs={4}
          type="select"
          mode="dark"
          options={creditCardsOptions}
          label="Credit Card Number"
          disabled={!creditCardsOptions.length}
          fast={false}
        />
        <FormField
          name="renewalPaymentType"
          type="select"
          label="Renewal Payment Frequency"
          mode="dark"
          xs={4}
          options={frequencyOptions}
        />
        {canModifyBillingId && (
          <FormField name="stripeCustomerId" xs={4} type="string" mode="dark" label="Billing ID" />
        )}
      </Grid>

      {!modify && !addCard && values.paymentMethod === paymentMethod.CreditCard && (
        <Grid item container xs={12} key="addNewCredit Card">
          {!!creditCardsOptions.length && (
            <Button onClick={toggleModifyCreditCardForm} style={{ paddingLeft: 0 }} variant="text" color="secondary">
              Modify Card
            </Button>
          )}
          <Button onClick={toggleAddCreditCard} variant="text" color="secondary">
            Add new card
          </Button>
        </Grid>
      )}

      {modify && !addCard && (
        <Grid container className={classes.billingDetailsContainer}>
          <CreditCardForm loading={sendingCard} onSubmit={handleChangeCardDetails} close={toggleModifyCreditCardForm} />
        </Grid>
      )}
      {addCard && !modify && (
        <Grid container className={classes.billingDetailsContainer}>
          <CreditCardForm loading={sendingCard} onSubmit={addCreditCard} close={toggleAddCreditCard} buttonType="add" />
        </Grid>
      )}
    </>
  );
};

CreditCard.propTypes = {
  classes: PropTypes.object.isRequired,
  formik: FormikCtxPropTypes.isRequired,
  store: PropTypes.object.isRequired,
  frequencyOptions: PropTypes.array.isRequired
};

export default flowRight(withStyles(styles), connect, withStore)(CreditCard);
