import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 720,
    margin: '0 auto',
    paddingTop: 30
  },
  discountcontainer: {
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.page__background_primary,
    overflow: 'hidden'
  },
  subtitle: {
    marginBottom: 8,
    padding: '0 32px'
  },
  subtitleWithoutPadding: {
    marginBottom: 8
  },
  datesContainer: {
    marginBottom: 32,
    padding: '0 32px'
  },
  sectionWithLine: {
    borderTop: `1px solid ${theme.colorPalette.white_40_op_70}`,
    padding: '30px 32px',
    backgroundColor: theme.colors.card_background_secondary
  },
  checkbox: {
    paddingTop: 15
  },
  toggle: {
    minHeight: 10,
    padding: 0,
    margin: 0
  },
  loadingBar: {
    height: 2
  },
  checkboxTooltip: {
    paddingLeft: 25
  }
}));

export default useStyles;
