function addCoveragesToPremiumBreakdown(premiumBreakdown, { coverages: coveragesToAdd, from: sourceCoverages }) {
  // save all coverages in list, summed globally
  for (const coverage of coveragesToAdd) {
    premiumBreakdown[coverage.toLowerCase()] = sourceCoverages
      .filter((c) => c.type === coverage)
      .reduce((acc, curr) => acc + curr.amount, 0);
  }

  // save iteration-based coverages
  for (const coverageObj of sourceCoverages) {
    if (coverageObj.iteration && coveragesToAdd.includes(coverageObj.type.toUpperCase())) {
      premiumBreakdown[`it${coverageObj.iteration}-${coverageObj.type.toLowerCase()}`] = coverageObj.amount;
    }
  }
} // addCoveragesToPremiumBreakdown

module.exports = {
  addCoveragesToPremiumBreakdown
};
