import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useObserver } from 'mobx-react';
import { Grid } from '@material-ui/core';
import queryString from 'query-string';
import copy from 'copy-to-clipboard';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { preBindMVRStates } from '@ourbranch/lookups';

import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { withToast } from 'core/components/toast';
import { useStore } from 'core/store/store.mobx';

import useStyles from './footer.styles';
import { ConfirmCheckout } from '../checkout';

const Footer = ({ toast, showValidationErrorsModal, setShowValidationErrorsModal }) => {
  const classes = useStyles();
  const history = useHistory();

  const { handleSubmit, dirty, values, validateForm, setErrors, setTouched } = useFormikContext();
  const { offer: store } = useStore();
  const { drivers } = store.offer.quote;

  const footerClasses = classNames({
    [classes.footerContainer]: true,
    [classes.footerAlert]: store.isStale
  });

  const needToUpdateMVRs =
    !dirty && preBindMVRStates.includes(store.state) && drivers.some((driver) => driver.postBindMVR);

  const footerLabel = needToUpdateMVRs
    ? 'This price does not reflect rating on auto incidents or violations'
    : dirty
    ? 'Update Offer'
    : 'Your Offer is Ready';

  const onCheckout = useCallback(async () => {
    const checkoutErrors = await validateForm();
    if (Object.keys(checkoutErrors).length > 0) {
      setErrors(checkoutErrors);
      setTouched(checkoutErrors);
      setShowValidationErrorsModal(true);
    } else {
      history.push(`/offer/${store.offer.id}/${values.selectedOption}/checkout`);
    }
  }, [history, setErrors, setTouched, store.offer.id, values, setShowValidationErrorsModal, validateForm]);

  const onUpdate = useCallback(async () => {
    const updateErrors = await validateForm();
    if (Object.keys(updateErrors).length > 0) {
      setErrors(updateErrors);
      setTouched(updateErrors);
      setShowValidationErrorsModal(true);
    } else if (needToUpdateMVRs) {
      await store.recalculateQuoteToCluster(
        store.offer.id,
        { getMVRs: true, selectedOption: values.selectedOption },
        history
      );
    } else {
      handleSubmit();
    }
  }, [
    handleSubmit,
    setShowValidationErrorsModal,
    validateForm,
    setErrors,
    setTouched,
    needToUpdateMVRs,
    history,
    store,
    values.selectedOption
  ]);

  const copyLink = () => {
    const { protocol, host, search } = window.location;

    const queryParamsMap = queryString.parse(search);
    const selectedOption = values.selectedOption || queryParamsMap.option;

    let policyType;
    if (selectedOption === 'HB' || selectedOption === 'AB') {
      // if we're giving a bundled price, link to the bundle
      policyType = 'HA';
    } else {
      policyType = selectedOption;
    }

    const link = store.offer.clusterId
      ? `${protocol}//${host}/review?cid=${store.offer.clusterId}&planType=${store.offer.code}&policyType=${policyType}&internal=true`
      : `${protocol}//${host}/quote/detail/${store.offer.id}`;
    const copied = copy(link);
    if (copied) {
      toast.notify({
        type: 'success',
        message: 'The link is copied to your clipboard. This link is for customer use ONLY.'
      });
    } else {
      toast.notify({
        type: 'error',
        message: `We could not copy the link, but here it is: ${link}`
      });
    }
  };

  return useObserver(() => {
    return (
      <div className={classes.floatingContainer}>
        <div className={footerClasses}>
          <Grid container alignItems="center" className={classes.footerContent}>
            {!store.isStale && (
              <>
                <div>
                  <Label className={classes.title} type="subtitle">
                    {footerLabel}
                  </Label>
                  {!dirty && (
                    <Button className={classes.copyLink} color="secondary" variant="text" onClick={copyLink}>
                      Copy Quote Link
                    </Button>
                  )}
                </div>
                <>
                  {needToUpdateMVRs ? (
                    <Button
                      onClick={onUpdate}
                      disabled={store.loading}
                      loading={store.loading}
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                    >
                      <Label className={classes.btnLabel}>Check for Auto Incidents</Label>
                    </Button>
                  ) : !dirty ? (
                    <ConfirmCheckout
                      onCheckout={onCheckout}
                      showValidationModal={showValidationErrorsModal}
                      validateCheckout={validateForm}
                      CheckOutAction={({ onClick }) => (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.btn}
                          disabled={store.loading}
                          onClick={onClick}
                        >
                          <Label className={classes.btnLabel}>Continue to Checkout</Label>
                        </Button>
                      )}
                    />
                  ) : (
                    <Button
                      onClick={onUpdate}
                      disabled={store.loading}
                      loading={store.loading}
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                    >
                      <Label className={classes.btnLabel}>Update Offer Price</Label>
                    </Button>
                  )}
                </>
              </>
            )}
            {store.isStale && (
              <>
                <Label type="whiteMedium">Offer Out of Date!</Label>
                <Button
                  onClick={onUpdate}
                  disabled={store.loading}
                  loading={store.loading}
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                >
                  <Label className={classes.btnLabel}>Update To Purchase</Label>
                </Button>
              </>
            )}
          </Grid>
        </div>
      </div>
    );
  });
};

export default withToast(Footer);
