import React, { useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { Grid } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';

import { withStore } from 'core/store';
import { AuthContext } from 'core/components/auth/auth.context';
import { CheckoutStatus } from 'core/store/offer-store';
import branchSpinnerJson from 'core/assets/lottie/branch-spinner.min.json';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import useStyles from './purchased.styles';

const Purchased = ({ offerId, store: { offer: offerStore } }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { isAgency } = useContext(AuthContext);

  useEffect(() => {
    const onLoadStartPollingForPolicy = () => {
      offerStore.pollFetchPolicyFromOffer(offerId);
    };
    onLoadStartPollingForPolicy();
  }, [offerId, offerStore]);

  const onGoToCustomer = useCallback(() => {
    history.push(`/customer/${offerStore.purchasedAccountId}`);
  }, [history, offerStore.purchasedAccountId]);

  const onGoToWelcomeEmail = useCallback(() => {
    window.open(`https://fly.customer.io/env/92447/people/${offerStore.purchasedAccountId}/sent`, '_blank');
  }, [offerStore.purchasedAccountId]);

  return (
    <>
      {offerStore.status === CheckoutStatus.FetchingPolicyFromOffer && (
        <Grid container direction="column" justify="center" alignItems="center" className={classes.container}>
          <Label type="title">The purchase is completed!</Label>
          <div className={classes.spinner}>
            <Lottie options={{ animationData: branchSpinnerJson, loop: true, autoplay: true }} />
          </div>

          <Label type="greenSmall">Waiting for the policies to be created...</Label>
        </Grid>
      )}
      {offerStore.status === CheckoutStatus.FetchSuccessful && (
        <Grid container direction="column" justify="center" alignItems="center" className={classes.container}>
          <Label type="title">The purchase is completed!</Label>
          <Label type="greenSmall" className={classes.subtitle}>
            Policies are created. We sent the application and account credentials to {location.state.email}
          </Label>
          <Button variant="contained" color="primary" className={classes.button} onClick={onGoToCustomer}>
            Go to customer page
          </Button>
          {!isAgency && (
            <Button variant="contained" color="secondary" className={classes.button} onClick={onGoToWelcomeEmail}>
              Preview welcome email
            </Button>
          )}
        </Grid>
      )}
      {offerStore.status === CheckoutStatus.FetchFailed && (
        <Grid container direction="column" justify="center" alignItems="center" className={classes.container}>
          <Label type="title">The purchase is completed!</Label>
          <Label type="greenSmall" className={classes.subtitle}>
            Something is taking longer than expected. We will send the application and account credentials to{' '}
            {location.state.email} when the policies are done being created.
          </Label>
        </Grid>
      )}
    </>
  );
};

Purchased.propTypes = {
  offerId: PropTypes.string.isRequired,
  store: PropTypes.object.isRequired
};

export default withStore(Purchased);
