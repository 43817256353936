import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  searchDialog: {
    minWidth: 720,
    minHeight: 290,
    padding: 0,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary
  },
  searchFooter: {
    height: 125
  },
  linkButton: {
    backgroundColor: theme.colorPalette.orange_10,
    color: theme.colors.white,
    width: 296
  },
  buttonContainer: {
    marginRight: 48
  },
  searchHeader: {
    padding: '28px 0 0 48px'
  },
  searchBar: {
    padding: '24px 48px'
  },
  searchSubtitle: {
    paddingTop: 12
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  }
}));

export default useStyles;
