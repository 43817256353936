import React, { useContext, useCallback } from 'react';
import Script from 'react-load-script';

import { AuthContext } from 'core/components/auth';
import { initializeWidget } from './zendesk-chat-helpers';

const ZendeskChat = () => {
  const session = useContext(AuthContext);
  const onLoadWidget = useCallback(() => {
    initializeWidget();
    window.zE('webWidget', 'prefill', {
      name: { value: session?.user?.username },
      email: { value: session?.user?.email },
      phone: { value: session?.user?.phoneNumber }
    });

    window.zESettings = {
      webWidget: {
        contactForm: {
          fields: [
            { id: 360020869092, prefill: { '*': session?.user?.username } },
            { id: 360017036492, prefill: { '*': session?.user?.email } },
            { id: 360017056671, prefill: { '*': session?.user?.phoneNumber } }
          ]
        },
        chat: {
          title: {
            '*': 'Agency Support Chat'
          },
          departments: {
            enabled: ['Agency Support'],
            select: 'Agency Support'
          }
        }
      }
    };
  }, [session]);

  if (!session.isAgency) {
    return null;
  }

  return (
    <Script
      url="https://static.zdassets.com/ekr/snippet.js?key=31588225-3a1a-4b8e-a386-416122d2462a"
      attributes={{ id: 'ze-snippet' }}
      onLoad={onLoadWidget}
    />
  );
};

export default ZendeskChat;
