export const hideChatWidget = () => {
  if (window.zE) {
    window.zE('webWidget', 'hide');
  }
};

export const showChatWidget = () => {
  if (window.zE) {
    window.zE('webWidget', 'show');
  }
};

export const toggleChatWidget = () => {
  if (window.zE) {
    window.zE('webWidget', 'toggle');
  }
};

export const openChatWidget = () => {
  if (window.zE) {
    window.zE('webWidget', 'open');
  }
};

export const closeChatWidget = () => {
  if (window.zE) {
    window.zE('webWidget', 'close');
  }
};

export const moveChatWidget = (position) => {
  if (window.zE) {
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        position
      }
    });
  }
};

export const initializeWidget = () => {
  window.zESettings = {
    webWidget: {
      position: { horizontal: 'right', vertical: 'bottom' },
      color: { theme: '#c93800' }
    }
  };
  window.zE('webWidget', 'hide');
  window.zE('webWidget', 'chat:addTags', ['agency']);
  window.zE('webWidget:on', 'close', () => window.zE('webWidget', 'hide'));

  window.zE('webWidget:on', 'chat:unreadMessages', () => {
    openChatWidget();
    showChatWidget();
  });
};
