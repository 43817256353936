const styles = (theme) => ({
  root: {
    borderRadius: '6px'
  },
  primary: {
    composes: '$root',
    backgroundColor: theme.colors.card_background_primary
  },
  primaryEditable: {
    composes: '$root',
    backgroundColor: theme.colors.card_background_primary_editable
  },
  secondary: {
    composes: '$root',
    backgroundColor: theme.colors.card_background_secondary
  },
  tertiary: {
    composes: '$root',
    backgroundColor: theme.colors.card_background_tertiary
  },
  quatrinary: {
    composes: '$root',
    backgroundColor: theme.colors.info__label_secondary
  },
  highlight: {
    composes: '$root',
    backgroundColor: theme.colors.payment__background_primary
  },
  outline: {
    composes: '$root',
    background: 'transparent',
    border: `2px solid ${theme.colors.card_background_primary}`
  },
  noBorders: {
    borderRadius: 0,
    backgroundColor: theme.colors.card_background_primary,
    borderTop: `1px solid ${theme.colors.card_background_primary_editable}`
  },
  noBordersTop: {
    composes: '$noBorders',
    borderRadius: '6px 6px 0 0'
  },
  scrollable: {
    maxHeight: 630,
    overflowY: 'scroll'
  },
  noData: {
    height: 232,
    display: 'flex'
  }
});

export default styles;
