import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  styledText: {
    display: 'block',
    lineHeight: 1.3
  }
}));

export default useStyles;
