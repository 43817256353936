import React, { useContext } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store/store.mobx';
import { dateTimeFormatter } from 'core/helpers/formatters';
import { DropzoneContainer } from '../dropzone';
import { Loading, SelectableRow, Table } from 'core';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import copyIcon from '../../assets/copy.svg';
import viewIcon from '../../assets/view.svg';
import styles from '../../documents.styles';

const InternalDocuments = observer(
  ({
    sortDocuments,
    classes,
    getDocName,
    onCopyDocClick,
    downloadDocument,
    uploaded,
    uploadFile,
    percent,
    error,
    deleting,
    file
  }) => {
    const {
      account: {
        policies: { documents, loadingDocuments: loading }
      }
    } = useStore();
    const { canEdit, isService } = useContext(AuthContext);

    return (
      <>
        <Section
          title="Internal Documents"
          rightLabel="Total"
          rightValue={`${loading || !documents ? '...' : documents.internal.length} documents`}
        >
          {loading || !documents ? (
            <Loading />
          ) : (
            <Card>
              {documents.internal.length || uploaded.length ? (
                <Table
                  selfContained={false}
                  header={
                    <>
                      <TableCell>FILE NAME</TableCell>
                      <TableCell>LAST MODIFICATION</TableCell>
                      {/* need the following empty tablecell header for the view or copy action buttons */}
                      <TableCell />
                    </>
                  }
                  body={
                    <>
                      {sortDocuments(documents.internal.slice() || []).map((d) => (
                        <TableRow key={d.path} id={d.path}>
                          <TableCell className={classes.docTitle}>{getDocName(d.path)}</TableCell>
                          <TableCell>{dateTimeFormatter(d.lastModified)}</TableCell>
                          <TableCell>
                            <div className={classes.iconContainer}>
                              {canEdit && (
                                <Tooltip
                                  title="Copy file to policy documents"
                                  placement="top"
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <IconButton onClick={() => onCopyDocClick(d)}>
                                    <img className={classes.icon} src={copyIcon} alt="copy internal doc to customer" />
                                  </IconButton>
                                </Tooltip>
                              )}
                              <Tooltip title="View file" placement="top" classes={{ tooltip: classes.tooltip }}>
                                <IconButton onClick={() => downloadDocument(d.path)}>
                                  <img className={classes.icon} src={viewIcon} alt="view document" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                      {sortDocuments(uploaded).map((d) => (
                        <SelectableRow key={d.path} id={d.path} onClick={downloadDocument}>
                          <TableCell className={classNames(classes.highlighted, classes.docTitle)}>
                            {getDocName(d.path)}
                          </TableCell>
                          <TableCell className={classes.highlighted}>{dateTimeFormatter(d.lastModified)}</TableCell>
                        </SelectableRow>
                      ))}
                    </>
                  }
                />
              ) : (
                <p className={classes.noDocs}>No internal documents for this customer</p>
              )}
              {isService && (
                <DropzoneContainer
                  onUpload={uploadFile}
                  progress={percent}
                  error={error}
                  deleting={deleting}
                  uploading={file ? file.path : undefined}
                />
              )}
            </Card>
          )}
        </Section>
      </>
    );
  }
);

InternalDocuments.propTypes = {
  sortDocuments: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  getDocName: PropTypes.func.isRequired,
  onCopyDocClick: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,
  uploaded: PropTypes.array,
  uploadFile: PropTypes.func.isRequired,
  percent: PropTypes.number,
  error: PropTypes.string,
  deleting: PropTypes.bool,
  file: PropTypes.object
};

InternalDocuments.defaultProps = {
  percent: 0,
  uploaded: [],
  deleting: false,
  error: undefined,
  file: undefined
};

export default withStyles(styles)(InternalDocuments);
