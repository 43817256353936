export const colorPalette = {
  white_10: '#ffffff',
  white_10_op_10: 'rgba(255, 255, 255, 0.10)',
  white_10_op_15: 'rgba(255, 255, 255, 0.15)',
  beige_10: '#eae2d6',
  beige_10_op_13: 'rgba(234, 226, 214, 0.13)',
  beige_10_op_60: 'rgba(234, 226, 214, 0.60)',
  beige_10_op_75: 'rgba(234, 226, 214, 0.75)',
  beige_20: '#dad0c3',
  white_30: '#f7f3ef',
  white_30_op_30: 'rgba(247,243,239,0.3)',
  white_40_op_70: 'rgba(234, 226, 214, 0.7)',
  green_5: '#878c4f',
  green_10: '#727831',
  green_15: '#52583c',
  green_10_op_10: 'rgba(114, 120, 49, 0.10)',
  green_10_op_20: 'rgba(114, 120, 49, 0.2)',
  green_10_op_30: 'rgba(114, 120, 49, 0.3)',
  green_10_op_75: 'rgba(114, 120, 49, 0.75)',
  green_10_op_60: 'rgba(114, 120, 49, 0.60)',
  green_20: '#343b1b',
  green_25: '#a4a87b',
  green_30: '#1b2514',
  green_35: '#262C14',
  green_40: '#454d2b',
  green_50: '#86c440',
  grey_10_op_38: 'rgba(52, 59, 27, 0.38)',
  orange_10: '#f89521',
  orange_10_op_08: 'rgba(248, 149, 33, 0.08)',
  orange_10_op_10: 'rgba(248, 149, 33, 0.1)',
  orange_10_op_70: 'rgba(248, 149, 33, 0.7)',
  orange_20: '#daa244',
  orange_30: '#f89521',
  orange_40: 'rgba(248, 149, 33, 0.4)',
  orange_50: '#da7228',
  red_10: '#cc3b21',
  red_20: '#9e2921'
};

export const colors = {
  green: '#32ddc2',
  green_light: '#76fff5',
  green_dark: '#00aa92',
  green_dark_90: 'rgba(27, 37, 20, 0.9)',
  red: '#d30000',
  red_light: '#ff5030',
  red_dark: '#990000',
  red_dark_90: 'rgba(153, 0, 0, 0.9)',
  black: '#000',
  gray: '#f2f7fa',
  white: '#fff',
  gray_01: '#eeeeee',
  gray_02: '#f2f5f6',
  gray_03: '#5b5b5b',
  gray_04: '#333333',

  // page general styles
  page__title: colorPalette.green_10,
  page__subtitle: colorPalette.green_20,
  page__background_primary: colorPalette.beige_10,
  // buttons
  button__background_primary: colorPalette.green_30,
  button__background_secondary: colorPalette.orange_10,
  button__text_primary: colorPalette.white_10,

  // header
  header__background_primary: colorPalette.beige_10,
  header__label_primary: colorPalette.green_20,
  header__label_secondary: colorPalette.green_10,

  // footer
  footer_background_primary: colorPalette.beige_10,
  // card styles
  info__background_primary: colorPalette.white_10,
  info__label_primary: colorPalette.green_20,
  info__label_secondary: colorPalette.green_10,
  info__label_title: colorPalette.green_10,
  info__label_subtitle: colorPalette.green_10,
  info__label_tertiary: colorPalette.orange_50,

  coverage__background_primary: colorPalette.green_20,
  coverage__background_secondary: colorPalette.green_10,
  coverage__label_primary: colorPalette.beige_10,
  coverage__label_secondary: colorPalette.beige_10_op_75,
  coverage__label_subtitle: colorPalette.beige_10,
  coverage__label_title: colorPalette.beige_10,
  coverage__label_paragraph: colorPalette.beige_10_op_75,

  payment__background_primary: colorPalette.green_10,
  payment__background_secondary: colorPalette.white_30,
  terms__background_primary: colorPalette.white_30,

  card_primary_separator: colorPalette.white_30,

  // form styles
  form__background_primary: colorPalette.white_30,
  form_label_primary: colorPalette.white_40_op_70,
  // plan picker styles
  planPicker_label_primary: colorPalette.beige_10,
  planPicker_label_secondary: colorPalette.green_25,

  text_primary: colorPalette.green_20,
  text_secondary: colorPalette.green_10,
  text_disabled: colorPalette.grey_10_op_38,
  text_secondary_background: colorPalette.white_10,

  light_text: colorPalette.beige_10,
  light_background: colorPalette.white_10_op_10,

  card_background_primary: colorPalette.white_10,
  card_background_primary_editable: colorPalette.white_30,
  card_background_secondary: colorPalette.green_20,
  card_background_tertiary: colorPalette.orange_20,

  loading_background: colorPalette.orange_40,
  loading_bar: colorPalette.orange_30,

  error: colorPalette.red_10,
  success: colorPalette.green_50
};

export const screens = {
  mobile: 500,
  tablet: 768,
  desktop: 1024,
  big_desktop: 1200
};

export const sizes = {
  headerHeight: 96,
  footerHeight: 110,
  sideColumn: 360,
  contentColumn: 744,
  roundCorners: 6
};

export const fonts = {
  // The `!important` makes sure that using a font explicitly
  // will override any font being inherited from higher up in the tree
  // (which will also be !important), but cannot be overridden by any
  // fonts coming from Material UI. (Ex: if you remove the !important,
  // tooltips from Material UI won't use the font you specify for the
  // selector '*')
  FrankRuhlLibre: `'Frank Ruhl Libre', -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif !important`,
  LibreFranklin: `'Libre Franklin', -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif !important`
};
