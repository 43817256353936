import React, { useState, useContext, useCallback, memo } from 'react';
import { Grid } from '@material-ui/core';
import { Form, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import {
  autoMultiPolicyDiscountOptions,
  homeMultiPolicyDiscountOptions,
  formattedAffinityOptions
} from 'core/helpers/lookups';
import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store';
import Switch from 'core/components/switch';
import { NEW_CUSTOMER_HOME_SECURITY } from 'core/helpers/constants';

import { Card } from 'core/components/card';
import { FormField } from 'core/components/form';
import Section from 'core/components/section';
import { ConnectedHomeSettings } from './connected-home-settings/index';
import useStyles from './discounts.styles';

const Discounts = memo(function Discounts({
  disabled,
  homeDiscounts,
  canModifyAffinityAndLeadSource,
  isInternalSales,
  showConnectedHome,
  onConnectedHomeChange,
  inventoryScore,
  onInventoryScoreChange,
  isBix,
  isAdvancedConnectedHome
}) {
  const affinityOptions = formattedAffinityOptions('No Affinity Code');
  const classes = useStyles();
  const multiPolicyDiscountOptions = homeDiscounts ? homeMultiPolicyDiscountOptions : autoMultiPolicyDiscountOptions;
  return (
    <Section title="Discounts" type="SubSection">
      <Card type="secondary" className={classes.secondary}>
        <Form disabled={disabled}>
          <Grid container alignItems="flex-end" justify="space-between" spacing={2}>
            {canModifyAffinityAndLeadSource && (
              <FormField
                id="global.affinity"
                name="global.affinity"
                label="Affinity Code"
                type="select"
                mode="dark"
                options={affinityOptions}
                xs={6}
              />
            )}
            <FormField
              id="multiPolicyDiscount"
              name="multiPolicyDiscount"
              label="Multiline Discount"
              type="select"
              mode="dark"
              options={multiPolicyDiscountOptions}
              xs={6}
            />
            <Grid container className={classes.discountsContainer}>
              {isBix && (
                <FormField
                  id="global.discountPaperless"
                  name="global.discountPaperless"
                  label="Paperless Discount"
                  type="switch"
                  mode="dark"
                  xs={12}
                />
              )}
              {homeDiscounts && isBix && (
                <Grid className={classes.inventoryContainer}>
                  <Switch
                    id="global.discountInventoryScore"
                    name="global.discountInventoryScore"
                    label="Inventory Discount"
                    mode="dark"
                    value={inventoryScore}
                    onChange={onInventoryScoreChange}
                    className={inventoryScore ? classes.discountInventoryScore : null}
                    xs={12}
                  />
                  {isInternalSales && inventoryScore && (
                    <FormField
                      id={'global.manualInventorySubmission'}
                      name={'global.manualInventorySubmission'}
                      type="checkbox"
                      label="I have received the member's inventory by email instead of through the app"
                      mode="dark"
                      className={classes.manualUploadCheckbox}
                    />
                  )}
                </Grid>
              )}
              {homeDiscounts && (
                <Grid item xs={12}>
                  <Switch
                    id="discountConnectedHome"
                    name="discountConnectedHome"
                    label="Connected Home"
                    mode="dark"
                    value={showConnectedHome}
                    onChange={onConnectedHomeChange}
                    xs={12}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Form>
        {showConnectedHome && <ConnectedHomeSettings isAdvancedConnectedHome={isAdvancedConnectedHome} />}
      </Card>
    </Section>
  );
});

const DiscountsFormikProvider = observer(({ disabled, homeDiscounts }) => {
  const classes = useStyles();
  const { canModifyAffinityAndLeadSource, isInternalSales } = useContext(AuthContext);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const {
    account: {
      policies: {
        policy: {
          isAdvancedConnectedHome,
          policy: { isBix: policyIsBix }
        }
      }
    }
  } = useStore();
  const isBix = policyIsBix || values.isBix; // policy isBix stored on policy level, this is segment context form
  const [showConnectedHome, setShowConnectedHome] = useState(
    !!values.connectedHome && values.connectedHome?.monitored !== 'N'
  );
  const [inventoryScore, setInventoryScore] = useState(values.global.discountInventoryScore);
  const onInventoryScoreChange = (e) => {
    setInventoryScore(e.target.checked);
    setFieldValue('global.discountInventoryScore', e.target.checked);
    if (!e.target.checked) {
      setFieldValue('global.manualInventorySubmission', false);
      setFieldTouched('global.manualInventorySubmission');
    }
    setFieldTouched('global.discountInventoryScore');
  };
  // clear the values when changed
  const onConnectedHomeChange = useCallback(
    (e) => {
      setShowConnectedHome(e.target.checked);
      if (e.target.checked) {
        setFieldValue('connectedHome', {
          moistureDevices: undefined,
          motionDetectingDevices: undefined,
          smokeDetectingDevices: undefined,
          theftPreventionDevices: undefined,
          autoWaterShutoffDevices: false,
          providerName: undefined,
          ...values.connectedHome
        });
      } else {
        setFieldValue('global.homeSecurityPartnerCustomerType', NEW_CUSTOMER_HOME_SECURITY.NO_SIGN_UP_TYPE);
        setFieldValue('connectedHome', { ...values.connectedHome, monitored: 'N' });
      }
      setFieldTouched('connectedHome');
    },
    [setFieldValue, setFieldTouched, values.connectedHome]
  );

  return (
    <Discounts
      classes={classes}
      disabled={disabled}
      homeDiscounts={homeDiscounts}
      canModifyAffinityAndLeadSource={canModifyAffinityAndLeadSource}
      isInternalSales={isInternalSales}
      inventoryScore={inventoryScore}
      onInventoryScoreChange={onInventoryScoreChange}
      showConnectedHome={showConnectedHome}
      onConnectedHomeChange={onConnectedHomeChange}
      isBix={isBix}
      isAdvancedConnectedHome={isAdvancedConnectedHome}
    />
  );
});

DiscountsFormikProvider.propTypes = {
  disabled: PropTypes.bool.isRequired,
  homeDiscounts: PropTypes.bool
};

DiscountsFormikProvider.defaultProps = {
  homeDiscounts: false
};

Discounts.defaultProps = {
  isAdvancedConnectedHome: false,
  isBix: false
};

Discounts.propTypes = {
  disabled: PropTypes.bool.isRequired,
  homeDiscounts: PropTypes.bool.isRequired,
  canModifyAffinityAndLeadSource: PropTypes.bool.isRequired,
  showConnectedHome: PropTypes.bool.isRequired,
  onConnectedHomeChange: PropTypes.func.isRequired,
  isBix: PropTypes.bool,
  isAdvancedConnectedHome: PropTypes.bool
};

export default DiscountsFormikProvider;
