import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  StylesProvider,
  MuiThemeProvider,
  createMuiTheme,
  createGenerateClassName,
  jssPreset
} from '@material-ui/core/styles';
import { create } from 'jss';
import jssCompose from 'jss-plugin-compose';
import jssExtend from 'jss-plugin-extend';

import { colorPalette, colors, screens, sizes } from './variables';
import overrideStyles from './material-overrides';

const generateClassName = createGenerateClassName();
const jss = create({
  plugins: [jssExtend(), ...jssPreset().plugins, jssCompose()]
});
export const theme = createMuiTheme({
  typography: {
    fontFamily: "'Libre Franklin', sans-serif",
    useNextVariants: true
  },
  palette: {
    primary: {
      main: colorPalette.green_30,
      contrastText: colorPalette.white_10
    },
    secondary: {
      main: colorPalette.orange_10,
      contrastText: colorPalette.white_10
    }
  },
  colors,
  colorPalette,
  screens,
  sizes,
  spacing: 4
});

overrideStyles(theme);

const ThemeProvider = ({ children }) => (
  <StylesProvider jss={jss} generateClassName={generateClassName}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  </StylesProvider>
);

ThemeProvider.propTypes = {
  children: PropTypes.object.isRequired
};

export default ThemeProvider;
