import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'core';
import { FormField } from 'core/components/form';
import { numberFormatter } from 'core/helpers/formatters';
import { LabelTooltip } from 'core/components/label-tooltip';
import { tooltipHoverTexts } from 'core/helpers/constants';
import { CardLine } from '../../card-line';
import useStyles from './policy-type-option.styles';

const money = (value) => `$${numberFormatter(value, 2)}`;

const PriceBreakdown = ({ price, frequency }) => {
  const classes = useStyles();
  return (
    <div className={classes.price}>
      <Label type="toast">{money(price)}</Label>
      <Label className={classes.priceNote} type="noteSmall">
        {frequency}
      </Label>
    </div>
  );
};

PriceBreakdown.propTypes = {
  price: PropTypes.number.isRequired,
  frequency: PropTypes.string.isRequired
};

const PolicyTypeOption = ({
  option,
  type,
  linkedToExistingCustomer,
  label,
  fieldOptions,
  onClick,
  noAutoWBundle,
  noAuto
}) => {
  const classes = useStyles();

  const hasHome = option.homePrice && option.homeFrequency;
  const hasAuto = option.autoPrice && option.autoFrequency;
  const disableIfBundleAndNotLinked = (type === 'HB' || type === 'AB') && !linkedToExistingCustomer;

  return (
    <CardLine>
      <div className={classes.section}>
        <FormField
          name="selectedOption"
          label={label}
          onClick={onClick}
          options={fieldOptions}
          type="radio"
          mode="dark"
          disabled={noAutoWBundle || noAuto || disableIfBundleAndNotLinked}
        />
      </div>
      <div className={classes.section}>
        {hasHome && <PriceBreakdown price={option.homePrice} frequency={option.homeFrequency} />}
        {hasHome && hasAuto && (
          <Label className={classes.plus} type="toast">
            +
          </Label>
        )}
        {hasAuto &&
          ((type === 'A' && noAuto && (
            // not eligible for monoline auto
            <LabelTooltip tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.noMonolineAuto }} />
          )) ||
            (type === 'AB' && noAutoWBundle && (
              // not elligible for auto w/bundle option
              <LabelTooltip tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.noMonolineAutoWBundle }} />
            )) ||
            (!noAuto && !noAutoWBundle && (
              <PriceBreakdown price={option.autoPrice} frequency={option.autoFrequency} />
            )))}
      </div>
    </CardLine>
  );
};

PolicyTypeOption.propTypes = {
  option: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  fieldOptions: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  linkedToExistingCustomer: PropTypes.bool.isRequired
};

export default PolicyTypeOption;
