import React, { useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash-es';
import _range from 'lodash-es/range';
import { connect } from 'formik';
import { paymentType, lookupsJson } from '@ourbranch/lookups';

import { useStore } from 'core/store/store.mobx';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import { AuthContext } from 'core/components/auth';
import styles from '../policy-settings/policy-settings.styles';

const daysOfMonth = _range(1, 29).map((day) => ({ id: day, value: day.toString() }));

const Mortgage = observer(({ formik, classes }) => {
  const { paymentType: paymentTypeOptions } = lookupsJson;
  const { isService, canModifyBillingId } = useContext(AuthContext);

  const { setFieldValue } = formik;
  const {
    account: {
      policies: { policy }
    }
  } = useStore();

  const primaryMortgage = policy.segment?.home?.mortgageDetails?.find((mortgage) => mortgage.primary) || {};

  useEffect(() => {
    setFieldValue('paymentType', paymentType.Escrow);
  }, [setFieldValue]);

  return (
    <>
      <FormField
        name="paymentType"
        type="select"
        label="Payment Frequency"
        mode="dark"
        xs={4}
        value={paymentType.Escrow}
        disabled
        options={paymentTypeOptions}
      />
      <FormField
        name="billingDayOfMonth"
        type="select"
        label="Billing Day"
        mode="dark"
        icon="calendar"
        xs={4}
        options={daysOfMonth}
        disabled={!isService}
      />
      <Grid container justify="flex-start" className={classes.billingDetailsContainer} spacing={4}>
        <Label style={{ width: '100%', paddingLeft: '6px' }} type="smallWarning">
          Navigate to Details to edit mortgage holder information.
        </Label>
        <FormField
          name="primaryMortgage.mortgageHolderName"
          value={primaryMortgage.mortgageHolderName}
          type="string"
          mode="dark"
          label="Lender Name"
          xs={4}
          disabled
        />
        <FormField
          name="primaryMortgage.loanNumber"
          value={primaryMortgage.loanNumber}
          type="string"
          mode="dark"
          label="Loan Number (optional)"
          disabled
          xs={4}
        />
        {canModifyBillingId && (
          <FormField name="stripeCustomerId" xs={4} type="string" mode="dark" label="Billing ID" />
        )}
        <FormField
          name="primaryMortgage.mortgageHolderAddress"
          value={primaryMortgage.mortgageHolderAddress}
          type="address"
          mode="dark"
          label="Lender Address"
          disabled
          xs={12}
        />
      </Grid>
    </>
  );
});

Mortgage.propTypes = {
  formik: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default flowRight(withStyles(styles), connect)(Mortgage);
