import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './no-results.styles';

const NoResults = ({ classes }) => (
  <Paper className={classes.container}>
    <div data-cy="no-results">No data available for your search.</div>
  </Paper>
);

NoResults.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NoResults);
