import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import ChatIcon from '@material-ui/icons/Chat';

import { AuthContext } from 'core/components/auth';
import { openChatWidget, showChatWidget } from 'core/components/zendesk-chat';

const useStyles = makeStyles((theme) => ({
  floatingContainer: {
    position: 'fixed',
    bottom: '125px',
    right: '35px',
    zIndex: 100
  }
}));

const StaffSpeedDial = () => {
  const classes = useStyles();
  const session = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBugReport = () => {
    window.open(`https://team-branch.zendesk.com/hc/en-us/requests/new?ticket_form_id=360001162011`, '_blank');
  };

  const openZendeskChat = () => {
    showChatWidget();
    openChatWidget();
  };

  return (
    <div className={classes.floatingContainer}>
      <SpeedDial
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        icon={<HelpOutlineOutlinedIcon />}
        ariaLabel="Staff Speed Dial"
      >
        <SpeedDialAction
          key="Need Assistance?"
          icon={<ContactSupportOutlinedIcon />}
          tooltipTitle="Need Assistance?"
          onClick={handleBugReport}
        />

        {session.isAgency && (
          <SpeedDialAction
            key="Agency Support Chat"
            icon={<ChatIcon />}
            tooltipTitle="Agency Support Chat"
            onClick={openZendeskChat}
          />
        )}
      </SpeedDial>
    </div>
  );
};

export default StaffSpeedDial;
