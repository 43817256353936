import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';

import { Discount } from '../discount';

const Paperless = ({ isActive, ...discount }) => {
  const { setFieldValue, values } = useFormikContext();

  const isApplied = values.global ? values.global.discountPaperless : isActive;

  const onAddClick = useCallback(() => {
    setFieldValue('global.discountPaperless', true);
  }, [setFieldValue]);

  const onRemoveClick = useCallback(() => {
    setFieldValue('global.discountPaperless', false);
  }, [setFieldValue]);

  return <Discount {...discount} isActive={isApplied} onAdd={onAddClick} onRemove={onRemoveClick} />;
};

export default Paperless;
