import { useMemo } from 'react';
import { getIn } from 'formik';
import { useStore } from 'core/store/store.mobx';

// @TODO this could be a mobx computed property
export const useCurrentState = (formikValues) => {
  const store = useStore();
  if (!store && !formikValues) {
    throw new Error('This hook has to be called inside policyStore or quote formik context');
  }
  const policy = store.account.policies?.policy?.policy;
  return useMemo(() => {
    if (policy) {
      return policy.state;
    }
    const state = getIn(formikValues, 'correctedAddress.state');
    return state;
  }, [policy, formikValues]);
};
