import * as Yup from 'yup';
import shouldBeAValidState from '../../../../helpers/yup-valid-state';

Yup.addMethod(Yup.mixed, 'shouldBeAValidState', shouldBeAValidState);

export const motgageValidation = Yup.object().shape({
  loanNumber: Yup.number().required('Loan number is required').typeError('Loan Number must be a number'),
  mortgageHolderName: Yup.string().required('Holder Name is required'),
  mortgageHolderAddress: Yup.object({
    address: Yup.string().required('Holder Address is required'),
    address2: Yup.string().nullable(),
    country: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().ensure().shouldBeAValidState('mortgage.state'),
    zip: Yup.string().nullable()
  })
});
