/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import People from 'common/components/people';
import AddApplicant from 'common/components/people/add-form';
import Applicant from 'common/components/people/applicant';
import { withDisabledState } from 'customer/disabled-context';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Discounts from 'common/components/discounts';
import { HomeCoverage, Umbrella } from './coverages';
import Detail from './detail';
import { SchedulePP } from './schedule-pp';

const HomePolicy = memo(({ disabled }) => {
  return (
    <>
      <People
        disabled={disabled}
        id="people"
        title="People"
        person={Applicant}
        addForm={AddApplicant}
        addLabel="Add People"
      />
      <Detail disabled={disabled} />
      <HomeCoverage disabled={disabled} fromPolicy />
      <Umbrella disabled={disabled} fromPolicy />
      <Discounts disabled={disabled} homeDiscounts />
      <SchedulePP disabled={disabled} />
    </>
  );
});

HomePolicy.propTypes = {
  disabled: PropTypes.bool
};

HomePolicy.defaultProps = {
  disabled: false
};

export default flowRight(withDisabledState)(HomePolicy);
