import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';

export const getInitialValues = (prefillData) => {
  let initialValues = {
    firstName: '',
    lastName: '',
    address: '',
    driversLicense: '',
    driversLicenseState: '',
    state: '',
    zip: '',
    city: '',
    dateOfBirth: '',
    email: undefined,
    phone: undefined,
    leadSource: undefined,
    affinity: undefined,
    rep: undefined,
    VIN: '',
    priorAddress: {
      address: '',
      state: '',
      zip: '',
      city: ''
    },
    home: {
      sqFt: undefined,
      ownershipStatus: undefined,
      homeQuality: undefined,
      typeOfHome: undefined,
      constructionType: undefined,
      exteriorWallType: undefined,
      garageType: undefined,
      roofShape: undefined,
      yearBuilt: undefined,
      numFireplaces: undefined,
      numWoodBurningStoves: undefined,
      numOccupants: undefined,
      numMortgages: undefined,
      numFullBathrooms: undefined,
      numHalfBathrooms: undefined,
      numStories: undefined,
      garageCarCapacity: undefined,
      numFamiliesInDwelling: undefined,
      roofYear: undefined,
      roofType: undefined,
      roofOriginal: undefined,
      purchaseDate: undefined,
      basementSqFt: undefined,
      basementType: undefined,
      latitude: undefined,
      longitude: undefined,
      fpc: undefined,
      wildfireHazardScore: undefined,
      dtoName: undefined,
      dtbowName: undefined,
      dtbowLabel: undefined,
      stormShutters: undefined
    },
    insuranceInPast31Days: false,
    priorCoverageInYears: '',
    policyLimitBIPD: '',
    fcraDisclaimer: false,
    isNewConstruction: false,
    connectedHome: undefined,
    global: undefined,
    signUpForNewMonitoringService: false
  };

  if (prefillData) {
    const {
      fname,
      lname,
      firstName,
      lastName,
      correctedAddress: { address, city, state, zip } = {},
      priorAddress,
      email,
      phone,
      home,
      drivers,
      leadSource,
      affinity
    } = prefillData;
    initialValues = {
      ...initialValues,
      firstName: fname || firstName,
      lastName: lname || lastName,
      rep: prefillData.salesRep,
      dateOfBirth: drivers?.length ? drivers[0]?.dateOfBirth : '',
      driversLicense: drivers?.length ? drivers[0]?.driversLicenseNumber : '',
      driversLicenseState: drivers?.length ? drivers[0]?.driversLicenseState : '',
      policyLimitBIPD: prefillData.autoCoverage?.policyLimitBIPD,
      priorCoverageInYears: Math.min(
        differenceInCalendarYears(new Date(), Date.parse(prefillData.global?.continuousAutoCoverageStartDate)),
        6
      ),
      insuranceInPast31Days: !!prefillData.global?.continuousAutoCoverageStartDate,
      address,
      state,
      zip,
      city,
      priorAddress,
      home,
      phone,
      email,
      leadSource,
      affinity
    };
  }
  return initialValues;
};
