import { withStyles } from '@material-ui/core/styles';
import { policyTypes } from '@ourbranch/policy-types';
import dateFnsFormat from 'date-fns/format';
import isFuture from 'date-fns/isFuture';
import { Formik, yupToFormErrors } from 'formik';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { observer } from 'mobx-react';

import { AuthContext } from 'core/components/auth';
import {
  mapAutoDiscountValues,
  mapHomeDiscountValues,
  mapDiscountToInputs,
  awsDateFormatter
} from 'core/helpers/formatters';
import { getDateViolations } from './segment-date-change-violations';
import { Loading } from 'core/components/loading';
import { useToast } from 'core/components/toast';
import { formatInput, getValues, sortItems } from 'core/helpers/scheduled-pp-helper';
import { useStore } from 'core/store';
import { formatUmbrellaValues } from 'common/components/home/coverages/umbrella/umbrella';
import {
  formatValues as formatHomeValues,
  getHighestEducation,
  getOldestResident
} from 'common/components/home/detail/helper';
import { getStartMinMaxDate } from '../dates';
import DetailsForm from './details-form';
import { autoValidation, homeValidation } from './validations';
import { styles } from './details.styles';

const changeType = {
  EditSelectedSegment: 'edit_change',
  NewSegment: 'new_change'
};

const PolicyDetails = observer(({ loadingPreview, onSegmentPreview }) => {
  const {
    account: {
      policies: {
        getAutoPolicyCoverage,
        getHomePolicyDetails,
        list: policies,
        policy: { policy, segment, isAdvancedConnectedHome, geographicState }
      }
    }
  } = useStore();

  // in order to have the accurate umbrella values we have to use this function
  const homePolicyDetails = getHomePolicyDetails();
  const [state, setState] = useState(() =>
    fromJS({
      changed: false,
      changeType: isFuture(new Date(segment.startDate)) ? changeType.EditSelectedSegment : changeType.NewSegment,
      startDate: undefined,
      minDate: undefined,
      maxDate: undefined
    })
  );

  const session = useContext(AuthContext);
  const { canBackDate } = session;
  const toast = useToast();

  // if we're on a home policy, the current segment node in the store is filled with the home policy's segment
  // so we need to grab the autoPolicy from the store and look at it's merged segments or policyDetails for the auto coverage
  // we need autoCoverage even for home policies so we know if the policy is able to include umbrella
  const autoCoverage = policy.policyType === 'A' ? segment.autoCoverage : getAutoPolicyCoverage();

  const initialValues = {
    ...segment,
    auto: segment?.auto
      ? {
          ...segment.auto,
          pipAdditionalResidents: segment?.auto?.pipAdditionalResidents || 0
        }
      : null,
    scheduledPersonalProperty: {
      deductible: segment.scheduledPersonalProperty?.items ? segment.scheduledPersonalProperty?.deductible : null,
      items: segment.scheduledPersonalProperty?.items.length
        ? sortItems(segment.scheduledPersonalProperty?.items.map((item) => getValues(item)))
        : []
    },
    highestEducation:
      policy?.offer?.quote?.people || policy?.offer?.quote?.drivers
        ? getHighestEducation(policy?.offer?.quote?.people || policy?.offer?.quote?.drivers)
        : undefined,
    oldestResident:
      policy?.offer?.quote?.people || policy?.offer?.quote?.drivers
        ? getOldestResident(policy?.offer?.quote?.people || policy?.offer?.quote?.drivers)
        : undefined,
    multiPolicyDiscount:
      policy.policyType === policyTypes.Home
        ? mapHomeDiscountValues(homePolicyDetails || policy.policyDetails)
        : mapAutoDiscountValues({
            ...policy.policyDetails,
            includeUmbrella: homePolicyDetails?.includeUmbrella
          }),
    attachedRenters: segment.attachedRenters || !!segment.includeRenters,
    autoCoverage,
    underwritingChanges: false,
    isBix: policy.isBix
  };

  useEffect(() => {
    // runs on the first render
    if (!state.get('startDate') && policy) {
      const { startDate, minDate, maxDate } = getStartMinMaxDate({
        policy,
        editCurrentSegment: state.get('changeType') === changeType.EditSelectedSegment,
        currentSegment: segment,
        canBackDate
      });
      setState(state.set('startDate', startDate).set('minDate', minDate).set('maxDate', maxDate));
    }
  }, [policy, state, segment, canBackDate]);

  useEffect(() => {
    // runs when clicking on a different segment in changes history
    // sets default change type based on effective date of segment: future effective defaults to changeType.EditSelectedSegment.
    if (policy && segment?.segmentId && segment.segmentId !== state.get('segmentId')) {
      const { startDate, minDate, maxDate } = getStartMinMaxDate({
        policy,
        editCurrentSegment: isFuture(new Date(segment.startDate)),
        currentSegment: segment,
        canBackDate
      });
      setState(
        state
          .set('segmentId', segment.segmentId)
          .set(
            'changeType',
            isFuture(new Date(segment.startDate)) ? changeType.EditSelectedSegment : changeType.NewSegment
          )
          .set('changed', false)
          .set('startDate', startDate)
          .set('minDate', minDate)
          .set('maxDate', maxDate)
      );
    }
  }, [segment, state, setState, policy, canBackDate]);

  const handleStartDate = useCallback(
    ({ value: startDate }) => {
      setState(state.set('startDate', startDate));
    },
    [setState, state]
  );

  const handleRadioChange = useCallback(
    (e) => {
      const { startDate, minDate, maxDate } = getStartMinMaxDate({
        policy,
        editCurrentSegment: e.target.value === changeType.EditSelectedSegment,
        currentSegment: segment,
        canBackDate
      });
      setState(
        state
          .set('changeType', e.target.value)
          .set('startDate', startDate)
          .set('minDate', minDate)
          .set('maxDate', maxDate)
      );
    },
    [setState, state, canBackDate, policy, segment]
  );

  const handleSegmentChange = useCallback(
    (policyDetails) => {
      const hasDateViolations = getDateViolations({
        isEdit: state.get('changeType') === changeType.EditSelectedSegment,
        policy,
        segment,
        startDate: state.get('startDate'),
        minDate: state.get('minDate'),
        maxDate: state.get('maxDate'),
        toast
      });
      if (!hasDateViolations) {
        const manuallySetMultiPolicyDiscount = policyDetails?.multiPolicyDiscount !== segment?.multiPolicyDiscount;
        const calculatedMultiPolicyDiscount =
          policy.policyType === policyTypes.Home
            ? mapHomeDiscountValues(policyDetails)
            : mapAutoDiscountValues({
                ...policyDetails,
                includeUmbrella: policyDetails?.includeUmbrella || homePolicyDetails?.includeUmbrella
              });
        const multiPolicyDiscount = manuallySetMultiPolicyDiscount
          ? policyDetails.multiPolicyDiscount
          : calculatedMultiPolicyDiscount;

        // If we're turning on inventory then set the discountInventoryScoreAddedDate
        if (!segment.global.discountInventoryScore && policyDetails.global.discountInventoryScore) {
          policyDetails.global.discountInventoryScoreAddedDate = dateFnsFormat(new Date(), 'yyyy-MM-dd');
        }
        onSegmentPreview(
          {
            ...policyDetails,
            umbrellaCoverage: formatUmbrellaValues(policyDetails.umbrellaCoverage, policyDetails.includeUmbrella),
            scheduledPersonalProperty: formatInput(policyDetails.scheduledPersonalProperty),
            home: formatHomeValues(policyDetails.home),
            startDate: awsDateFormatter(state.get('startDate')),
            multiPolicyDiscount,
            ...mapDiscountToInputs(multiPolicyDiscount),
            autoCoverage:
              policyDetails.autoCoverage && policy.policyType === policyTypes.Auto
                ? {
                    ...policyDetails.autoCoverage,
                    policyLimitPIPWL: policyDetails?.drivers?.some((d) => d.waivedPIPWL) ? 'S/WLW' : 'S'
                  }
                : null
          },
          state.get('changeType'),
          initialValues
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSegmentPreview, state, homePolicyDetails, segment, policy, toast]
  );

  if (!segment || !policy) {
    return <Loading />;
  }

  const validate = async (values) => {
    try {
      const schema =
        policy.policyType !== policyTypes.Auto
          ? homeValidation({ policies, isAdvancedConnectedHome, savedSegment: segment, session })
          : autoValidation({ policies });
      await schema.validate(values, {
        abortEarly: false,
        context: { ...values, state: geographicState, initialValues, canAddCarsManually: session.canAddCarsManually }
      });
    } catch (errors) {
      return yupToFormErrors(errors);
    }
  };
  return (
    <Formik
      key={`${segment.segmentId}-${policy.version}`}
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={handleSegmentChange}
      validate={validate}
    >
      <DetailsForm
        loadingPreview={loadingPreview}
        handleRadioChange={handleRadioChange}
        handleStartDate={handleStartDate}
        state={state}
      />
    </Formik>
  );
});

PolicyDetails.propTypes = {
  loadingPreview: PropTypes.bool.isRequired,
  onSegmentPreview: PropTypes.func.isRequired
};

export default withStyles(styles)(PolicyDetails);
