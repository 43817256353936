import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginTop: 16,
    '&> div': {
      borderTop: `1px solid ${theme.colorPalette.white_30_op_30} `
    },
    '&> div:first-child': {
      borderTop: 'none'
    },
    marginBottom: 16,
    padding: 15,
    minHeight: 60
  },
  primary: {
    backgroundColor: theme.colorPalette.orange_50
  },
  secondary: {
    backgroundColor: '#fff',
    color: theme.colorPalette.green_30
  },
  tertiary: {
    backgroundColor: theme.colorPalette.green_15,
    color: theme.colorPalette.white_10
  },
  quaternary: {
    backgroundColor: theme.colorPalette.green_30,
    color: theme.colors.light_text
  },
  icon: {
    marginRight: 16,
    width: 50,
    height: 30
  },
  styledText: {
    display: 'block',
    lineHeight: 1.3
  },
  notificationContentContainer: {
    display: 'flex',
    alignItems: 'top'
  }
}));

export default useStyles;
