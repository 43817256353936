import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

import styles from './selectable-row.styles';

class SelectableRow extends React.Component {
  static propTypes = {
    id: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.onClick(this.props.id);
  }

  render() {
    const { children, classes, onClick, ...rest } = this.props;
    return (
      <TableRow hover classes={{ hover: classes.hover, root: classes.root }} onClick={this.onClick} {...rest}>
        {children}
      </TableRow>
    );
  }
}

export default withStyles(styles)(SelectableRow);
