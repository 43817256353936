const crypto = require('crypto');
const qs = require('querystring');
const { key, iv } = require('./aes-256-cbc-key-iv.json');

function createBixConversionApplicationLink(policyId) {
  const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key, 'hex'), Buffer.from(iv, 'hex'));
  const encryptedPolicyId = cipher.update(policyId, 'utf8', 'hex') + cipher.final('hex');
  const querystring = qs.stringify({ policyId: encryptedPolicyId });
  return `https://ourbranch.com/convert?${querystring}`;
}

module.exports = {
  createBixConversionApplicationLink
};
