const { roofSurfacesExtendedCompositionMaxAge } = require('@ourbranch/lookups');

function willHaveCoverageRSExtended({ roofType, roofAge, state }) {
  return (
    (roofType === 'C' && roofAge <= (roofSurfacesExtendedCompositionMaxAge[state] || 15)) ||
    (['M', 'T', 'S'].includes(roofType) && roofAge <= 20) ||
    roofAge <= 10
  );
}

module.exports = { willHaveCoverageRSExtended };
