import { lookupsJson } from '@ourbranch/lookups';

const states = lookupsJson.usStates;

function shouldBeAValidState(name) {
  return this.test({
    name: `${name}.validState`,
    message: 'Please enter a valid US state',
    test: (val) => !val || states.some((state) => state.id === val),
    exclusive: false
  });
}

export default shouldBeAValidState;
