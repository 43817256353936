import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { getOptions, lookupsJson, noMedPayStates } from '@ourbranch/lookups';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { lazy, Suspense, useMemo, useEffect } from 'react';

import { FormField } from 'core/components/form';
import Section from 'core/components/section';
import { LabelTooltip } from 'core/components/label-tooltip';
import { tooltipHoverTexts } from 'core/helpers/constants';
import { useCurrentState } from '../../../hooks/useCurrentState';
import styles from './coverage.styles';
import SplitLiabilityLimit from './split-liability-limit';
import * as umbiResolvers from './limit-by-state';

const id = 'autoCoverage';

const Coverage = ({ classes, disabled }) => {
  const { values, setFieldValue } = useFormikContext();
  const state = useCurrentState(values);

  const UMBIByState = useMemo(() => {
    if (state) {
      return lazy(umbiResolvers[`um${state.toLowerCase()}`]);
    }
  }, [state]);

  const { policyLimitBIPD, policyLimitUMBI } = values[id];
  let { policyLimitUIMBI } = values[id];
  // if null UIMBI = UMBI
  if (!policyLimitUIMBI) {
    policyLimitUIMBI = policyLimitUMBI;
  }

  const valueBIPD = policyLimitBIPD.includes('/')
    ? Number(policyLimitBIPD.split('/')[0])
    : Number(policyLimitBIPD.split(' ')[0]);

  const valueUM = policyLimitUMBI.includes('/')
    ? Number(policyLimitUMBI.split('/')[0])
    : Number(policyLimitUMBI.split(' ')[0]);

  const valueUIMBI = policyLimitUIMBI.includes('/')
    ? Number(policyLimitUIMBI.split('/')[0])
    : Number(policyLimitUIMBI.split(' ')[0]);

  const [UIMBIOptions, UMBIOptions] = useMemo(() => {
    const validOption = (option, limit) => {
      if (option.id === '0/0') return true;
      if (option.id.includes('/')) {
        return Number(option.id.split('/')[0]) <= limit;
      }
      return Number(option.id.split(' ')[0]) <= limit;
    };

    let uimbiOptions = getOptions('policyLimitUIMBI', state).map((option) => ({
      ...option,
      disabled: !validOption(option, valueBIPD)
    }));
    const umbiOptions = getOptions('policyLimitUMBI', state).map((option) => ({
      ...option,
      disabled: !validOption(option, valueBIPD)
    }));

    if (state === 'WI') {
      // Wisconsin is different for UIMBI; must be limited to UMBI or 50/100
      // if UMBI is 25/50
      if (valueUM === 25 && !uimbiOptions.find((opt) => opt.id === '50/100')) {
        uimbiOptions.push({
          id: '50/100',
          value: '$50K | $100K',
          disabled: false
        });
      }
      // otherwise uimbi must equal um
      uimbiOptions = uimbiOptions.map((opt) => ({
        ...opt,
        disabled:
          opt.id !== '0/0' &&
          Number(opt.id.split('/')[0]) !== valueUM &&
          (valueUM !== 25 || opt.id !== '50/100') &&
          opt.id.replace(' CSL', '') !== String(valueBIPD)
      }));
    }

    return [
      // how do we change this over to lookupsJson?
      uimbiOptions,
      // how do we change this over to lookupsJson?
      umbiOptions
    ];
  }, [state, valueBIPD, valueUM]);

  // // don't allow UM/UIM to be higher than BIPD
  useEffect(() => {
    if (policyLimitUMBI && valueUM > valueBIPD) {
      // reverse options so we get highest value allowed
      const lowestPossibleUMBI = [...UMBIOptions].reverse().find((option) => {
        return !option.disabled;
      });
      setFieldValue(`${id}.policyLimitUMBI`, lowestPossibleUMBI?.id, false);
    }

    // in WI, 25/50 BI allows for 50/100 UIM, and otherwise UIM must be UM or rejected:
    const uimValueBIPD = state === 'WI' && valueBIPD === 25 ? 50 : valueBIPD;

    // UIMBI Can't be higher than UMBI which can't be higher than the uim BIPD set above
    // and also can't be currently disabled
    if (
      policyLimitUIMBI &&
      (
        valueUIMBI > uimValueBIPD ||
        UIMBIOptions.find((option) => {
          const testValue = option.id.includes('CSL')
            ? Number(option.id.replace(/ CSL/i, ''))
            : Number(option.id.split('/')[0]);
          return testValue === valueUIMBI;
        })
      )?.disabled
    ) {
      const lowestPossibleUIMBI = [...UIMBIOptions].reverse().find((option) => {
        return !option.disabled;
      });
      // UIMBI is optional
      setFieldValue(`${id}.policyLimitUIMBI`, lowestPossibleUIMBI?.id || '0/0', false);
    }
  }, [
    valueBIPD,
    policyLimitUMBI,
    policyLimitUIMBI,
    valueUM,
    valueUIMBI,
    setFieldValue,
    UIMBIOptions,
    UMBIOptions,
    state
  ]);

  return (
    <Section title="Auto Policy Coverage" type="SubSection">
      <div className={classes.container}>
        <Grid container justify="space-between" alignItems="flex-start" className={classes.containerDark} spacing={4}>
          {state === 'MI' ? (
            <SplitLiabilityLimit disabled={disabled} />
          ) : (
            <Grid item xs={6}>
              <LabelTooltip
                label="Bodily Injury/Property Damage Limit"
                tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.policyLimitBIPD }}
              >
                <FormField
                  mode="dark"
                  name={`${id}.policyLimitBIPD`}
                  id={`${id}.policyLimitBIPD`}
                  // how do we change this over to lookupsJson?
                  options={getOptions('policyLimitBIPD', state)}
                  type="select"
                  optional
                  disabled={disabled}
                />
              </LabelTooltip>
            </Grid>
          )}

          {!noMedPayStates[state] && (
            <Grid item xs={6}>
              <LabelTooltip
                label="Medical Payments"
                tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.policyLimitMedicalPayments }}
              >
                <FormField
                  mode="dark"
                  name={`${id}.policyLimitMedicalPayments`}
                  id={`${id}.policyLimitMedicalPayments`}
                  // how do we change this over to lookupsJson?
                  options={getOptions('policyLimitMedicalPayments', state)}
                  type="select"
                  optional
                  disabled={disabled}
                />
              </LabelTooltip>
            </Grid>
          )}

          <Suspense fallback={null}>
            {UMBIByState && (
              <UMBIByState id={id} disabled={disabled} UMBIOptions={UMBIOptions} UIMBIOptions={UIMBIOptions} />
            )}
          </Suspense>
          {state === 'MI' && (
            <FormField
              id={`${id}.policyLimitLPD`}
              name={`${id}.policyLimitLPD`}
              type="select"
              label="Limited Property Damage Coverage"
              mode="dark"
              xs={6}
              disabled={disabled}
              options={lookupsJson.policyLimitLPD}
            />
          )}
          {['PA', 'VA'].includes(state) && (
            <FormField
              id={`${id}.policyLimitIncomeLoss`}
              name={`${id}.policyLimitIncomeLoss`}
              type="select"
              label="Income Loss Coverage"
              mode="dark"
              xs={6}
              disabled={disabled}
              options={lookupsJson.policyLimitIncomeLoss}
            />
          )}
          {state === 'PA' && (
            <FormField
              id={`${id}.policyLimitFuneralBenefits`}
              name={`${id}.policyLimitFuneralBenefits`}
              type="select"
              label="Funeral Benefits Coverage"
              mode="dark"
              xs={6}
              disabled={disabled}
              options={lookupsJson.policyLimitFuneralBenefits}
            />
          )}
          {state === 'PA' && (
            <FormField
              id={`${id}.policyLimitTortOption`}
              name={`${id}.policyLimitTortOption`}
              type="select"
              label="Tort Option"
              mode="dark"
              xs={6}
              disabled={disabled}
              options={lookupsJson.policyLimitTortOption}
            />
          )}
          {state === 'PA' && (
            <FormField
              id={`${id}.policyLimitExtraMedBenefits`}
              name={`${id}.policyLimitExtraMedBenefits`}
              type="select"
              label="Extraordinary Medical Benefits Coverage"
              mode="dark"
              xs={6}
              disabled={disabled}
              options={lookupsJson.policyLimitExtraMedBenefits}
            />
          )}
          {state === 'PA' && (
            <FormField
              id={`${id}.policyLimitComboFBP`}
              name={`${id}.policyLimitComboFBP`}
              type="select"
              label="Combined First Party Benefits"
              mode="dark"
              xs={6}
              disabled={disabled}
              options={lookupsJson.policyLimitComboFBP}
            />
          )}
        </Grid>
      </div>
    </Section>
  );
};

Coverage.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

Coverage.defaultProps = {
  disabled: false
};

export default withStyles(styles)(Coverage);
