import React, { useState, useEffect, useContext } from 'react';
import TableCell from '@material-ui/core/TableCell';
import { Button, withStyles, Grid } from '@material-ui/core';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { AuthContext } from 'core/components/auth';
import { Loading, Paper, SelectableRow, Table, Label } from 'core';
import { useStore } from 'core/store/store.mobx';
import DatePicker from 'core/components/date-picker';
import { ApplicationUrlForm } from '../application-url-form';
import { RegenerateDocument } from '../regenerate-document';
import { dateTimeFormatter } from 'core/helpers/formatters';
import withDatePicker from 'core/components/with-date-picker';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import styles from '../../documents.styles';

// @TODO these might could be moved to mobx computed properties
const filterPolicyDocuments = (policyId, documents) => {
  if (!policyId) return documents;
  return documents.filter((doc) => doc.path.split('/')[3] === policyId);
};

const getPolicyIdFromPath = (path) => /(\w*-\d*-\w*)/.exec(path)[0];

const RegularDocuments = observer(
  ({
    getDocName,
    sortDocuments,
    showPolicyIdColumn,
    downloadDocument,
    classes,
    fetchDocuments,
    onRegenerateDocsError,
    toast
  }) => {
    const {
      account: {
        policies: {
          regeneratePolicyDocuments,
          list: policiesList,
          documents,
          unsignedApplications,
          loadingDocuments: loading,
          policy: policyStore
        }
      }
    } = useStore();

    const { canEdit, isService } = useContext(AuthContext);
    const processedNames = new Set();
    const policyId = policyStore.policy?.id;

    const createRegularDocs = (documents, policyId, processedNames) => {
      if (documents && Array.isArray(documents.regular)) {
        const regularDocsArr = sortDocuments(filterPolicyDocuments(policyId, documents.regular.slice()))
          .map((currentDoc) => ({
            ...currentDoc,
            friendlyName: getDocName(currentDoc.path),
            policyId: getPolicyIdFromPath(currentDoc.path)
          }))
          .map((currentDoc, currentIndex, docs) => {
            if (processedNames.has(currentDoc.friendlyName)) {
              return currentDoc;
            }

            processedNames.add(currentDoc.friendlyName);

            const duplicateNameExists = docs.some(
              (doc, index) => doc.friendlyName === currentDoc.friendlyName && index !== currentIndex
            );

            if (duplicateNameExists) {
              return { ...currentDoc, friendlyName: `Updated ${currentDoc.friendlyName}` };
            }

            return currentDoc;
          });
        return regularDocsArr;
      }
      return [];
    };

    const regularDocuments = createRegularDocs(documents, policyId, processedNames);

    const [filterState, setFilterState] = useState([]);
    const [dateState, setDateState] = useState(new Date());
    const [regeneratingDoc, setRegeneratingDoc] = useState(false);

    useEffect(() => {
      setFilterState(
        !window.location.href.includes('auto') && !window.location.href.includes('home')
          ? createRegularDocs(documents, null, processedNames)
          : createRegularDocs(documents, policyId, processedNames)
      ); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documents]); // including processedNames would trigger too many rerenders

    const onRegenerateDocuments = (policyId, policyType, accountId) => {
      setRegeneratingDoc(true);
      regeneratePolicyDocuments(policyId, accountId)
        .then(({ data }) => {
          if (data) {
            toast.notify({
              type: 'success',
              message: `${policyType} policy documents regenerated successfully`
            });
            fetchDocuments();
          } else {
            onRegenerateDocsError(toast, policyType);
          }
        })
        .catch(() => {
          onRegenerateDocsError(toast, policyType);
        })
        .finally(() => {
          setRegeneratingDoc(false);
        });
    };

    const onDateFilterClick = () => {
      const dateFilter = new Date(dateState).setHours(23, 59, 59);
      // set time to 23:59:59 to make sure we get all the docs for the day
      const objForFilter = {
        'Auto Policy': 0,
        'Auto Declarations': 0,
        'Auto ID Cards': 0,
        'Homeowners Policy': 0,
        'Homeowners Declarations': 0,
        'Home Insurance Proof of Insurance': 0
      }; // used to keep track of the docs we have returned in filter
      const filteredDateArray = regularDocuments
        .filter((d) => {
          const lastModDate = new Date(d.lastModified);
          return dateFilter >= lastModDate;
        })
        .filter((d) => {
          if (d.friendlyName.includes('Updated') && objForFilter[d.friendlyName.slice(8)] === 0) {
            objForFilter[d.friendlyName.slice(8)] = +1;
            return d;
          }
          if (objForFilter[d.friendlyName] === 0) {
            objForFilter[d.friendlyName] = +1;
            return d;
          }
          return false;
        });
      setFilterState(filteredDateArray);
    };

    const onResetClick = () => {
      setFilterState(regularDocuments);
    };

    const onDateChange = (value) => {
      setDateState(value);
    };

    return (
      <>
        <Section
          className={classNames({ [classes.docSection]: !policyId })}
          title="Policies Documents"
          rightLabel="Total"
          rightValue={`${loading || !documents || !Array.isArray(filterState) ? '...' : filterState.length} documents`}
        >
          {loading || !documents || !Array.isArray(documents.regular) ? (
            <Loading />
          ) : documents.regular.length ? (
            <>
              {canEdit && <ApplicationUrlForm unsignedApplications={unsignedApplications} />}
              <Card>
                <Grid container alignItems="center" className={classes.regularDocsTableContainer}>
                  <Label type="greenSmall">Show documents active on </Label>
                  <Grid item>
                    <DatePicker
                      onChange={(e) => onDateChange(e.value)}
                      mode="light"
                      disableFuture
                      label="DATE"
                      value={dateState}
                      className={classes.datePicker}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={onDateFilterClick}
                      mode="big"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      className={classes.filterButton}
                    >
                      Filter
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="text" color="secondary" onClick={onResetClick}>
                      Reset Filter
                    </Button>
                  </Grid>
                </Grid>
                {filterState && filterState.length > 0 ? (
                  <Table
                    selfContained={false}
                    header={
                      <>
                        <TableCell className={classes.firstCell}>FILE NAME</TableCell>
                        <TableCell>LAST MODIFICATION</TableCell>
                        {showPolicyIdColumn && <TableCell>POLICY ID</TableCell>}
                      </>
                    }
                    body={
                      <>
                        {filterState.length &&
                          filterState.map((d) => (
                            <SelectableRow key={d.path} id={d.path} onClick={downloadDocument}>
                              <TableCell className={classes.docTitle}>{d.friendlyName}</TableCell>
                              <TableCell>{dateTimeFormatter(d.lastModified)}</TableCell>
                              {showPolicyIdColumn && <TableCell>{d.policyId}</TableCell>}
                            </SelectableRow>
                          ))}
                      </>
                    }
                  />
                ) : (
                  <p className={classes.noDocs}>There were no active documents on this date.</p>
                )}
                {isService && (
                  <RegenerateDocument
                    documentPolicyId={policyId}
                    policies={policiesList}
                    onRegenerate={onRegenerateDocuments}
                    regenerating={regeneratingDoc}
                  />
                )}
              </Card>
            </>
          ) : (
            <Paper>No documents generated for this customer</Paper>
          )}
        </Section>
      </>
    );
  }
);

RegularDocuments.propTypes = {
  getDocName: PropTypes.func.isRequired,
  sortDocuments: PropTypes.func.isRequired,
  showPolicyIdColumn: PropTypes.bool,
  downloadDocument: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  fetchDocuments: PropTypes.func.isRequired,
  onRegenerateDocsError: PropTypes.func.isRequired,
  toast: PropTypes.object
};

RegularDocuments.defaultProps = {
  showPolicyIdColumn: false
};

export default withStyles(styles, withDatePicker)(RegularDocuments);
