const { policyTypes: PolicyType } = require('@ourbranch/policy-types');
const { alabamaLogicInstallmentFeeStates } = require('@ourbranch/lookups');

const paperFeeStateAuto = {
  AZ: 25,
  IL: 0,
  IN: 25,
  MO: 0,
  OH: 25,
  TX: 25
};

const paperFeeStateHome = {
  AZ: 25,
  IL: 0,
  IN: 25,
  MO: 25,
  OH: 25,
  TX: 25
};

const nonBixNonThreeDollarInstallmentFeeStates = {
  AZ: true,
  IL: true,
  MO: true,
  OH: true,
  TX: true
};

// paper fees by state
const paperFeeByTypeAndState = (state, type) => {
  const discount = type === PolicyType.Auto ? paperFeeStateAuto : paperFeeStateHome;
  return discount[state];
};

const getInventoryDiscount = ({ policyType, state, inBundle = false }) => {
  if (policyType === PolicyType.Auto || policyType === PolicyType.ARBundle) {
    if (state === 'TX') {
      return 0;
    }
    return inBundle ? 15 : 20;
  }

  if (state === 'IL') {
    // home, IL
    return 50;
  }
  // home, elsewhere
  return inBundle ? 55 : 65;
};

const FeeType = {
  Policy: 'policy',
  Imagery: 'imagery',
  Paper: 'paper',
  Installment: 'installment',
  Cancellation: 'cancellation',
  Reinstatement: 'reinstatement',
  AutoTheft: 'auto_theft',
  RuralFireDept: 'rural_fire_dept',
  FairPlan: 'fair_plan',
  AutoTheftPrevention: 'auto_theft_prevention',
  AutoCrimePrevention: 'auto_crime_prevention',
  MCCA: 'mcca'
};

/**
 *
 * @param {string} feeType, cant be A for autoPolicy fees or H for HomePolicyFees
 * @param {object} feeOptions: { policyType, inBundle, state }
 */
function generateFee(feeType, feeOptions) {
  let feeDescription;
  let feeAmount;

  const { policyType, state, inBundle, isBix, policyLimitPIPME } = feeOptions;
  const paymentCount = policyType === PolicyType.Auto ? 6 : 12;

  switch (feeType.toLowerCase()) {
    case 'policy':
      feeDescription = 'Policy';
      feeAmount = 5;
      break;
    case 'paper':
      feeDescription = 'Paper Documentation';
      feeAmount = paperFeeByTypeAndState(state, policyType);
      break;
    case 'imagery': {
      feeDescription = 'Imagery';
      feeAmount =
        policyType === PolicyType.HABundle
          ? getInventoryDiscount({ policyType: 'H', state, inBundle: true }) +
            getInventoryDiscount({ policyType: 'A', state, inBundle: true })
          : getInventoryDiscount({ policyType, state, inBundle });

      break;
    }
    case 'installment':
      feeDescription = 'Installment';
      if (!isBix && nonBixNonThreeDollarInstallmentFeeStates[state]) {
        feeAmount = feeOptions.paymentMethod === 'E' ? 1 * paymentCount : 4 * paymentCount;
      } else {
        feeAmount = 3 * paymentCount; // flat $3/installment for BIX and new GSNIC
      }
      break;
    case 'cancellation':
      feeDescription = 'Cancellation';
      feeAmount = 35;
      break;
    case 'reinstatement':
      feeDescription = 'Reinstatement';
      feeAmount = 5;
      break;
    case 'auto_theft':
      feeDescription = 'Auto Theft Authority';
      feeAmount = 0.5 * (Array.isArray(feeOptions.cars) ? feeOptions.cars.length : 1);
      break;
    case 'mcca':
      feeDescription = 'MCCA Full Recoupment Fee';
      feeAmount =
        policyLimitPIPME && policyLimitPIPME === 'UNLIMITED'
          ? 43 * (Array.isArray(feeOptions.cars) ? feeOptions.cars.length : 1)
          : 0;
      break;
    case 'rural_fire_dept':
      feeDescription = 'Rural Volunteer Fire Department Assistance Program Recoupment Fee';
      feeAmount = 0;
      break;
    case 'fair_plan':
      feeDescription = 'FAIR Plan Surcharge';
      feeAmount = 0;
      break;
    case 'auto_theft_prevention':
      feeDescription = 'Auto Theft Prevention';
      feeAmount =
        0.5 *
        (Array.isArray(feeOptions.cars)
          ? feeOptions.cars.filter((c) => c.deductibleComprehensive !== 'NONE').length
          : 1);
      break;
    case 'auto_crime_prevention':
      feeDescription = 'Motor Vehicle Crime Prevention';
      feeAmount = 2 * (Array.isArray(feeOptions.cars) ? feeOptions.cars.length : 1);
      break;

    default:
      break;
  }

  return { description: feeDescription, amount: feeAmount };
} // generateFee

function roundTwoDecimals(num) {
  return Math.round(num * 1e2) / 1e2;
}

/**
 *
 * @param {string} state, state in uppercase
 * @param {string} policyType, H or A
 * @param {object} fees: { amount, appliedDate, description, type }
 * @param {float} premium
 * @param {float} downPayment
 */
function adjustFees({ fees, state, policyType, premium, downPayment }) {
  // At this point, this function only implements the Alabama fee logic
  // if at some future point this function needs to do more, please refactor!
  if (!alabamaLogicInstallmentFeeStates[state] || !Array.isArray(fees)) {
    return;
  }

  const installmentFee = fees.find((f) => f.type === 'installment');

  if (!installmentFee) {
    return;
  }

  const rate = policyType === 'H' ? 0.09 : 0.045;
  const paymentCount = policyType === 'H' ? 11 : 5; // don't count the downpayment
  const feeMin = 3;
  const altFeeCalc = roundTwoDecimals(Math.floor((((premium || 0) - (downPayment || 0)) * rate + 15) / paymentCount));

  console.log(
    `fee adjustment from ${installmentFee.amount} to greater of ${feeMin} or ${altFeeCalc} (${premium}, ${downPayment}, ${rate}, ${paymentCount})`
  );

  installmentFee.amount = (altFeeCalc < feeMin ? altFeeCalc : feeMin) * (paymentCount + 1);
} // adjustFees

module.exports = {
  generateFee,
  adjustFees,
  FeeType
};
