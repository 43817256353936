import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'formik';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormField } from 'core/components/form';
import { awsDateToJs } from 'core/helpers/formatters';
import withDatePicker from 'core/components/with-date-picker';
import styles from './reinstate-form.styles';

const ReinstateForm = ({ classes, originalEndDate, formik }) => {
  const maxDate = awsDateToJs(originalEndDate);
  useEffect(() => {
    formik.setFieldValue('reinstateDate', originalEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container item xs={8} key="details" justify="flex-end">
      <FormField
        name="reinstateDate"
        type="date"
        label="Coverage End Date"
        mode="light"
        xs={4}
        disableFuture={false}
        maxDate={maxDate}
        initialFocusedDate={maxDate}
        className={classes.endDate}
        disabled={false} // cancel policy sets all form fields to disabled, so need to manually exclude this field
      />
      <FormField
        type="checkbox"
        id="applyFee"
        name="applyFee"
        mode="light"
        label="Apply Fee"
        xs={3}
        className={classes.checkbox}
        disabled={false} // Add fee
      />
    </Grid>
  );
};

ReinstateForm.propTypes = {
  originalEndDate: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired
};

export default flowRight(withStyles(styles), connect, withDatePicker)(ReinstateForm);
