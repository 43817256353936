const { clean } = require('./lib/clean');
const { mergeAndRemoveDuplicates } = require('./lib/merge');
const { policyDetailsFromSegments } = require('./lib/policy-details-from-segments');
const paymentsHelper = require('./lib/payments');
const { willHaveCoverageRSExtended } = require('./lib/will-have-coverage-rs-extended');
const { createBixConversionApplicationLink } = require('./lib/create-bix-conversion-application-link');
const { addCoveragesToPremiumBreakdown } = require('./lib/add-coverages-to-premium-breakdown');
const { samePolicyRoot, isPrecedingTerm } = require('./lib/policy-id-matching-utils');

module.exports = {
  clean,
  mergeAndRemoveDuplicates,
  paymentsHelper,
  policyDetailsFromSegments,
  createBixConversionApplicationLink,
  willHaveCoverageRSExtended,
  addCoveragesToPremiumBreakdown,
  samePolicyRoot,
  isPrecedingTerm
};
