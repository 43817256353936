import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { errorCodes, rejectCodes } from '@ourbranch/lookups';
import classNames from 'classnames';

import styles from './errors.styles';
import { Label } from '../../../core';
import { getError } from '../../constants/errorHelper';

const ErrorItem = ({ classes, error, isRejection }) => {
  const errorText = useMemo(() => {
    const codes = isRejection ? rejectCodes : errorCodes;
    return Object.keys(codes).reduce((prev, key) => {
      if (prev === '' && codes[key] === Number(error.code)) {
        return key;
      }
      return prev;
    }, '');
  }, [error, isRejection]);

  const errorObject = useMemo(() => {
    if (error) {
      return getError(error);
    }
  }, [error]);

  return (
    <div
      className={classNames({
        [classes.errorContainer]: !isRejection,
        [classes.rejectionContainer]: isRejection,
        [classes.nonFcraContainer]: isRejection && !errorObject.fcra
      })}
    >
      {!isRejection && <div className={classes.triangle} />}
      <div className={isRejection ? classes.rejectionContent : classes.errorContent}>
        <Label
          className={classNames({
            [classes.topInfo]: !isRejection || errorObject.fcra,
            [classes.nonFcraTopInfo]: isRejection && !errorObject.fcra
          })}
          type="infoLabel"
        >
          {errorText}: {error.code}
        </Label>
        {errorObject && (
          <>
            {errorObject.title && (
              <Label
                type="infoSubtitle"
                className={classNames({
                  [classes.title]: !isRejection || errorObject.fcra,
                  [classes.nonFcraTitle]: isRejection && !errorObject.fcra
                })}
              >
                {errorObject.title}
              </Label>
            )}
            {errorObject.message && (
              <Label
                type="infoValue"
                className={classNames(classes.message, {
                  [classes.errorMessage]: !isRejection,
                  [classes.rejectionMessage]: isRejection && errorObject.fcra,
                  [classes.nonFcraMessage]: isRejection && !errorObject.fcra
                })}
              >
                {errorObject.message}
              </Label>
            )}
          </>
        )}
      </div>
    </div>
  );
};

ErrorItem.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  isRejection: PropTypes.bool.isRequired
};

export default withStyles(styles)(ErrorItem);
