import React from 'react';
import { Grid } from '@material-ui/core';
import { getError } from 'quote/constants/errorHelper';
import PropTypes from 'prop-types';
import { Label } from 'core/components/label';
import useStyles from './error.styles';

const ErrorDetail = ({ error }) => {
  const classes = useStyles();

  const errorObject = (err) => {
    if (err) {
      return getError(err);
    }
  };

  const { text, message, title } = errorObject(error);

  return (
    <Grid item xs={12} className={classes.errorContent}>
      <Label className={classes.topInfo} type="infoLabel">
        {text} {error.code}
      </Label>
      {(title || message) && (
        <>
          {title && (
            <Label type="darkGreenSmall" className={classes.errorTitle}>
              {title}
            </Label>
          )}
          {message && (
            <Label type="infoLabel" className={classes.errorMessage}>
              {message}
            </Label>
          )}
        </>
      )}
    </Grid>
  );
};

ErrorDetail.propTypes = {
  error: PropTypes.object.isRequired
};

export default ErrorDetail;
