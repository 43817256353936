const dayjs = require('dayjs');
const cloneDeep = require('lodash.clonedeep');
const { mergeAndRemoveDuplicates } = require('./merge');

function policyDetailsFromSegments(policy, { activeSegmentsOnly = false } = {}) {
  const policyDetails = cloneDeep(policy.segments[0]);
  for (let i = 1; i < policy.segments.length; i += 1) {
    if (activeSegmentsOnly && dayjs(policy.segments[i].startDate).isAfter(dayjs())) {
      // Ignore any segments that start in the future if `activeSegmentsOnly` is true
      continue;
    }

    if (dayjs(policy.segments[i].startDate).isAfter(policy.endDate)) {
      // ignore any renewal segments
      continue;
    }
    mergeAndRemoveDuplicates(policyDetails, policy.segments[i]);
  }
  if (policyDetails.premium) {
    // we don't want the segment details premium to override policy.premium
    // and we copy the elements here to the root of policy
    delete policyDetails.premium;
  }
  return policyDetails;
}

module.exports = { policyDetailsFromSegments };
