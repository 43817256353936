import * as Yup from 'yup';
import shouldBeAValidState from '../../../helpers/yup-valid-state';

Yup.addMethod(Yup.mixed, 'shouldBeAValidState', shouldBeAValidState);

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name or Company is required!'),
  address: Yup.string().ensure().required('Address is required!'),
  address2: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: Yup.string().nullable().shouldBeAValidState('lienholder.state'),
  zip: Yup.string().nullable(),
  loanNumber: Yup.string().nullable(),
  VIN: Yup.string().nullable()
});
