/* eslint-disable */
const overrideButton = (theme) => {
  theme.overrides.MuiButton = {
    root: {
      borderRadius: 4,
      height: 36,
      padding: '0 8px'
    },
    containedSecondary: {
      fontWeight: 600,
      '&$disabled': {
        backgroundColor: theme.colorPalette.orange_10_op_70,
        color: theme.colorPalette.white_10
      }
    },
    textSecondary: {
      '&$disabled': {
        opacity: 0.5,
        color: theme.colorPalette.orange_10
      }
    },
    label: {
      textTransform: 'none'
    },
    sizeLarge: {
      padding: '0 22px'
    }
  };

  // this should be fixed by material on newer versions, we can also create a pull requests otherwise
  theme.overrides.MuiIconButton = {
    disabled: {
      color: theme.colorPalette.orange_10_op_70,
      opacity: 0.53
    }
  };
};

export default overrideButton;
