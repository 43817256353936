import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';

export default function withDatePicker(WrappedComponent) {
  return (props) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
      <WrappedComponent {...props} />
    </MuiPickersUtilsProvider>
  );
}
