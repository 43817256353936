"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.templates = void 0;
exports.templates = {
    // application
    APPL_FRONT: { id: 'APPL_FRONT', name: '' },
    H_SFSW: { id: 'H_SFSW', name: 'Home Stress-Free Switch Letter' },
    A_SFSW: { id: 'A_SFSW', name: 'Auto Stress-Free Switch Letter' },
    H_SHAR: { id: 'H_SHAR', name: 'Third-Party Data-Sharing Agreement' },
    B_AUTH: { id: 'B_AUTH', name: 'Recurring Payment Authorization Form' },
    A_APPL: { id: 'A_APPL', name: 'Auto Application' },
    H_APPL: { id: 'H_APPL', name: 'Home Application' },
    H_MINA: {
        id: 'H_MINA',
        name: 'Mine Subsidence Insurance Underwriting Association Application'
    },
    H_EUSF: {
        id: 'H_EUSF',
        name: 'Excess Uninsured/Underinsured Motorists Selection/Rejection Form'
    },
    A_UMSF: { id: 'A_UMSF', name: 'UM/UIM Selection Rejection Form' },
    A_LOIW: { id: 'A_LOIW', name: 'Loss of Income Waiver' },
    A_MEDP: { id: 'A_MEDP', name: 'MedPay Selection Rejection Form' },
    // declaration
    A_DECK: { id: 'A_DECK', name: 'Auto Declarations' },
    H_DECK: { id: 'H_DECK', name: 'Homeowners Declarations' },
    A_GBCK: {
        id: 'A_GBCK',
        name: 'Community Giveback Endorsement',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 122020',
            MI: 'MI 052021',
            NE: 'CW 122020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            TX: 'CW 042020',
            UT: 'CW 122020',
            WI: 'CW 122020'
        },
        docId: {
            AL: 'CW 102020',
            CO: 'CW 102020',
            KS: 'CW 102020',
            OH: 'CW 042020'
        }
    },
    A_ADWR: {
        id: 'A_ADWR',
        name: 'Auto Deductible Waiver Endorsement',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            NE: 'CW 072021',
            OH: 'CW 042020',
            OK: 'OK 022021',
            TX: 'CW 072021',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            OH: 'CW 042020'
        }
    },
    // id cards
    A_IDCD: { id: 'A_IDCD', name: 'Auto ID Cards' },
    H_BIND: { id: 'H_BIND', name: 'Homeowners Binder Proof of Insurance' },
    // Auto Policy Jacket
    A_PJAK: {
        id: 'A_PJAK',
        name: 'Auto Policy',
        bixDocId: {
            AZ: 'AZ 012021',
            IN: 'IN 122020',
            MD: 'MD 102020',
            MI: 'MI 052021',
            NE: 'CW 072021',
            OH: 'OH 042020',
            OK: 'OK 032021',
            PA: 'PA 052021',
            TX: 'TX 072021',
            UT: 'UT 112021',
            WI: 'WI 052021'
        },
        docId: {
            AL: 'AL 032021',
            AZ: {
                default: 'CW 122018',
                '2020-09-09': 'AZ 072020'
            },
            CO: 'CO 102020',
            IL: 'CW 102020',
            KS: 'KS 032021',
            MO: 'CW 102020',
            OH: 'CW 102020',
            TX: 'TX 022020'
        }
    },
    A_PLCY: {
        id: 'A_PLCY',
        name: 'Auto Policy',
        bixDocId: {
            AZ: 'AZ 032021',
            IN: 'IN 012021',
            MD: 'MD 022021',
            MI: 'MI 062021',
            NE: 'NE 072021',
            OH: 'OH 042020',
            OK: 'OK 022021',
            PA: 'PA 072021',
            TX: 'TX 092021',
            UT: 'UT 112021',
            WI: 'WI 052021'
        },
        docId: {
            AL: 'AL 032021',
            AZ: {
                default: 'AZ 062019',
                '2020-09-09': 'AZ 072020'
            },
            CO: 'CO 102020',
            IL: 'IL 122018',
            KS: 'KS 032021',
            MO: 'MO 122018',
            OH: 'CW 122018',
            TX: 'TX 032020'
        }
    },
    A_AMND: {
        id: 'A_AMND',
        name: 'Amendatory Endorsement',
        docId: {
            AZ: 'CW 122018',
            IL: 'IL 122018',
            MO: 'CW 122018',
            OH: 'CW 122018'
        }
    },
    A_CIVU: {
        id: 'A_CIVU',
        name: 'Civil Union Endorsement',
        docId: {
            AZ: 'CW 122018',
            IL: 'IL 122018',
            MO: 'CW 122018',
            OH: 'CW 122018'
        }
    },
    A_COMM: {
        id: 'A_COMM',
        name: 'Branch Community Giveback Endorsement',
        docId: {
            AZ: 'CW 042020',
            CO: 'CW 102020',
            IL: 'CW 042020',
            MO: 'CW 042020',
            OH: 'CW 042020',
            TX: 'CW 042020'
        }
    },
    A_NDEX: {
        id: 'A_NDEX',
        name: 'Named Driver Exclusion',
        bixDocId: {
            AZ: 'AZ 012021',
            IN: 'IN 122020',
            MD: 'MD 102020',
            MI: 'MI 042021',
            NE: 'NE 072021',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            UT: 'UT 102021',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'AZ 122018',
            CO: 'CO 112020',
            IL: 'IL 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 012020'
        }
    },
    A_NNOP: {
        id: 'A_NNOP',
        name: 'Named Non-Owner Coverage Endorsement',
        bixDocId: {
            AZ: 'AZ 012021',
            IN: 'IN 112020',
            MD: 'MD 102020',
            MI: 'MI 042021',
            NE: 'NE 072021',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 082021',
            UT: 'UT 102021',
            WI: 'WI 102020'
        },
        docId: {
            AL: 'AL 032021',
            AZ: 'AZ 122018',
            CO: 'CO 102020',
            IL: 'IL 122018',
            KS: 'KS 032021',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 012020'
        }
    },
    A_RENT: {
        id: 'A_RENT',
        name: 'Renters Endorsement',
        bixDocId: {
            IN: 'IN 122020',
            MD: 'MD 102020',
            NE: 'NE 072021',
            OH: 'CW 042020',
            OK: 'OK 022021',
            UT: 'UT 102021',
            WI: 'WI 102020'
        },
        docId: {
            AL: 'AL 032021',
            AZ: 'CW 122018',
            IL: 'IL 122018',
            KS: 'KS 052021',
            MO: 'CW 122018',
            OH: 'CW 122018'
        }
    },
    A_RNTC: {
        id: 'A_RNTC',
        name: 'Rental Car Endorsement',
        bixDocId: {
            MD: 'MD 102020'
        }
    },
    A_PIPR: {
        id: 'A_PIPR',
        bixDocId: {
            OH: 'CW 042020'
        },
        name: 'Pet Injury Endorsement'
    },
    A_RIDE: {
        id: 'A_RIDE',
        name: 'Rideshare Endorsement',
        bixDocId: {
            AZ: 'AZ 022021',
            IN: 'IN 112020',
            MD: 'MD 102020',
            MI: 'MI 042021',
            NE: 'NE 072021',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 092020',
            UT: 'UT 102021',
            WI: 'WI 102020'
        },
        docId: {
            AL: 'AL 032021',
            AZ: 'AZ 122018',
            CO: 'CO 102020',
            IL: 'IL 122018',
            KS: 'KS 032021',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 022020'
        }
    },
    A_RTNC: {
        id: 'A_RTNC',
        name: 'Renewal Terms and Conditions',
        bixDocId: {
            AZ: 'AZ 022021',
            IN: 'IN 012021',
            MD: 'MD 122020',
            MI: 'MI 062021',
            NE: 'NE 072021',
            OH: 'OH 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 072021',
            UT: 'UT 102021',
            WI: 'WI 052021'
        },
        docId: {
            AL: 'AL 102021',
            AZ: 'AZ 062019',
            CO: 'CO 102020',
            IL: 'IL 122019',
            KS: 'KS 032021',
            MO: 'MO 102019',
            OH: 'OH 112019',
            TX: 'TX 012020'
        }
    },
    A_WGPT: {
        id: 'A_WGPT',
        name: 'Window Glass Coverage Endorsement',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            OH: 'CW 042020',
            OK: 'CW 042020',
            WI: 'CW 042020'
        }
    },
    A_AANR: {
        id: 'A_AANR',
        name: 'Important information about your credit based score notice'
    },
    A_AATU: { id: 'A_AATU', name: 'Adverse Underwriting' },
    A_AATU2: { id: 'A_AATU2', name: 'Adverse Underwriting' },
    A_AAVK: { id: 'A_AAVK', name: 'Adverse Underwriting' },
    A_TRCC: {
        id: 'A_TRCC',
        name: 'Trailer Contents Coverage Endorsement',
        bixDocId: {
            UT: 'UT 102021'
        }
    },
    A_TPDC: {
        id: 'A_TPDC',
        name: 'Trailer Comp-Coll Coverage Endorsement',
        bixDocId: {
            UT: 'UT 102021'
        }
    },
    A_NEXL: {
        id: 'A_NEXL',
        name: 'Information',
        docId: {
            TX: 'TX 122018'
        }
    },
    A_CBRN: {
        id: 'A_CBRN',
        name: 'Information',
        docId: {
            TX: 'TX 062021'
        }
    },
    A_CYIR: { id: 'A_CYIR', name: '' },
    A_SPIN: {
        id: 'A_SPIN',
        name: 'Premium Increase Notice'
    },
    A_GPIN: {
        id: 'A_GPIN',
        name: 'Premium Increase Notice'
    },
    A_DISC: {
        id: 'A_DISC',
        docId: {
            CO: 'CO 102020'
        },
        name: 'Important Notice'
    },
    B_PRMM: {
        id: 'B_PRMM',
        bixDocId: {
            MD: 'MD 032021'
        },
        name: 'Important Notice'
    },
    A_RECA: {
        id: 'A_RECA',
        bixDocId: {
            MD: 'MD 022021'
        },
        name: 'Notice of Recalculation of Premium'
    },
    A_REDU: {
        id: 'A_REDU',
        bixDocId: {
            MD: 'MD 022021'
        },
        name: 'Reduction in Coverage Notice'
    },
    A_PIPS: {
        id: 'A_PIPS',
        bixDocId: {
            MD: 'MD 012021'
        },
        name: 'Notice of Waive of Personal Injury Protection Coverage'
    },
    A_EUMC: {
        id: 'A_EUMC',
        bixDocId: {
            MD: 'MD 102020'
        },
        name: 'Enhanced Underinsured Motorist Coverage Endorsement'
    },
    A_MPIG: {
        id: 'A_MPIG',
        bixDocId: {
            MD: 'MD 012021'
        },
        name: 'Notice of Premium Increase'
    },
    A_MPIL: {
        id: 'A_MPIL',
        bixDocId: {
            MD: 'MD 012021'
        },
        name: 'Notice of Premium Increase'
    },
    A_DRPP: {
        id: 'A_DRPP',
        bixDocId: {
            MD: 'MD 012021'
        },
        name: 'Summary of Driver Record Point Plan'
    },
    A_DCDR: {
        id: 'A_DCDR',
        bixDocId: {
            MD: 'MD 012021'
        },
        name: 'Summary of Rate Classification'
    },
    A_KSRI: {
        id: 'A_KSRI',
        docId: {
            KS: 'KS 032021'
        },
        name: 'Kansas Rating Information'
    },
    A_DITR: {
        id: 'A_DITR',
        bixDocId: {
            MI: 'MI 042021'
        },
        name: 'Declaration of Intent to Reside'
    },
    A_NDEL: {
        id: 'A_NDEL',
        bixDocId: {
            MI: 'MI 042021',
            TX: 'TX 072021',
            UT: 'UT 102021'
        },
        name: 'Named Driver Exclusion Election Form'
    },
    A_MPSF: {
        id: 'A_MPSF',
        bixDocId: {
            MI: 'MI 052021'
        },
        name: 'Selection of Personal Injury Protection'
    },
    A_BISF: {
        id: 'A_BISF',
        bixDocId: {
            MI: 'MI 042021'
        },
        name: 'Choice of Bodily Injury Liability Coverage Limits'
    },
    A_BIOF: {
        id: 'A_BIOF',
        bixDocId: {
            MI: 'MI 052021'
        },
        name: 'Bodily Injury Option and Premiums'
    },
    A_WLBW: {
        id: 'A_WLBW',
        bixDocId: {
            MI: 'MI 062021'
        },
        name: 'Work Loss Benefits Waiver'
    },
    A_RTNO: {
        id: 'A_RTNO',
        bixDocId: {
            MI: 'MI 062021'
        },
        name: 'Rating Information Notice'
    },
    A_UIMS: {
        id: 'A_UIMS',
        bixDocId: {
            PA: 'PA 042021',
            UT: 'UT 102021'
        },
        name: 'UIM Selection Rejection Form'
    },
    A_TOSF: {
        id: 'A_TOSF',
        bixDocId: {
            PA: 'PA 042021'
        },
        name: 'Tort Selection Rejection Form'
    },
    A_CODW: {
        id: 'A_CODW',
        bixDocId: {
            PA: 'PA 042021'
        },
        name: 'Collision Deductible Waiver'
    },
    A_ICSE: {
        id: 'A_ICSE',
        bixDocId: {
            PA: 'PA 042021'
        },
        name: 'Insurance Consultation Services Exemption'
    },
    A_NOBE: {
        id: 'A_NOBE',
        bixDocId: {
            PA: 'PA 042021'
        },
        name: 'Notice of Benefits'
    },
    A_NOTO: {
        id: 'A_NOTO',
        bixDocId: {
            PA: 'PA 042021'
        },
        name: 'Notice of Tort Options'
    },
    A_PSDS: {
        id: 'A_PSDS',
        bixDocId: {
            PA: 'PA 042021'
        },
        name: 'Surcharge Disclosure'
    },
    A_SMCQ: {
        id: 'A_SMCQ',
        bixDocId: {
            PA: 'PA 042021'
        },
        name: 'State Minimum Coverage Quote'
    },
    B_ELCN: {
        id: 'B_ELCN',
        bixDocId: {
            MI: 'MI 052021'
        },
        name: 'Extraordinary Life Event Notice'
    },
    // Home Policy Jacket
    H_AMND: {
        id: 'H_AMND',
        name: 'Amendatory Endorsement',
        bixDocId: {
            AZ: 'AZ 012021',
            IN: 'CW 042020',
            OH: 'OH 042020'
        },
        docId: {
            AZ: 'AZ 122018',
            IL: 'IL 102019',
            MO: 'MO 122018',
            OH: 'OH 122018'
        }
    },
    H_ADDI: {
        id: 'H_ADDI',
        bixDocId: {
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AL: 'CW 122018',
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            TX: 'CW 122018'
        },
        name: 'Additional Insureds Endorsement'
    },
    H_ADNO: {
        id: 'H_ADNO',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 082021',
            WI: 'CW 042020'
        },
        docId: {
            AL: 'CW 122018'
        },
        name: 'Additional Insured Non Occupant (Designated Premises Only)'
    },
    H_AANR: {
        id: 'H_AANR',
        bixDocId: {
            AZ: 'CW 042020',
            CO: 'CW 122018',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'CW 042020',
            PA: 'CW 042020',
            TX: 'CW 042020',
            UT: 'CW 042020'
        },
        name: 'Important information about your credit based score notice'
    },
    H_AATU: { id: 'H_AATU', name: 'Adverse Underwriting' },
    H_ADNR: {
        id: 'H_ADNR',
        name: 'Additional Insured Non-Relative',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'CW 042020',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 082021',
            WI: 'CW 042020'
        },
        docId: {
            AL: 'CW 122018',
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'CW 122018'
        }
    },
    H_ADTR: {
        id: 'H_ADTR',
        name: 'Additional Insured Trust',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'MI 042021',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 072021',
            UT: 'UT 092021',
            WI: 'WI 102020'
        },
        docId: {
            AL: 'CW 122018',
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'CW 122018'
        }
    },
    H_ANME: {
        id: 'H_ANME',
        name: 'Animal Liability Exclusion',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'MI 052021',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'IL 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'CW 122018'
        }
    },
    H_ANML: {
        id: 'H_ANML',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'MI 052021',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'CW 122018'
        },
        name: 'Animal Liability Limitation Endorsement'
    },
    H_BMTF: {
        id: 'H_BMTF',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'CW 042020',
            TX: 'CW 042020',
            UT: 'CW 082021',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'CW 122018'
        },
        name: 'Building Materials Theft Endorsement'
    },
    H_CAMS: {
        id: 'H_CAMS',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'CW 122018'
        },
        name: 'Additional Coverage On Cameras'
    },
    H_CONS: {
        id: 'H_CONS',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'CW 122018'
        },
        name: 'Contract of Sale Clause'
    },
    H_DCAR: {
        id: 'H_DCAR',
        name: 'Home Day Care Endorsement',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'MD 092020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 092020',
            UT: 'CW 082021',
            WI: 'CW 042020'
        },
        docId: {
            AL: 'AL 032021',
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 122019'
        }
    },
    H_DCOC: {
        id: 'H_DCOC',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 082021',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CO 102020',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'CW 122018'
        },
        name: 'Dwelling in the Course of Construction Endorsement'
    },
    H_DPRO: {
        id: 'H_DPRO',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'CW 122018'
        },
        name: 'Damage To Property Of Others'
    },
    H_ELDR: {
        id: 'H_ELDR',
        name: 'Electronic Data Recovery Expense Endorsement',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 082021',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'MO 122018',
            OH: 'CW 122018'
        }
    },
    H_EPHP: {
        id: 'H_EPHP',
        name: 'Home Sharing Protection Extended Premesis',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 092020',
            UT: 'CW 082021',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 122019'
        }
    },
    H_EXTP: {
        id: 'H_EXTP',
        name: 'Extended Premises Endorsement',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 092020',
            UT: 'CW 082021',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CO 102020',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 122019'
        }
    },
    H_FLOD: {
        id: 'H_FLOD',
        name: 'Flood Notice',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 072021',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 012020'
        }
    },
    H_HPAE: {
        id: 'H_HPAE',
        name: 'Host Protection Amendatory Endorsement',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 092020',
            UT: 'CW 082021',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 122019'
        }
    },
    H_IB10: {
        id: 'H_IB10',
        name: 'Increased Building Structure Protection 10% Endorsement',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 092020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CO 102020',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 122019'
        }
    },
    H_IB20: {
        id: 'H_IB20',
        name: 'Increased Building Structure Protection 20% Endorsement',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 092020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CO 102020',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 122019'
        }
    },
    H_IB40: {
        id: 'H_IB40',
        name: 'Increased Building Structure Protection 40% Endorsement',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 092020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CO 102020',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 122019'
        }
    },
    H_LOSP: {
        id: 'H_LOSP',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AL: 'CW 122018',
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 122019'
        },
        name: 'Loss Payable Clause'
    },
    H_MINE: {
        id: 'H_MINE',
        name: 'Mine Subsidence Coverage Endorsement',
        bixDocId: {
            IN: 'IN 112020',
            OH: 'OH 042020'
        },
        docId: {
            IL: 'IL 122018',
            OH: 'CW 122018'
        }
    },
    H_MNSF: {
        id: 'H_MNSF',
        name: 'Mine Subsidence Selection Rejection Form',
        bixDocId: {
            IN: 'IN 122020'
        }
    },
    H_MUSI: {
        id: 'H_MUSI',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CO 102020',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 122019'
        },
        name: 'Additional Coverage On Musical Instruments'
    },
    H_NRCE: {
        id: 'H_NRCE',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'CW 042020',
            PA: 'CW 042020',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CO 102020',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 122019'
        },
        name: 'Important Notice'
    },
    H_SRCE: {
        id: 'H_SRCE',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'CW 042020',
            PA: 'CW 042020',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CO 102020',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 122019'
        },
        name: 'Important Notice'
    },
    H_IRCE: {
        id: 'H_IRCE',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'CW 042020',
            PA: 'CW 042020',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        name: 'Important Notice'
    },
    H_OFAC: {
        id: 'H_OFAC',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'CW 042020',
            PA: 'CW 042020',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        name: 'Assets Control Advisory Notice'
    },
    H_OMSI: {
        id: 'H_OMSI',
        bixDocId: {
            IN: 'IN 112020',
            OH: 'OH 042020'
        },
        name: 'Mine Subsidence Renewal Offer'
    },
    H_PJAK: {
        id: 'H_PJAK',
        name: 'Home Policy Jacket',
        bixDocId: {
            AZ: 'AZ 012021',
            IN: 'IN 122020',
            MD: 'MD 032021',
            MI: 'MI 052021',
            NE: 'NE 072021',
            OH: 'CW 042020',
            OK: 'OK 032021',
            PA: 'PA 062021',
            TX: 'TX 112020',
            UT: 'UT 112021',
            WI: 'WI 052021'
        },
        docId: {
            AL: 'AL 032021',
            AZ: 'AZ 102020',
            CO: 'CO 102020',
            IL: 'IL 102020',
            KS: 'KS 032021',
            MO: 'CW 082019',
            OH: 'CW 102020',
            TX: 'TX 102020'
        }
    },
    H_PLCY: {
        id: 'H_PLCY',
        name: 'Homeowners Policy',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'IN 122020',
            MD: 'MD 032021',
            MI: 'MI 052021',
            NE: 'NE 072021',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 062021',
            TX: 'TX 072021',
            UT: 'UT 112021',
            WI: 'WI 052021'
        },
        docId: {
            AL: 'AL 032021',
            AZ: 'CW 122018',
            CO: 'CO 102020',
            IL: 'CW 122018',
            KS: 'KS 032021',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 012020'
        }
    },
    H_PUMB: {
        id: 'H_PUMB',
        name: 'Personal Umbrella Liability Endorsement',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'IN 121820',
            MD: 'MD 102021',
            MI: 'MI 042021',
            NE: 'NE 072021',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 072021',
            UT: 'UT 092021',
            WI: 'WI 102020'
        },
        docId: {
            AL: 'AL 032021',
            AZ: 'CW 122018',
            IL: 'IL 122018',
            KS: 'KS 032021',
            MO: 'MO 122018',
            OH: 'CW 122018',
            TX: 'TX 012020'
        }
    },
    H_RENI: {
        id: 'H_RENI',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CO 102020',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'CW 122018'
        },
        name: 'Fair Rental Income Endorsement'
    },
    H_RCOM: {
        id: 'H_RCOM',
        name: 'Roof Surfaces Notice',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 112020',
            MD: 'CW 122018',
            MI: 'CW 122018',
            NE: 'CW 122018',
            OH: 'CW 082020',
            OK: 'CW 122018',
            PA: 'CW 122018',
            TX: 'CW 120218',
            UT: 'CW 122018',
            WI: 'CW 122018'
        },
        docId: {
            AZ: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'CW 122018'
        }
    },
    H_ROFE: {
        id: 'H_ROFE',
        name: 'Roof Surfaces Extended Coverage Endorsement',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'MD 092020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 092020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CO 102020',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 122019'
        }
    },
    H_ROF1: {
        id: 'H_ROF1',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        name: ''
    },
    H_ROFM: {
        id: 'H_ROFM',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'CW 122018'
        },
        name: 'Metal Roof Surface Cosmetic Damage Exclusion'
    },
    H_ROFR: {
        id: 'H_ROFR',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'CW 122018'
        },
        name: 'Metal Roof Surface Cosmetic Damage Exclusion'
    },
    H_ROOF: {
        id: 'H_ROOF',
        name: 'Roof Surfaces Endorsement',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 092020',
            UT: 'CW 082021',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 122019'
        }
    },
    H_ROOS: {
        id: 'H_ROOS',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'CW 042020',
            PA: 'CW 042020',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'CW 122018'
        },
        name: 'Important Notice'
    },
    H_ROSE: {
        id: 'H_ROSE',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'CW 042020',
            PA: 'CW 112020',
            TX: 'CW 042020',
            UT: 'CW 112020',
            WI: 'CW 042020'
        },
        name: 'Important Notice'
    },
    H_SCOR: {
        id: 'H_SCOR',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'CW 122018'
        },
        name: 'Secondary Residence'
    },
    H_SFPP: {
        id: 'H_SFPP',
        bixDocId: {
            NE: 'NE 072021',
            OK: 'OK 022021'
        },
        name: 'Standard Fire Policy Provision'
    },
    H_SPPE: {
        id: 'H_SPPE',
        name: 'Scheduled Personal Property',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 092020',
            UT: 'CW 102021',
            WI: 'CW 042020'
        },
        docId: {
            AL: 'CW 062021',
            AZ: 'CW 122018',
            CO: 'CO 102020',
            IL: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 122019'
        }
    },
    H_SPRT: {
        id: 'H_SPRT',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        name: 'Additional Coverage On Sports Equpment '
    },
    H_WNHD: {
        id: 'H_WNHD',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 092020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 042020',
            WI: 'CW 042020'
        },
        name: 'Windstorm and Hail Deductible Endorsement'
    },
    H_WNEX: {
        id: 'H_WNEX',
        name: 'Windstorm and Hail Exclusion',
        bixDocId: {
            TX: 'TX 092020'
        },
        docId: {
            TX: 'TX 122018'
        }
    },
    H_WTBU: {
        id: 'H_WTBU',
        name: 'Water Backup Endorsement',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 092020',
            UT: 'CW 082021',
            WI: 'CW 042020'
        },
        docId: {
            AZ: 'CW 122018',
            CO: 'CO 102020',
            IL: 'CW 122018',
            KS: 'CW 122018',
            MO: 'CW 122018',
            OH: 'CW 122018',
            TX: 'TX 112019'
        }
    },
    H_YARD: {
        id: 'H_YARD',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MD: 'CW 042020',
            MI: 'CW 042020',
            NE: 'CW 042020',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'CW 042020',
            UT: 'CW 092021',
            WI: 'CW 042020'
        },
        docId: {
            AL: 'AL 032021'
        },
        name: 'Additional Coverage for Yard and Garden'
    },
    H_DISC: {
        id: 'H_DISC',
        docId: {
            CO: 'CO 032021'
        },
        name: 'Colorado Specific DISC'
    },
    H_LOSS: {
        id: 'H_LOSS',
        docId: {
            CO: 'CO 032021'
        },
        name: 'Colorado Specific LOSS'
    },
    H_PRMM: {
        id: 'H_PRMM',
        bixDocId: {
            MD: 'MD 032021'
        },
        name: 'Important Notice'
    },
    H_CLAM: {
        id: 'H_CLAM',
        bixDocId: {
            MD: 'MD 012021'
        },
        name: 'Information Regarding Claims'
    },
    H_RECA: {
        id: 'H_RECA',
        bixDocId: {
            MD: 'MD 022021'
        },
        name: 'Notice of Recalculation of Premium'
    },
    H_REDU: {
        id: 'H_REDU',
        bixDocId: {
            MD: 'MD 022021'
        },
        name: 'Reduction in Coverage Notice'
    },
    H_CSDF: {
        id: 'H_CSDF',
        bixDocId: {
            MD: 'MD 092020'
        },
        name: 'Coverage Summary Disclosure'
    },
    H_WINN: {
        id: 'H_WINN',
        bixDocId: {
            MD: 'MD 012021'
        },
        name: 'Windstorm Protective Device Notice'
    },
    H_SBCF: {
        id: 'H_SBCF',
        bixDocId: {
            MD: 'MD 092020'
        },
        name: 'Sewer Backup'
    },
    H_SBIL: {
        id: 'H_SBIL',
        bixDocId: {
            MD: 'MD 092020'
        },
        name: 'Sewer Backup Increased Limits'
    },
    H_SINK: {
        id: 'H_SINK',
        bixDocId: {
            PA: 'PA 042021'
        },
        name: 'Sinkhole Collapse Endorsement'
    },
    H_OSTA: {
        id: 'H_OSTA',
        bixDocId: {
            PA: 'PA 042021'
        },
        name: 'Oil Storage Tank(s) Endorsement'
    },
    H_SPIN: {
        id: 'H_SPIN',
        name: 'Premium Increase Notice'
    },
    // Generic Policy Jacket
    B_CMPL: {
        id: 'B_CMPL',
        bixDocId: {
            IN: 'IN 112020'
        },
        name: 'Complaint Notice'
    },
    B_PRIV: {
        id: 'B_PRIV',
        bixDocId: {
            AZ: 'CW 042020',
            IN: 'CW 042020',
            MI: 'MI 072021',
            OH: 'CW 042020',
            OK: 'CW 042020',
            PA: 'CW 042020',
            TX: 'CW 042020',
            WI: 'CW 042020'
        },
        docId: {
            CO: 'CW 102020'
        },
        name: 'Privacy Statement'
    },
    B_SASP: { id: 'B_SASP', name: 'Subscriber Agreement Signature Page' },
    B_NIMP: {
        id: 'B_NIMP',
        name: 'Information',
        bixDocId: {
            TX: 'TX 072021'
        },
        docId: {
            TX: 'TX 012020'
        }
    },
    H_WHXX: {
        id: 'H_WHXX',
        bixDocId: {
            TX: 'TX 072021'
        },
        name: 'Wind/Hail Exclusion Form'
    },
    H_NEXL: {
        id: 'H_NEXL',
        name: 'Information',
        bixDocId: {
            TX: 'TX 072021'
        },
        docId: {
            TX: 'TX 122019'
        }
    },
    H_CBRN: {
        id: 'H_CBRN',
        name: 'Information',
        bixDocId: {
            TX: 'TX 062021'
        },
        docId: {
            TX: 'TX 062021'
        }
    },
    H_WHEX: {
        id: 'H_WHEX',
        name: 'Windstorm and Hail Exclusion',
        bixDocId: {
            TX: 'TX 092020'
        },
        docId: {
            AL: 'AL 032021',
            TX: 'TX 122018'
        }
    },
    H_FROF: {
        id: 'H_FROF',
        name: 'Fortified Roof Endorsement',
        docId: {
            AL: 'AL 032021'
        }
    },
    H_OLCL: {
        id: 'H_OLCL',
        name: 'Residential Property Insurance Outline',
        docId: {
            AL: 'AL 032021'
        }
    },
    H_RTNO: {
        id: 'H_RTNO',
        name: 'Rating Information Notice',
        docId: {
            TX: 'MI 042021'
        }
    },
    B_OFAC: { id: 'B_OFAC', name: 'OFAC Statement' },
    // FCRA Notice
    H_CYIR: { id: 'H_CYIR', name: '' },
    H_AAVK: { id: 'H_AAVK', name: 'Adverse Underwriting' },
    H_AUVK: { id: 'H_AUVK', name: 'Adverse Underwriting' },
    A_AUVK: { id: 'A_AUVK', name: 'Adverse Underwriting' },
    A_AUTU2: { id: 'A_AUTU2', name: 'Adverse Underwriting' },
    // Cancellation
    H_CNRN: { id: 'H_CNRN', name: 'Cancellation notice' },
    H_CNRS: {
        id: 'H_CNRS',
        name: 'Cancellation notice',
        bixDocId: {
            AZ: 'AZ 012021',
            OH: 'OH 042020',
            TX: 'TX 072021',
            MD: 'MD 012021',
            MI: 'MI 072021'
        },
        docId: {
            AZ: 'AZ 122018',
            IL: 'IL 122019',
            MO: 'MO 092019',
            OH: 'OH 122018',
            TX: 'TX 012020',
            CO: 'CO 102020'
        }
    },
    H_CNPY: {
        id: 'H_CNPY',
        name: 'Cancellation notice',
        bixDocId: {
            OH: 'OH 042020',
            MD: 'MD 012021',
            PA: 'PA 052021'
        },
        docId: {
            OH: 'OH 122018',
            AZ: 'AZ 122018',
            IL: 'IL 122019',
            MO: 'MO 092019',
            TX: 'TX 012020'
        }
    },
    H_NRRS: {
        id: 'H_NRRS',
        name: 'Cancellation notice',
        bixDocId: {
            CW: 'CW 022021',
            MI: 'MI 052021'
        },
        docId: {
            CW: 'CW 122018'
        }
    },
    H_NULL: {
        id: 'H_NULL',
        name: 'Cancellation notice',
        bixDocId: {
            CW: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 052021'
        },
        docId: {
            CW: 'CW 122018'
        }
    },
    A_CNRS: {
        id: 'A_CNRS',
        name: 'Cancellation notice',
        bixDocId: {
            TX: 'TX 072021',
            AZ: 'AZ 012021',
            MD: 'MD 012021',
            MI: 'MI 042021'
        },
        docId: {
            IL: 'IL 122019',
            MO: 'MO 102019',
            TX: 'TX 012020',
            CO: 'CO 102020'
        }
    },
    A_CNPY: {
        id: 'A_CNPY',
        name: 'Cancellation notice',
        bixDocId: {
            OH: 'OH 042020',
            AZ: 'AZ 012021',
            MD: 'MD 012021',
            MI: 'MI 042021'
        },
        docId: {
            OH: 'OH 042020',
            AZ: 'AZ 122018',
            MO: 'MO 102019',
            TX: 'TX 012020'
        }
    },
    A_NRRS: {
        id: 'A_NRRS',
        name: 'Cancellation notice',
        bixDocId: {
            CW: 'CW 022021',
            OH: 'OH 122018',
            // COREY TODO: TX has placeholder
            TX: 'CW 022021',
            AZ: 'AZ 012021',
            OK: 'CW 022021',
            MD: 'MD 012021',
            MI: 'MI 052021',
            IN: 'IN 122020'
        },
        docId: {
            CW: 'CW 122018',
            OH: 'OH 042020',
            IL: 'IL 012020',
            MO: 'MO 102019',
            TX: 'TX 012020',
            // COREY TODO: CO has placeholder
            CO: 'CW 022021',
            KS: 'KS 032021'
        }
    },
    A_NULL: {
        id: 'A_NULL',
        name: 'Cancellation notice'
    },
    H_RTNC: {
        id: 'H_RTNC',
        name: 'Renewal Terms and Conditions',
        bixDocId: {
            AZ: 'CW 022021',
            IN: 'IN 121820',
            MI: 'MI 072021',
            OH: 'CW 042020',
            OK: 'OK 022021',
            PA: 'PA 042021',
            TX: 'TX 072021',
            WI: 'WI 052021'
        },
        docId: {
            AL: 'AL 102021',
            AZ: 'CW 062419',
            CO: 'CO 102020',
            IL: 'IL 032020',
            MO: 'CW 032020',
            OH: 'OH 112019',
            TX: 'TX 032020'
        }
    },
    H_NRBIX: {
        id: 'H_NRBIX',
        name: 'Insurance Cancellation Notice'
    },
    A_NRBIX: {
        id: 'A_NRBIX',
        name: 'Insurance Cancellation Notice'
    }
};
