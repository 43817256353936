import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const PotentialCustomerMatches = ({ matchCustomer, matchOffer }) => {
  return (
    <Grid container>
      {matchCustomer}
      {matchOffer}
    </Grid>
  );
};

PotentialCustomerMatches.propTypes = {
  matchCustomer: PropTypes.element.isRequired,
  matchOffer: PropTypes.element.isRequired
};

export default PotentialCustomerMatches;
