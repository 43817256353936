import React, { memo, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import flowRight from 'lodash-es/flowRight';
import { withStyles } from '@material-ui/core/styles';
import { lookupsJson, getOptions, vehicleEquipmentStatusCode, annualMilesDrivenLimits } from '@ourbranch/lookups';
import { observer } from 'mobx-react';

import Field from 'core/components/form/form.v2';
import withDatePicker from 'core/components/with-date-picker';
import { useStore } from 'core/store';
import { tooltipHoverTexts } from 'core/helpers/constants';
import { numberFormatter } from 'core/helpers/formatters';
import { LabelTooltip } from 'core/components/label-tooltip';
import { AuthContext } from 'core/components/auth';
import { AddCarDetails } from './add-car-details';
import { Label } from '../../../../core/components/label';
import CollapsibleCard from '../../../../core/components/collapsible-card';
import RemoveButton from '../../../../core/components/remove-button';
import styles from './car.styles';

export const requiredCarFields = [
  'deductibleCollision',
  'deductibleComprehensive',
  'limitRental',
  'limitUMPD',
  'limitACPE'
];

const getCarHeader = (item) => {
  const header = `${item.year || ''} ${item.make || ''} `.concat(
    item.year || item.model || item.make ? ` ${item.model || ''}` : ` ${item.model || 'New Car'}`
  );
  return header;
};

const Car = observer(({ fieldName, classes, item = {}, index, removeFromList, onRemove, disabled, fromPolicy }) => {
  const {
    offer: offerStore,
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();
  const displayLeaseLoan = fromPolicy ? policyStore?.showLeaseLoan : offerStore?.showLeaseLoan;

  const { setTouched, errors, setFieldValue } = useFormikContext();

  const { canAddCarsManually } = useContext(AuthContext);

  useEffect(() => {
    if (Object.keys(errors).length) {
      setTouched(errors);
    }
  }, [errors, setTouched]);

  const state = item?.garageLocation?.state || null;

  const {
    aebStatus,
    antiTheftStatus,
    blindSpotStatus,
    singleAirbagStatus,
    doubleAirbagStatus,
    deductibleCollision,
    deductibleComprehensive
  } = item;

  // build the header string dynamically
  const getHeaderStatuses = useMemo(() => {
    const buildLabelText = (statuses, type) => {
      const statusLabels = {
        aebStatus: 'Automatic Emergency Braking',
        antiTheftStatus: 'Anti-theft Device',
        blindSpotStatus: 'Blind Spot Warning System',
        singleAirbagStatus: 'Single Airbag',
        doubleAirbagStatus: 'Dual Airbags'
      };

      const statusType =
        type === 'standard' ? vehicleEquipmentStatusCode.STANDARD : vehicleEquipmentStatusCode.OPTIONAL;

      const typeText = type === 'standard' ? 'Equipped with Standard: ' : 'Optionally Equipped with: ';

      return Object.entries(statuses)
        .filter((entry) => String(entry[1]) === String(statusType))
        .reduce((statusText, [status, value], idx, arr) => {
          const lastItem = idx === arr.length - 1;
          if (!statusText) {
            // Empty accumulator
            return `${statusText} ${typeText} ${statusLabels[status]}${lastItem ? '.' : ''}`;
          }
          if (lastItem) {
            // last standard or optional item
            return `${statusText}, and ${statusLabels[status]}.`;
          }
          return `${statusText}, ${statusLabels[status]}`;
        }, '');
    };

    const statuses = { aebStatus, antiTheftStatus, blindSpotStatus, singleAirbagStatus, doubleAirbagStatus };
    return buildLabelText(statuses, 'standard').concat(buildLabelText(statuses, 'optional'));
  }, [antiTheftStatus, aebStatus, blindSpotStatus, singleAirbagStatus, doubleAirbagStatus]);

  const leaseLoanAvailable = useMemo(() => {
    // lease loan in all BIX and all GSNIC that isn't our original five
    return deductibleCollision !== 'NONE' && deductibleComprehensive !== 'NONE' && displayLeaseLoan;
  }, [deductibleCollision, deductibleComprehensive, displayLeaseLoan]);

  useEffect(() => {
    if (!leaseLoanAvailable) {
      setFieldValue(`${fieldName}.coverageLoan`, false);
    }
  }, [leaseLoanAvailable, fieldName, setFieldValue]);

  // blind spot coverage only in indiana
  const blindSpotAvailable = ['IN', 'PA'].includes(state);

  // policy-level UMPD should block showing UMPD:
  const showCarLevelUMPD = !['AL', 'AZ', 'IN', 'MI', 'MO', 'NE', 'OK', 'PA', 'TX', 'WI'].includes(state);

  const annualMilesDrivenOptions = useMemo(() => {
    const config = annualMilesDrivenLimits[state];
    if (annualMilesDrivenLimits[state]) {
      const { max, step } = config;
      return [
        {
          id: -1,
          value: 'Unknown'
        },
        ...Array.from({ length: Math.floor(max / step) }, (_, ix) => {
          const upper = (ix + 1) * step;
          const lower = ix ? upper - (step - 1) : upper - step;
          return {
            id: upper,
            value: `${numberFormatter(lower)}-${numberFormatter(upper)}`
          };
        }),
        {
          id: max + 1,
          value: `Over ${numberFormatter(max)}`
        }
      ];
    }
    return null;
  }, [state]);

  return (
    <CollapsibleCard
      content={
        <div className={classes.header}>
          <Label type="infoSubtitle">{getCarHeader(item)}</Label>
          <Label type="infoLabel" className={classes.headerStatus}>
            {getHeaderStatuses}
          </Label>
        </div>
      }
      contentRight={<RemoveButton disabled={disabled} mode="big" onClick={() => onRemove(index, removeFromList)} />}
      initialOpen={item.initialOpen}
    >
      <Form>
        <Grid spacing={4} container justify="space-between" className={classes.cardBody}>
          <Field id={`${fieldName}.VIN`} name={`${fieldName}.VIN`} type="string" label="VIN" mode="light" xs={4} />
          <Field
            id={`${fieldName}.primaryUse`}
            name={`${fieldName}.primaryUse`}
            type="select"
            label="Primary Use"
            mode="light"
            xs={4}
            options={lookupsJson.primaryUse}
          />
          <Field
            id={`${fieldName}.purchaseDate`}
            name={`${fieldName}.purchaseDate`}
            type="date"
            label="Purchase Date"
            mode="light"
            xs={4}
          />
          <Field
            id={`${fieldName}.garageLocation.address`}
            name={`${fieldName}.garageLocation.address`}
            type="string"
            label="Garage Location"
            mode="light"
            xs={8}
          />
          <Field
            id={`${fieldName}.garageLocation.address2`}
            name={`${fieldName}.garageLocation.address2`}
            type="string"
            label="Address 2"
            mode="light"
            xs={4}
          />
          <Field
            id={`${fieldName}.garageLocation.city`}
            name={`${fieldName}.garageLocation.city`}
            type="string"
            label="City"
            mode="light"
            xs={4}
          />
          <Field
            id={`${fieldName}.garageLocation.state`}
            name={`${fieldName}.garageLocation.state`}
            type="autocomplete"
            label="State"
            mode="light"
            xs={4}
            options={lookupsJson.usStates}
            stateSelectForm
          />
          <Field
            id={`${fieldName}.garageLocation.zip`}
            name={`${fieldName}.garageLocation.zip`}
            type="string"
            label="Zip Code"
            mode="light"
            xs={4}
          />
          {canAddCarsManually && <AddCarDetails fieldName={fieldName} classes={classes} />}
        </Grid>
        <Grid spacing={4} container justify="space-between" className={classes.containerDark} key={state}>
          <Grid item xs={4}>
            <LabelTooltip
              label="Collision Deductible"
              tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.deductibleCollision }}
            >
              <Field
                id={`${fieldName}.deductibleCollision`}
                name={`${fieldName}.deductibleCollision`}
                type="select"
                mode="dark"
                // how do we change this over to lookupsJson?
                options={getOptions('deductibleCollision', state)}
              />
            </LabelTooltip>
          </Grid>

          <Grid item xs={4}>
            <LabelTooltip
              label="Comprehensive Deductible"
              tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.deductibleComprehensive }}
            >
              <Field
                id={`${fieldName}.deductibleComprehensive`}
                name={`${fieldName}.deductibleComprehensive`}
                type="select"
                mode="dark"
                // how do we change this over to lookupsJson?
                options={getOptions('deductibleComprehensive', state)}
              />
            </LabelTooltip>
          </Grid>

          <Grid item xs={4}>
            <LabelTooltip
              label="Rental Car Expense"
              tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.limitRental }}
            >
              <Field
                id={`${fieldName}.limitRental`}
                name={`${fieldName}.limitRental`}
                type="select"
                mode="dark"
                // how do we change this over to lookupsJson?
                options={getOptions('limitRental', state)}
              />
            </LabelTooltip>
          </Grid>

          {showCarLevelUMPD && (
            <Grid item xs={6}>
              <LabelTooltip
                label="Uninsured Motorist Property Damage Limit"
                tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.limitUMPD }}
              >
                <Field
                  id={`${fieldName}.limitUMPD`}
                  name={`${fieldName}.limitUMPD`}
                  type="select"
                  mode="dark"
                  // how do we change this over to lookupsJson?
                  options={getOptions('limitUMPD', state)}
                />
              </LabelTooltip>
            </Grid>
          )}

          <Field
            id={`${fieldName}.limitACPE`}
            name={`${fieldName}.limitACPE`}
            type="select"
            label="Additional Custom Parts Limit"
            mode="dark"
            xs={6}
            // how do we change this over to lookupsJson?
            options={getOptions('limitACPE', state)}
          />

          {annualMilesDrivenOptions && (
            <Grid item xs={12}>
              <Field
                id={`${fieldName}.annualMilesDriven`}
                name={`${fieldName}.annualMilesDriven`}
                type="select"
                label="Annual Miles Driven"
                mode="dark"
                xs={6}
                options={annualMilesDrivenOptions}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Field
              id={`${fieldName}.roadsideAssistance`}
              name={`${fieldName}.roadsideAssistance`}
              type="checkbox"
              mode="dark"
              label="With Roadside Assistance Coverage"
              tooltipText={tooltipHoverTexts.roadsideAssistance}
              tooltipLabel="More Info"
            />
          </Grid>

          <Grid item xs={6}>
            <Field
              id={`${fieldName}.rideSharing`}
              name={`${fieldName}.rideSharing`}
              type="checkbox"
              label="With Rideshare Coverage"
              mode="dark"
              tooltipText={tooltipHoverTexts.rideSharing}
              tooltipLabel="More Info"
            />
          </Grid>

          {displayLeaseLoan && (
            <Grid item xs={12}>
              <Field
                id={`${fieldName}.coverageLoan`}
                name={`${fieldName}.coverageLoan`}
                type="checkbox"
                label="With Lease/Loan Coverage"
                mode="dark"
                disabled={!leaseLoanAvailable}
                fast={false}
                tooltipText={tooltipHoverTexts.coverageLoan}
                tooltipLabel="More Info"
                xs={6}
              />
            </Grid>
          )}

          {aebStatus === vehicleEquipmentStatusCode.OPTIONAL && (
            <Field
              id={`${fieldName}.aebConfirmed`}
              name={`${fieldName}.aebConfirmed`}
              type="checkbox"
              label="With Automatic Emergency Braking"
              mode="dark"
              xs={6}
            />
          )}
          {antiTheftStatus === vehicleEquipmentStatusCode.OPTIONAL && (
            <Field
              id={`${fieldName}.antiTheftConfirmed`}
              name={`${fieldName}.antiTheftConfirmed`}
              type="checkbox"
              label="With Anti-Theft Device"
              mode="dark"
              xs={6}
            />
          )}

          {blindSpotAvailable && blindSpotStatus === vehicleEquipmentStatusCode.OPTIONAL && (
            <Field
              id={`${fieldName}.blindSpotConfirmed`}
              name={`${fieldName}.blindSpotConfirmed`}
              type="checkbox"
              label="With Blind Spot Warning System"
              mode="dark"
              xs={6}
            />
          )}
        </Grid>
      </Form>
    </CollapsibleCard>
  );
});

Car.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  fieldId: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  item: PropTypes.shape({
    VIN: PropTypes.string,
    addDate: PropTypes.string,
    bodyType: PropTypes.string,
    confirmed: PropTypes.bool,
    deductibleCollision: PropTypes.string,
    deductibleComprehensive: PropTypes.string,
    coverageLoan: PropTypes.bool,
    garageLocation: PropTypes.object,
    limitACPE: PropTypes.string,
    limitRental: PropTypes.string,
    limitUMPD: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    primaryUse: PropTypes.string,
    purchaseDate: PropTypes.string,
    roadsideAssistance: PropTypes.bool,
    year: PropTypes.number,
    aebStatus: PropTypes.oneOf([null, ...Object.values(vehicleEquipmentStatusCode)]),
    antiTheftStatus: PropTypes.oneOf([null, ...Object.values(vehicleEquipmentStatusCode)]),
    singleAirbagStatus: PropTypes.oneOf([null, ...Object.values(vehicleEquipmentStatusCode)]),
    doubleAirbagStatus: PropTypes.oneOf([null, ...Object.values(vehicleEquipmentStatusCode)]),
    aebConfirmed: PropTypes.bool,
    antiTheftConfirmed: PropTypes.bool,
    singleAirbagConfirmed: PropTypes.bool,
    doubleAirbagConfirmed: PropTypes.bool,
    annualMilesDriven: PropTypes.oneOf([null, PropTypes.number]),
    symbolMake: PropTypes.string,
    symbolModel: PropTypes.string,
    symbolStyle: PropTypes.string,
    symbolAux: PropTypes.string
  }).isRequired,
  index: PropTypes.number.isRequired,
  removeFromList: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  offer: PropTypes.object,
  policy: PropTypes.object
};

Car.defaultProps = {
  disabled: false,
  offer: {},
  policy: {}
};

export default flowRight(withStyles(styles), withDatePicker)(memo(Car));
