import * as Yup from 'yup';
import shouldBeAValidState from '../../../common/helpers/yup-valid-state';

Yup.addMethod(Yup.mixed, 'shouldBeAValidState', shouldBeAValidState);

const schema = Yup.object().shape({
  fname: Yup.string().required('First Name is required!'),
  lname: Yup.string().required('Last Name is required!'),
  mailingAddress: Yup.object().shape({
    address: Yup.string().required('Address is required!'),
    address2: Yup.string().nullable(),
    city: Yup.string().required('City is required!'),
    state: Yup.string().required('State is requied!').shouldBeAValidState('account-details.state'),
    zip: Yup.string().required('Zip is required!')
  }),
  email: Yup.string().email().required('Email is required!'),
  phoneNumber: Yup.string().required('Phone is required!'),
  additionalPhoneNumbers: Yup.array(
    Yup.object().shape({
      phoneNumber: Yup.string().required('Required or click trash to remove').min(10, 'Must be at least 10 digits'),
      note: Yup.string().max(50, 'Must be less than 50 characters').nullable()
    })
  ).nullable()
});

export default schema;
