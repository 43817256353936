import { differenceInDays, isAfter } from 'date-fns';
import { types, flow, getEnv } from 'mobx-state-tree';
import { v4 as uuid } from 'uuid';
import feesHelper from '@ourbranch/fee-utils';
import { advancedConnectedHomeStates, leaseLoanStates } from '@ourbranch/lookups';

import { getMerged } from 'customer/components/policy/merge-segments';
import { types as GQLTypes } from 'core/helpers/sanitize';
import {
  REMOVE_HOLD_CARD,
  ADD_HOLD_CARD,
  GET_HOLD_CARDS,
  PREVIEW_POLICY_CHANGE_SCRUB_INCIDENTS,
  GET_RENEWAL_POLICY_ID,
  CHANGE_POLICY,
  CHANGE_SEGMENT,
  ADD_SEGMENT,
  CONFIRM,
  RECREATE_APPLICATION,
  ORDER_INSPECTION,
  GET_INSPECTION_STATUS,
  GET_CD_DIFF,
  GET_BIX_CONVERSION_SIGNATURE_FROM_TABLE
} from 'customer/components/policy/policy.queries';
import { RUN_MANUAL_CHARGE, MODIFY_CREDIT_CARD } from 'customer/components/policy/payment-tab/payment.queries';
import { currencyFormatter, awsDateToDateFormatter } from 'core/helpers/formatters';
import Fee from './models/fee';
import PolicyEquityStatus from './models/policy-equity-status';
import BillingDetails from './models/billing-details';

export const PolicyStore = types
  .model({
    segments: types.array(types.frozen()),
    changed: types.optional(types.boolean, false),
    segment: types.maybeNull(types.frozen()),
    segmentId: types.maybeNull(types.string),
    policy: types.maybeNull(types.frozen()),
    loading: types.optional(types.boolean, false),
    holdCards: types.array(types.frozen()),
    holdCardLoading: types.optional(types.boolean, false),
    recreatingApplication: types.optional(types.boolean, false),
    canAddMoratorium: types.optional(types.boolean, false),
    billingDetails: types.maybeNull(BillingDetails),
    policyEquityStatus: PolicyEquityStatus,
    fees: types.array(Fee),
    writeOffTransactions: types.array(types.frozen()),
    inspection: types.optional(types.frozen()),
    bixConversionSignedTimestamp: types.maybeNull(types.string)
  })
  // fetching actions
  .actions((self) => ({
    fetchHoldCards: flow(function* fetchHoldCards(policyId) {
      self.holdCardLoading = true;
      const { client } = getEnv(self);
      try {
        const {
          data: { error: holdCardsError, holdcards }
        } = yield client.query({
          query: GET_HOLD_CARDS,
          fetchPolicy: 'network-only',
          variables: {
            policyId
          }
        });
        if (holdCardsError) {
          throw new Error(holdCardsError);
        }
        self.holdCards = holdcards;
        self.canAddMoratorium = true;
      } catch (e) {
        self.holdCardLoading = false;
        throw e;
      }
    }),
    addHoldCard: flow(function* addHoldCard(reason, policyId) {
      self.holdCardLoading = true;
      const { client } = getEnv(self);
      try {
        const res = yield client.mutate({
          mutation: ADD_HOLD_CARD,
          fetchPolicy: 'no-cache',
          variables: {
            policyId,
            reason
          }
        });
        if (res.data.addHoldCard.success) {
          const { createdBy, createdDate, deleted, holdCardId } = res.data.addHoldCard;
          const cards = [...self.holdCards, { createdBy, createdDate, deleted, holdCardId, reason, policyId }];
          self.holdCards = cards;
          self.holdCardLoading = false;
        }
      } catch (e) {
        self.holdCardLoading = false;
        throw e;
      }
    }),
    removeHoldCard: flow(function* removeHoldCard(policyId, holdCardId) {
      try {
        self.holdCardLoading = true;
        const { client } = getEnv(self);
        yield client.mutate({
          mutation: REMOVE_HOLD_CARD,
          variables: { policyId, holdCardId }
        });
        const newHoldCards = self.holdCards.filter((holdCard) => holdCard.holdCardId !== holdCardId);
        self.holdCards = newHoldCards;
        self.holdCardLoading = undefined;
        return true;
      } catch (e) {
        self.holdCardLoading = undefined;
        throw e;
      }
    }),
    changeSegmentMutation: flow(function* changeSegment(policyId, segment) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: CHANGE_SEGMENT,
        variables: {
          policyId,
          segmentId: segment.segmentId,
          segment: GQLTypes.PolicyDetailsInput(segment)
        }
      });
    }),
    confirm: flow(function* confirm(policyId, previewId, internalNotes) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: CONFIRM,
        variables: {
          policyId,
          previewId,
          internalNotes
        }
      });
    }),
    addSegment: flow(function* addSegment(policyId, segment) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: ADD_SEGMENT,
        variables: {
          policyId,
          segment: GQLTypes.PolicyDetailsInput(segment)
        }
      });
    }),
    changePolicy: flow(function* changePolicy(policy) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: CHANGE_POLICY,
        variables: {
          policy: GQLTypes.PolicyInput(policy)
        }
      });
    }),
    runManualCharge: flow(function* runManualCharge(
      policyId,
      accountId,
      paymentMethod,
      amount,
      internalDescription,
      billType
    ) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: RUN_MANUAL_CHARGE,
        variables: {
          policyId,
          accountId,
          paymentMethod,
          amount,
          internalDescription,
          billType
        }
      });
    }),
    changeCreditCard: flow(function* changeCreditCard({ accountId, policyId, stripeToken }) {
      const { client } = getEnv(self);
      return yield client.mutate({
        mutation: MODIFY_CREDIT_CARD,
        variables: {
          policyId,
          accountId,
          stripeToken
        }
      });
    }),
    previewPolicyChangeScrubIncidents: flow(function* previewPolicyChangeScrubIncidents(policy, incidentDates) {
      const { client } = getEnv(self);
      self.loading = true;
      try {
        const { data } = yield client.mutate({
          mutation: PREVIEW_POLICY_CHANGE_SCRUB_INCIDENTS,
          fetchPolicy: 'no-cache',
          variables: {
            policy,
            incidentDates
          }
        });
        self.loading = false;

        return data;
      } catch (e) {
        self.loading = false;
        throw e;
      }
    }),
    getRenewalPolicyId: flow(function* getRenewalPolicyId(policyId) {
      const { client } = getEnv(self);

      const { data } = yield client.query({
        query: GET_RENEWAL_POLICY_ID,
        variables: { policyId }
      });

      return data.getRenewalPolicyId;
    }),
    recreateApplication: flow(function* recreateApplication(policyId) {
      const { client } = getEnv(self);
      self.recreatingApplication = true;

      try {
        const { data } = yield client.mutate({
          mutation: RECREATE_APPLICATION,
          variables: {
            policyId
          }
        });

        return data?.pdfUrl;
      } finally {
        self.recreatingApplication = false;
      }
    }),
    orderInspection: flow(function* orderInspection({
      address,
      fname,
      lname,
      policyId,
      phone,
      mailingAddress,
      coverageA,
      effectiveDate,
      wildfireHazardScore,
      homeAge
    }) {
      const { client } = getEnv(self);
      try {
        const { data } = yield client.mutate({
          mutation: ORDER_INSPECTION,
          variables: {
            address,
            fname,
            lname,
            policyId,
            phone,
            mailingAddress,
            coverageA,
            effectiveDate,
            wildfireHazardScore,
            homeAge
          }
        });

        const { inspection } = yield self.getInspectionStatus({ policyId });
        self.inspection = inspection;

        return data;
      } catch (e) {
        self.loading = false;
        throw e;
      }
    }),
    getInspectionStatus: flow(function* getInspectionStatus({ policyId }) {
      const { client } = getEnv(self);
      try {
        const { data } = yield client.mutate({
          mutation: GET_INSPECTION_STATUS,
          variables: { policyId }
        });
        return data;
      } catch (e) {
        self.loading = false;
        throw e;
      }
    }),
    getClarionDoorRequestDiff: flow(function* getClarionDoorRequestDiff({ policyId, policyPreviewId }) {
      const { client } = getEnv(self);
      try {
        const {
          data: { requests },
          error
        } = yield client.query({
          query: GET_CD_DIFF,
          variables: { policyId, policyPreviewId }
        });
        self.loading = false;
        return { requests, error };
      } catch (e) {
        self.loading = false;
        throw e;
      }
    }),
    getBixConversationSignatureFromTable: flow(function* getBixConversationSignatureFromTable(policyId) {
      const { client } = getEnv(self);
      try {
        const { data } = yield client.query({
          query: GET_BIX_CONVERSION_SIGNATURE_FROM_TABLE,
          variables: {
            policyId
          }
        });

        if (data?.getBixConversationSignatureFromTable?.signedTimestampISO8601) {
          self.bixConversionSignedTimestamp = data.getBixConversationSignatureFromTable.signedTimestampISO8601;
        }
      } catch (e) {
        self.loading = false;
        throw e;
      }
    })
  }))
  // state mutation actions
  .actions((self) => ({
    changeSegment(segmentId) {
      self.segment = getMerged(self.segments, segmentId);
      self.segmentId = segmentId;
      self.loading = false;
    },
    setChanged(changed) {
      self.changed = changed;
    },
    // TODO: Document options
    // TODO: Hook into formik
    addFee(type, options) {
      const protoFee = feesHelper.generateFee(type, options);
      const appliedDate = new Date().toISOString().split('T')[0];
      self.fees.push({ ...protoFee, type, appliedDate, status: 'added' });
    },
    setHoldCards(cards) {
      self.holdCards = cards;
    },
    addCreditCard(card) {
      self.billingDetails.allPaymentMethods.push(card);
    },
    addBankAccount(account) {
      self.billingDetails.allPaymentMethods.push(account);
    }
  }))
  .views((self) => ({
    allActiveHoldCards() {
      const cards = self.holdCards
        .slice()
        .filter((card) => !card.deleted)
        .sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1));
      return cards;
    },
    reversedSegments() {
      return self.segments.slice().reverse();
    }
  }))
  .views((self) => ({
    activeHoldCards() {
      // TODO: Add argument for number of cards returned
      return self.allActiveHoldCards().slice(0, 5);
    },
    getActiveHoldCardsByReason(reason) {
      return self.allActiveHoldCards().filter((card) => card.reason === reason);
    },

    get geographicState() {
      return self.policy?.state;
    },

    get policyType() {
      return self.policy?.policyType;
    },

    get premium() {
      return self.policy?.premium;
    },

    get surplusContribution() {
      return self.policy?.surplusContribution ?? 0.0;
    },

    get totalFeeCost() {
      return self.fees.reduce((total, fee) => total + fee.amount, 0);
    },

    get totalBilled() {
      return self.premium + self.surplusContribution + self.totalFeeCost;
    },

    get writeOffTotal() {
      return self.writeOffTransactions.reduce((total, writeOff) => total + writeOff.amount, 0);
    },

    get totalReceived() {
      const { transactions } = self.billingDetails;

      const paid = transactions?.filter((transaction) =>
        ['paid', 'partially refunded', 'pending'].includes(transaction.paymentStatus)
      );

      return paid
        ? paid.reduce((total, transaction) => transaction.paymentAmount - transaction.paymentRefunded + total, 0) +
            self.writeOffTotal
        : 0;
    },

    get allTransactions() {
      const formattedWriteOffTransactions = self.writeOffTransactions.map((transaction) => ({
        id: uuid(),
        paymentDate: transaction.date,
        paymentAmount: transaction.amount,
        paymentRefunded: 0,
        paymentMethod: transaction.paymentMethod,
        paymentStatus: transaction.paymentStatus,
        internalDescription: transaction.description
      }));

      const transactions = self.billingDetails?.transactions || [];

      return [...transactions, ...formattedWriteOffTransactions];
    },

    get priceBreakdownRows() {
      return [
        {
          rowName: 'Paid',
          costBreakdown: self.policyEquityStatus.paidCostBreakdown
        },
        {
          rowName: 'Remaining',
          costBreakdown: self.policyEquityStatus.remainingCostBreakdown
        },
        {
          rowName: 'Billed',
          costBreakdown: self.policyEquityStatus.billedCostBreakdown
        }
      ];
    },

    get earnedPremiumDays() {
      const { policyDays } = self.policyEquityStatus;

      const daysInForce = differenceInDays(new Date(), new Date(self.policy.effectiveDate));

      // if in the future, return 0
      if (daysInForce <= 0) {
        return 0;
      }

      return daysInForce > policyDays ? policyDays : daysInForce;
    },

    get billedPremiumDays() {
      return Math.round(self.policyEquityStatus.premiumBilled / self.policyEquityStatus.blendedDailyRate);
    },

    get paidPremiumDays() {
      return Math.round(self.policyEquityStatus.remainingAfterFees / self.policyEquityStatus.blendedDailyRate);
    },

    get paidEquityDaysRemaining() {
      const endDate = new Date(self.policy.endDate);
      const effectiveDate = new Date(self.policy.effectiveDate);

      // if in the future, 0
      if (isAfter(effectiveDate, new Date())) {
        return 0;
      }

      return isAfter(new Date(), endDate) ? 0 : self.paidPremiumDays - self.earnedPremiumDays;
    },

    get remainingPayments() {
      return self.policyEquityStatus.remainingCostBreakdown.total === 0 ? 0 : self.billingDetails.remainingPayments;
    },

    get nextPaymentAmount() {
      return self.remainingPayments > 0 ? self.billingDetails.nextPaymentAmount - self.writeOffTotal : 0;
    },

    get nextPaymentLabel() {
      const { nextPaymentAmount: amount } = self;

      return amount < 0 ? 'Refund Owed' : 'Next Payment Due';
    },

    get nextPaymentValue() {
      const { nextPaymentDate: date } = self.billingDetails;
      const { nextPaymentAmount: amount } = self;

      const value =
        amount <= 0
          ? `${currencyFormatter(Math.abs(amount))}`
          : `${currencyFormatter(amount)} on ${awsDateToDateFormatter(date)}`;
      return value;
    },

    get policyId() {
      return new Promise((resolve) => {
        const checkLoading = () => {
          if (!self.loading && self.policy) {
            resolve(self.policy.id);
          } else {
            setTimeout(checkLoading, 300);
          }
        };

        checkLoading();
      });
    },
    get isBix() {
      return self.policy?.isBix;
    },
    get isAdvancedConnectedHome() {
      return self.isBix
        ? advancedConnectedHomeStates[self.geographicState]?.isBix
        : advancedConnectedHomeStates[self.geographicState]?.isNotBix;
    },
    get showLeaseLoan() {
      return self.isBix
        ? leaseLoanStates[self.geographicState]?.isBix
        : leaseLoanStates[self.geographicState]?.isNotBix;
    }
  }));
