/* eslint-disable */
const overrideInput = (theme) => {
  theme.overrides.MuiInput = {
    root: {
      padding: 0,
      color: theme.colorPalette.white_40_op_70,
      border: 'none',
      ' label + &': {
        marginTop: theme.spacing(3)
      },
      '& input:focus': {
        borderRadius: 0,
        border: 'none',
        boxShadow: 'none',
        padding: '1px 7px'
      }
    },
    input: {
      padding: '0 7px',
      backgroundColor: theme.colorPalette.white_10_op_15,
      color: theme.colorPalette.white_10,
      fontSize: 14,
      height: 36,
      width: '100%',
      boxSizing: 'border-box'
    },
    error: {
      border: `1px solid ${theme.palette.error.main}`,
      '& input:focus': {
        border: `1px solid ${theme.palette.error.main}`,
        boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
        padding: '1px 7px',
        color: theme.palette.common.black
      }
    },
    disabled: {
      opacity: 0.4
    }
  };

  theme.overrides.MuiInputLabel = {
    root: {
      paddingLeft: 5,
      color: theme.colorPalette.white_40_op_70,
      fontSize: 14,
      transform: 'translate(0, 27px) scale(1)'
    },
    formControl: {
      transform: 'translate(0, 27px) scale(1)'
    },
    shrink: {
      textTransform: 'uppercase',
      transform: 'translate(-4px, 1.5px) scale(0.68)'
    },
    disabled: {
      color: theme.colorPalette.white_40_op_70,
      opacity: 0.4
    },
    error: {
      border: 'none',
      color: theme.palette.error.main
    }
  };

  theme.overrides.MuiSelect = {
    selectMenu: {
      lineHeight: 36
    }
  };

  theme.overrides.MuiFormControl = {
    root: {
      minHeight: 54,
      marginBottom: 12,
      // TODO: Remove "no-margin-bottom" class once we migrate to use new forms in the whole application
      '&.no-margin-bottom': {
        marginBottom: 0
      },
      '&.has-custom-error': {
        minHeight: 40
      }
    }
  };

  theme.overrides.MuiInputAdornment = {
    positionEnd: {
      position: 'absolute',
      color: theme.colorPalette.white_40_op_70,
      right: 8
    }
  };
};

export default overrideInput;
