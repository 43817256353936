import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import flowRight from 'lodash-es/flowRight';

import { withStore } from 'core/store';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { dateFormatter } from 'core/helpers/formatters';
import { Table, SelectableRow } from 'core';
import { Label } from 'core/components/label';
import coffeeIcon from '../assets/coffee.svg';
import styles from '../tickets.styles';

const Five9TicketSection = ({ classes, tickets }) => {
  return (
    <>
      <Section title="Five9" rightLabel="total" rightValue={`${tickets ? tickets.length : 0} Tickets`}>
        {tickets && tickets.length > 0 ? (
          <Card type="scrollable">
            <Table
              selfContained={false}
              header={
                <>
                  <TableCell className={classes.firstCell}>Date</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Status</TableCell>
                </>
              }
              body={
                <>
                  {tickets.map((ticket, index) => (
                    <SelectableRow key={`five9-data-${index}`} id={`five9-data-${index}`} onClick={() => {}}>
                      <TableCell>{dateFormatter(ticket.date)}</TableCell>
                      <TableCell>{ticket.subject}</TableCell>
                      <TableCell>{ticket.status}</TableCell>
                    </SelectableRow>
                  ))}
                </>
              }
            />
          </Card>
        ) : (
          <Card type="noData">
            <div className={classes.noData}>
              <img src={coffeeIcon} alt="No tickets" />
              <Label type="darkGreenMedium">Relax, there are no tickets yet.</Label>
            </div>
          </Card>
        )}
      </Section>
    </>
  );
};

Five9TicketSection.propTypes = {
  classes: PropTypes.object.isRequired,
  tickets: PropTypes.array
};

Five9TicketSection.defaultProps = {
  tickets: null
};

export default flowRight(withStyles(styles), withStore)(Five9TicketSection);
