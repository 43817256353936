import { Button, Grid, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { getOptions } from '@ourbranch/lookups';
import { Label } from 'core';
import Field from 'core/components/form/form.v2';
import { withToast } from 'core/components/toast';
import { withStore } from 'core/store';
import { Formik } from 'formik';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styles from '../payment.styles';
import { validationSchema } from './manual-charge-form.validation';

const ManualChargeForm = ({
  classes,
  toast,
  store: {
    account: {
      fetchFullAccountAndPolicy,
      policies: { policy: policyInfo }
    }
  }
}) => {
  const {
    policy,
    runManualCharge,
    billingDetails: { allPaymentMethods }
  } = policyInfo;
  const paymentMethodOptions = allPaymentMethods
    ? allPaymentMethods.map((paymentMethod) => {
        const { id, brand, last4, bankName } = paymentMethod;
        return { id, value: `${bankName ? bankName.toUpperCase() : brand.toUpperCase()} ****${last4}` };
      })
    : [];

  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (values) => {
      setLoading(true);
      const accountId = policy.id.slice(0, 9);
      try {
        const res = await runManualCharge(
          policy.id,
          accountId,
          values.paymentMethod,
          values.amount,
          values.internalDescription,
          values.billType
        );
        setLoading(false);
        if (res.data.runManualCharge.success) {
          toast.notify({
            type: 'success',
            message: `Manually charged ${res.data.runManualCharge.type} ending with ${res.data.runManualCharge.last4}`
          });
          fetchFullAccountAndPolicy(policy.id, accountId);
        } else {
          toast.notify({
            type: 'error',
            message: 'There was an error during the manual charge'
          });
        }
      } catch (e) {
        setLoading(false);
        toast.notify({
          type: 'error',
          message: 'There was an error during the manual charge'
        });
      }
    },
    [runManualCharge, policy.id, toast, fetchFullAccountAndPolicy]
  );

  return (
    <>
      <div className={classes.secondRow}>
        <Grid container alignItems="center" justify="flex-start" className={classes.rowTitle}>
          <Label type="coverageValue">Run a manual charge</Label>
        </Grid>

        <Formik
          initialValues={{ paymentMethod: '', amount: null, billType: '', internalDescription: '' }}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => {
            return (
              <div className={classes.manualChargeContainer}>
                <Grid container key="row1" spacing={4}>
                  <Field
                    name="paymentMethod"
                    type="select"
                    label="Payment Method"
                    xs={4}
                    mode="dark"
                    options={paymentMethodOptions}
                    disabled={false}
                  />
                  <Field
                    name="amount"
                    type="numeric"
                    label="Amount"
                    xs={4}
                    format={{
                      thousandSeparator: true,
                      prefix: '$',
                      allowNegative: false,
                      fixedDecimalScale: true,
                      decimalScale: 2
                    }}
                    disabled={false}
                  />
                  <Field
                    name="billType"
                    type="select"
                    label="Bill Type"
                    xs={4}
                    options={getOptions('billTypes')}
                    mode="dark"
                    disabled={false}
                  />
                </Grid>
                <Grid container key="row2" spacing={4} className={classes.spaceBetweenRow}>
                  <Field name="internalDescription" type="string" label="Description" xs={8} disabled={false} />
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Bill Client
                  </Button>
                </Grid>
              </div>
            );
          }}
        </Formik>
      </div>
      {loading && <LinearProgress color="secondary" className={classes.loadingBar} />}
    </>
  );
};

ManualChargeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  toast: PropTypes.object.isRequired
};

export default flowRight(withStyles(styles), withStore, withToast)(ManualChargeForm);
