const styles = (theme) => ({
  containerDark: {
    backgroundColor: theme.colors.card_background_primary_editable,
    paddingRight: 32,
    paddingLeft: 32,
    paddingTop: 24,
    paddingBottom: 10
  },
  container: {
    width: '100%',
    marginBottom: 0
  },
  mortgagesContainer: {
    padding: '0 24px 12px 32px',
    paddingTop: 0,
    backgroundColor: theme.colors.card_background_primary_editable,
    borderTop: '1px solid #fff'
  },
  windstormDetailsContainer: {
    backgroundColor: theme.colors.card_background_primary_editable,
    padding: '14px 24px 24px 24px'
  },
  windstormDetailsSectionLabel: {
    marginBottom: 14
  },
  sectionRightValue: {
    fontWeight: 'normal'
  },
  streetMapText: {
    color: `${theme.colorPalette.orange_10} !important`,
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: 12
  },
  windstormMitigationLevel: {
    marginTop: 4
  }
});

export default styles;
