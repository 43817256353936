import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { lookupsJson, mortgageLookups } from '@ourbranch/lookups';
import AddButton from 'core/components/add-button';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import { Form as FormikForm, withFormik } from 'formik';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { mortgageStyles } from './mortgage-details.styles';
import { motgageValidation } from './validation';

const initialValues = {
  loanNumber: '',
  mortgageHolderAddress: '',
  mortgageHolderName: '',
  primary: false
};

const mortgageList = mortgageLookups?.map((mortgage, index) => ({
  id: index,
  text: mortgage.correctName
}));

const handleSubmit = (values, { props }) => props.onAdd(values);

const AddMortgage = ({ classes, disabled, setFieldValue, values }) => {
  const handleSelection = useCallback(
    (_, { text, id }) => {
      const mortgage = mortgageLookups.find((mgItem) => mgItem.correctName === text);
      if (mortgage) {
        const { address, address2, city, state, zip, correctName, id: mortgageID } = mortgage;
        setFieldValue('mortgageHolderAddress', {
          address,
          address2,
          city,
          state,
          zip
        });
        setFieldValue('mortgageHolderName', correctName);
        setFieldValue('id', mortgageID || id);
      }
    },
    [setFieldValue]
  );
  return (
    <div>
      <div className={classes.line} />
      <Label className={classes.addLabel} type="greenSmall">
        Add Mortgage
      </Label>
      <FormikForm className={classes.container}>
        <Grid
          key="container"
          container
          justify="space-around"
          alignItems="flex-start"
          className={classes.formContainer}
          spacing={4}
        >
          <FormField mode="light" name="loanNumber" id="loanNumber" label="Loan Number" type="string" xs={4} />
          <FormField
            mode="light"
            name="mortgageHolderName"
            id="mortgageHolderName"
            options={mortgageList}
            onSelection={handleSelection}
            label="Holder name"
            type="autocomplete"
            xs={8}
            allowUserInput
          />
          <FormField
            mode="light"
            key={values.id}
            name="mortgageHolderAddress.address"
            id="mortgageHolderAddress.address"
            type="string"
            label="Holder Address"
            xs={8}
          />
          <FormField
            mode="light"
            name="mortgageHolderAddress.address2"
            id="mortgageHolderAddress.address2"
            label="Address 2"
            type="string"
            optional
            xs={4}
          />
          <FormField
            mode="light"
            name="mortgageHolderAddress.city"
            id="mortgageHolderAddress.city"
            label="City"
            type="string"
            optional
            xs={4}
          />
          <FormField
            mode="light"
            name="mortgageHolderAddress.state"
            id="mortgageHolderAddress.state"
            label="State"
            type="autocomplete"
            options={lookupsJson.usStates}
            optional
            xs={4}
            stateSelectForm
          />
          <FormField
            mode="light"
            name="mortgageHolderAddress.zip"
            id="mortgageHolderAddress.zip"
            label="Zip Code"
            type="string"
            optional
            xs={3}
          />
          <Grid item xs={1}>
            <div className={classes.buttonContainer}>
              <AddButton disabled={disabled} key="addButton" actionType="submit" type="full" mode="xl" />
            </div>
          </Grid>
        </Grid>
      </FormikForm>
    </div>
  );
};

AddMortgage.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
};

export default flowRight(
  withFormik({
    enableReinitialize: true,
    handleSubmit,
    validationSchema: motgageValidation,
    mapPropsToValues: () => initialValues
  }),
  withStyles(mortgageStyles)
)(AddMortgage);
