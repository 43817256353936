/* eslint-disable no-else-return */
import React from 'react';
import PropTypes from 'prop-types';
import MUITooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

import useStyles from './tooltip.styles';

export function Tooltip({ text, label, className, placement, ...props }) {
  const classes = useStyles();
  return (
    <MUITooltip title={text} placement={placement} interactive arrow classes={{ tooltip: classes.tooltip }}>
      <div {...props}>
        <span className={classNames(className, classes.desktopLabel)}>{label}</span>
      </div>
    </MUITooltip>
  );
}

Tooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  placement: PropTypes.string
};

Tooltip.defaultProps = {
  className: '',
  label: '',
  placement: 'top'
};

export default Tooltip;
