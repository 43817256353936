/* eslint-disable global-require */
const AWS = require('aws-sdk');
const { documentTypes, docIdToFriendlyName } = require('@ourbranch/be-constants');

const lambda = new AWS.Lambda({ apiVersion: '2015-03-31' });

const getPolicyDocuments = (policies, bindDetails) => {
  console.log(`Generation policy documents for policies: `, JSON.stringify(policies));
  return [
    { docType: documentTypes.DECLARATION, data: { policies, bindDetails } },
    { docType: documentTypes.ID_CARDS, data: { policies, bindDetails } },
    { docType: documentTypes.POLICY_JACKET, data: { policies, bindDetails } }
  ];
};

const _generate = (docs, docsRenderer) => {
  console.log(`running generate to ${docsRenderer}`);

  const documentsPromise = docs.map((documentData) =>
    lambda
      .invoke({
        FunctionName: docsRenderer,
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify(documentData)
      })
      .promise()
  );
  return Promise.all(documentsPromise);
};

const generatePolicyDocuments = async (policies, bindDetails, docsRenderer) => {
  try {
    console.log('Generating policy documents for: ', JSON.stringify(policies));
    const policyDocs = getPolicyDocuments(policies, bindDetails);
    console.log(`Generating ${policyDocs.map((x) => x.docType).join(', ')} documents`);
    console.log(`Sending bindDetails of ${JSON.stringify(bindDetails, null, 2)}`);

    const generatedDocs = await _generate(policyDocs, docsRenderer);
    const docs = policyDocs.map((doc, i) => {
      if (generatedDocs[i]) {
        doc.paths =
          typeof generatedDocs[i].Payload === 'string'
            ? JSON.parse(generatedDocs[i].Payload)
            : generatedDocs[i].Payload;
      }
      return doc;
    });
    console.log('Policy documents generated', docs);
    return docs;
  } catch (er) {
    console.log('Error generating policy documents', er);
    throw er;
  }
};

const generateCancellationDocuments = async (policies, bindDetails, docsRenderer) => {
  try {
    const cancellationDocs = getPolicyDocuments(policies, bindDetails);
    cancellationDocs.push({ docType: documentTypes.CANCELLATION, data: { policies, bindDetails } });
    console.log(
      `Generating ${cancellationDocs.length} documents with ${docsRenderer} (${JSON.stringify(docsRenderer, null, 4)})`
    );

    const generatedDocs = await _generate(cancellationDocs, docsRenderer);
    console.log('Cancellation documents generated', generatedDocs);
  } catch (er) {
    console.log('Error generating cancellation documents', er);
    throw er;
  }
};

const generateFriendlyName = (fileName) => {
  let docId = fileName // '2019-2-14-19-29-A_IDCD.pdf'
    .split('-') // ['2019', '2', '14', '19', '29', 'A_IDCD.pdf']
    .slice(5) // ['A_IDCD.pdf']
    .join('_') // 'A_IDCD.pdf'
    .split('.')[0] // ['A_IDCD', 'pdf'] -> 'A_IDCD'
    .toUpperCase(); // 'A_IDCD'
  // also drop out "BF_" if it starts with that.
  docId = docId.replace('BF_', '');
  // handle case where id has _CW and ends with stuff after _CW
  docId = !docId.includes('_CW_') ? docId : docId.split('_CW_')[0].concat('_CW');
  // strip off _XY if it ends that way
  docId = docId.match(/_[A-Z][A-Z]$/) ? docId.replace(/_[A-Z][A-Z]$/, '') : docId;
  return docIdToFriendlyName[docId] || fileName;
};

module.exports = {
  generatePolicyDocuments,
  generateCancellationDocuments,
  generateFriendlyName
};
